import { dateCubes, DateExt, parseCubes } from "./date-funcs";
import * as PrismaExtra from "prisma-client";
import { CustomerPaymentInfoFlags, SPPI, StringPathProxy } from "./schema-builder/cubes-utils";
import { ServerPayments } from "./server/Application/ServerPayments";
import { CustomerLedger, PrismaQuery, SelectTypeTree } from ".";
import { truthy } from "./schema-builder/graphql-declarator";
// import { PrismaConnection } from "./server/PrismaAuthToken";

export type PaymentLedgerKeys = "Customer" | "Branch" | "Owner" | "Division" | "SalesTax";

export function getPreviousRentalStatus(current: PrismaExtra.RentalStatus, isRentToOwn: boolean): PrismaExtra.RentalStatus {
  switch (current) {
    case 'Scheduled': return "Reserved";
    case 'Rented': return "Scheduled";
    case "RentToOwn": return "Scheduled";
    case 'Moving_Out': return isRentToOwn ? "RentToOwn" : "Rented";
    case 'Completed': return "Moving_Out";
    case 'Retained': return "Completed";
    case 'Released': return "Completed";
    case "Reserved":
    case "Archived":
    case "SoldToCustomer":
      throw `Cannot reverse ${current} rental status.`;
    default:
      const t: never = current;
      throw `Invalid rental status ${current}`;
  }
}

export function getNextRentalStatus(current: PrismaExtra.RentalStatus): PrismaExtra.RentalStatus[] {
  switch (current) {
    case 'Reserved': return ["Scheduled"]
    case 'Scheduled': return ["Rented", "RentToOwn"];
    case 'Rented': return ["Moving_Out"];
    case 'RentToOwn': return ["Moving_Out", "SoldToCustomer"];
    case 'Moving_Out': return ["Completed"];
    case 'Completed': return ["Retained", "Released"];
    case 'Retained': return ["Released"]
    case 'Released': return [];
    case "SoldToCustomer": return [];
    case "Archived": return [];
    default:
      const t: never = current;
      throw `Invalid rental status ${current}`;
  }
}


interface CognitoJwtFields {
  token_use: "access" | "id";
  "cognito:groups"?: string[];
  sub: string;
  iss: string;
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  origin_jti: string;
}
interface CognitoIdTokenFields extends CognitoJwtFields {
  token_use: "id";
  aud: string;
  at_hash: string;
  "cognito:username": string;
  email_verified: boolean;
  phone_number_verified: boolean;
  identities: {
    userId: string;
    providerName: string;
    providerType: string;
    issuer: null;
    primary: string;
    dateCreated: string;
  }[];
  "cognito:roles": string[];
  "cognito:preferred_role": string;
}

export interface IdTokenPayload extends CognitoIdTokenFields {
  name: string;
  email: string;
}




export const CognitoGroups = ["cubes_central_admin", "cubes_branch_admin"] as const;
export type CognitoGroups = typeof CognitoGroups[number];

// export { PrismaConnection };

export type PrismaClientType = PrismaExtra.PrismaClient;
export type PrismaTxnClient = Omit<PrismaClientType, "$on" | "$connect" | "$disconnect" | "$use" | "$transaction" | "$extends">;

export type PaymentFormKey = keyof PaymentFormInput;

export interface PaymentFormInput {
  "email": {
    email: string,
  },
  "verify-email": {
    // this is required in the definition to make it distinct
    verifyEmail?: true;
    token: string;
    sig: string;
  }
  "reset-password": {
    // this is required in the definition to make it distinct
    resetPassword?: string;
    token: string;
    sig: string;
  }
  "reset-password-complete": {
    // this is required in the definition to make it distinct
    resetPassword?: string;
    token: string;
    sig: string;
  }
  "token-id": {
    tokenId: string;
  },
  "form-url": {
    // this is required in the definition to make it distinct
    formUrl?: true;
    redirect: string;
  },
  "status": {
    // this is required in the definition to make it distinct
    status?: true,
  },
  "amount": {
    amount: number,
  },
  "balance": {
    balance?: true;
  },
  "history": {
    history?: true;
  }
  "basic-info": {
    billing?: true;
    Name: string;
    Address: string;
    Phone: string;
  }
  "autopay-info": {
    autopay: string;
  }
  "address-lookup": {
    addresslookup?: true,
    input: string,
  }
  "delete-payment-info": {
    deletePaymentInfo?: true,
  }
}

export interface PaymentFormOutput {
  "email": undefined,
  "status": Awaited<ReturnType<ServerPayments["getCustomerStatus"]>>["status"],
  "amount": { success: boolean, reason: string, amount?: number, },
  "form-url": { formUrl: string, },
  "token-id": { success: boolean, reason: string, }
  "history": { history: Awaited<ReturnType<typeof getCustomerActualTransactions>>, },
  "balance": { balance: number },
  "verify-email": {}
  "basic-info": {}
  "autopay-info": {}
  "address-lookup": { description: string | undefined }[]
  "reset-password": { AWSID: string, Email: string, EmailVerified: boolean, PasswordResetCode: string },
  "reset-password-complete": {},
  "delete-payment-info": {},
}


export function getNextInvoiceDay(date: Date, payday: number) {
  if (DateExt(date).getDate() >= payday) {
    return DateExt(date).addMonths(1).setDate(payday);
  } else {
    return DateExt(date).setDate(payday);
  }
}
export async function getCustomerActualBalance(client: PrismaTxnClient, customerID: string | undefined) {
  return await client.customerLedger.aggregate({
    _sum: { Amount: true },
    where: { customerID, line: { VoidSince: null } },
  });
}


export async function getCustomerActualTransactions(client: PrismaTxnClient, customerID: string | undefined) {
  return await client.customerLedger.findMany({
    where: { customerID, line: { VoidSince: null } },
    select: PrismaQuery.selectPathsProxy("CustomerLedger", getCustomerActualTransactions.SelectPaths)
  });
}

getCustomerActualTransactions.SelectPaths = (x: SelectTypeTree<"CustomerLedger">) => {
  return [
    x.id.__,
    x.line.Date.__,
    x.line.invoiceLine.paidOn.__,
    x.line.invoiceLine.item.ItemName.__,
    // x.line.invoiceLine.item.ItemType.__,
    x.line.invoiceLine.rental.unit.Name.__,
    x.line.invoiceLine.rental.unit.unitType.Name.__,
    x.line.paymentLine.PaymentStatus.__,
    // x.line.paymentLine.PaymentFee.__,
    x.Amount.__,
  ] as const;
};

getCustomerActualTransactions.StringPaths = StringPathProxy<CustomerLedger>()(getCustomerActualTransactions.SelectPaths as any) as SPPI[];
