import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useAngular, useEventEmitter, useObserver } from "react-utils";
import { useState } from "react";
import { BlockStack, Button, InlineError, InlineStack, Modal, ModalProps, Text } from "@shopify/polaris";
import Dinero from "dinero.js";
import { useSimpleValueFields } from "./useSimpleValueFields";
import { useDispatch } from "./useDispatch";
import { DataService, PaymentLedgerKeys, ok } from "common";
import { PaymentApp } from "./usePaymentStatus";
import { PaymentMode } from "./ProvidePaymentProcessor";
import { EventEmitter } from "@angular/core";
import { FormsQuestionService } from "../utils/forms-question.service";
import { startWith } from "rxjs";
import { makePaymentDialog } from "./makePaymentDialog";




export function MakePaymentModel({ PaymentLedger, events, balance, request, hostID, updateStatus }: {
  /** Must be memo-ized */ 
  events: EventEmitter<PaymentMode>,
  /** Must be memo-ized */ 
  updateStatus: PaymentApp["updateStatus"],
  /** Must be memo-ized */ 
  request: PaymentApp["request"],
  PaymentLedger: PaymentLedgerKeys,
  hostID: string,
  balance: Dinero.Dinero | undefined,
}) {
  const { get } = useAngular();
  const data = get(DataService);
  const fq = get(FormsQuestionService);

  const [open, setOpen] = useState(false);
  const [primaryAction, setPrimaryAction] = useState<ModalProps["primaryAction"]>();
  const [secondaryActions, setSecondaryActions] = useState<ModalProps["secondaryActions"]>();

  useObserver(events, e => {
    switch (e) {
      case "make-payment": setOpen(true); break;
      case "": setOpen(false);
    }
  });

  const balanceChanges = useEventEmitter<Dinero.Dinero | undefined>();
  const dialogRef = useRef<ReturnType<typeof makePaymentDialog>>();
  useLayoutEffect(() => {
    balanceChanges.emit(balance);
  }, [balanceChanges, balance]);
  useLayoutEffect(() => {
    if (open) {
      const dialog = makePaymentDialog(fq, data, PaymentLedger, hostID, async (amount) => {
        const result = await request("amount", { amount: amount.getAmount() }).catch(error => {
          alert("Something went wrong. Please contact support.");
          return { success: false, reason: undefined };
        });
        if(result.success) events.emit("make-payment-success");
        return result;
      });
      dialog.pageSetup(false);
      dialog.subs.add(() => {
        setOpen(false);
        dialogRef.current = undefined;
      });
      dialogRef.current = dialog;
    } else if (dialogRef.current) {
      dialogRef.current.subs.unsubscribe();
    }
  }, [hostID, open, PaymentLedger, data, events, fq, request]);
  return null;


}

// function MakePaymentModelInner({
//   PaymentLedger,
//   events, updateStatus, balance, request,
//   setOpen, setPrimaryAction, setSecondaryActions
// }: {
//   updateStatus: PaymentApp["updateStatus"],
//   request: PaymentApp["request"],
//   balance: Dinero.Dinero | undefined,
//   events: EventEmitter<PaymentMode>,
//   PaymentLedger: PaymentLedgerKeys,
//   setOpen: Dispatch<SetStateAction<boolean>>,
//   setPrimaryAction: Dispatch<SetStateAction<ModalProps["primaryAction"]>>,
//   setSecondaryActions: Dispatch<SetStateAction<ModalProps["secondaryActions"]>>,
// }) {
//   const { get } = useAngular();
//   const data = get(DataService);
//   const fq = get(FormsQuestionService);

//   const [sending, setSending] = useState(false);
//   const [complete, setComplete] = useState(false);
//   const [failure, setFailure] = useState("");

//   const store = new useSimpleValueFields({ Amount: "" }, true);

//   let value = +store.curValue["Amount"].slice(1).split(".").join("") || 0;
//   if (!Number.isFinite(value) || Number.isNaN(value)) value = 0;
//   const amount = Dinero({ amount: value });

//   const {
//     setValue,
//     setChangedFields,
//     setBlurredFields,
//   } = store;

//   const onClose = useDispatch(() => {
//     setOpen(false);
//     setComplete(false);
//     setSending(false);
//     setFailure("");
//     setValue({ Amount: "" });
//     setChangedFields("clear");
//     setBlurredFields("clear");
//     updateStatus();
//   });

//   const onClickMakePayment = useDispatch(async (): Promise<void> => {
//     if (value < 0) return; // { toolow: true };
//     if (value === 0) return; // { required: true };
//     if (sending) return; // { sending: true };

//     setSending(true);
//     const { success, error } = await request("amount", { amount: amount.getAmount() }).catch(e => ({ success: false, error: undefined }));
//     setSending(false);

//     if (success) {
//       setComplete(true);
//       events.emit("make-payment-success")
//     } else if (error) {
//       setFailure(error);
//     } else {
//       alert("something went wrong");
//     }

//   });

//   const amountFormatted = amount?.toFormat();
//   useLayoutEffect(() => {
//     setPrimaryAction({
//       content: complete ? "Close" : PaymentLedger === "Customer" ? `Charge ${amountFormatted}` : `Credit ${amountFormatted}`,
//       loading: sending,
//       onAction: complete ? onClose : onClickMakePayment,
//       disabled: !complete && (value < 0 || value === 0)
//     });
//   }, [complete, sending, value, amountFormatted, PaymentLedger, setPrimaryAction, onClickMakePayment, onClose])
//   useLayoutEffect(() => {
//     setSecondaryActions((complete || sending) ? [] : [{
//       content: 'Close',
//       onAction: onClose
//     }]);
//   }, [complete, sending, onClose, setSecondaryActions])
//   return <Modal.Section>
//     <BlockStack gap="400">
//       {!complete ? <>
//         {balance ? (
//           <InlineStack align="space-between">
//             <Text as="span" variant="headingMd">{PaymentLedger}'s current balance: ${formatDinero(balance)}</Text>
//             <Button onClick={() => { store.setValue({ Amount: balance.toFormat() }) }}>Use balance</Button>
//           </InlineStack>
//         ) : (
//           <Text as="p" variant="headingMd">Loading balance...</Text>
//         )}
//         {store.TextField({
//           key: "Amount",
//           title: "Amount",
//           validate: (value) => value ? undefined : "Required",
//           required: true,
//           onChangeMap: input => {
//             if (!input) return "";
//             input = input.replace(/[^0-9]/g, "").padStart(3, "0");
//             while (input.length > 3 && input[0] === "0") input = input.slice(1);
//             return "$" + input.slice(0, input.length - 2) + "." + input.slice(input.length - 2);
//           }
//         })}
//         <Text as="p" variant="headingMd">Total: ${formatDinero(amount)}</Text>
//         {failure && <InlineError message={failure} fieldID="error" />}
//       </> : <>
//         <Text as="p" variant="headingMd">Total: ${formatDinero(amount)}</Text>
//         <Text as="p" variant="bodyMd">Accepted. Thank you.</Text>
//       </>}
//     </BlockStack>
//   </Modal.Section>;

// }
// export function formatDinero(item: Dinero.Dinero) {
//   return item.toFormat("0,0.00");
//   // let str = item.getAmount().toFixed(0).padStart(3, "0");
//   // return `${str.slice(0, str.length - 2)}.${str.slice(str.length - 2)}`;
// }
