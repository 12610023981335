/* eslint-disable no-lone-blocks */
import React, { Dispatch, SetStateAction, useDeferredValue, useEffect, useLayoutEffect, useReducer, useRef, useSyncExternalStore } from "react";
import {
  IndexFilters,
  IndexFiltersMode,
  TabProps, Card, DataTable, EmptyState, Spinner, Box, InlineStack,
  Link
} from '@shopify/polaris';
import { useState, useMemo } from 'react';
import { ColumnBase, DataListColumn, DataListCustomColumn, DataListIdFieldColumn, EditTreeAccessor } from '../utils';
import pluralize from "pluralize";
import { CustomerLedger, DataQueryGraph, DataService, ok, schema, select, SPPI, StringPathProxy, TABLE_NAMES, TableViewColumn, truthy, TYPE_NAMES, UIService, WHERE_balanceWhereLine } from "common";
import { useAsyncEffect, useAngular, useObservable, valMarkup, useLayoutEffectWatcher, useSubscribe, useEventEmitter, useDispatch } from "react-utils";
import { NonEmptyArray } from "@shopify/polaris/types";
import { IndexTable, IndexTableHeading, IndexTablePaginationProps, IndexTableSortDirection } from "@shopify/polaris/components/IndexTable";
import { FormsQuestionService } from '../utils';
import { FormControl } from "@angular/forms";
import { useCallback } from "react";
import { Router } from "@angular/router";
import { useI18n } from "@shopify/polaris/utilities/i18n";
import { EventEmitter, NgZone } from "@angular/core";
import { AppliedFilterProps, FilterProps, convertFiltersToQuery, useFiltersWithViews } from "./useFilters";
import { MaybeArray, TableView, PrismaWhereQuery, TableViewClass, MaybeTableViewArray, ListValue, TableViewOptions, assignColumnOptions } from "./table-views";
import { EMPTY, filter, firstValueFrom, takeUntil } from "rxjs";
import { IndexFiltersPrimaryAction } from "@shopify/polaris/components/IndexFilters";
import { Observable } from 'rxjs';
import { Range, SelectionType } from "@shopify/polaris/utilities/index-provider";

import styles from "@shopify/polaris/components/IndexTable/IndexTable.scss";
import { useRefresh } from 'react-utils';
import { CustomColumnDef, CustomColumnState } from "./CustomColumnState";
import { useNavigate } from "react-router";
import { TableRowAction, TableRowDispatch, makeTableRowRedux } from "./TableRowRedux";


export function useTableListSimple<R extends any[] = any[]>({ table, view }: {
  table: TABLE_NAMES,
  view: TableView,
}) {


  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);

  const { cols, rows, idcol, value, setValue, arrayList, arraySort, customs } = useTableListTree(table, view);

  const AND: PrismaWhereQuery[] | null = useSimpleAND(cols, view, table);
  const badgeCounts = useMemo(() => new Map(), []);

  const { loading } = useTableListEffect(
    cols, setValue, data, table, arrayList, arraySort, badgeCounts, customs, JSON.stringify(AND)
  );

  return { cols, rows: rows as R, idcol, loading, arraySort };

}

function useSimpleAND(
  // tree: { cols: readonly ColumnBase[];[TableCheck]?: never; },
  // tree: EditTreeAccessor<any>,
  cols: DataListColumn[],
  view: TableView,
  table: string
) {

  const viewAND = view && Array.isArray(view.AND) && view.AND;

  const AND: PrismaWhereQuery[] | null = useMemo(() => {
    return [
      ...viewAND || [],
      // ...convertFiltersToQuery(convertViewToFilters(cols, view.filters)),
    ];
  }, [viewAND, cols, view]);
  return AND;
}

export function TableListSimple({ table, view, hiddenColumns = [], emptyState }: {
  table: TABLE_NAMES,
  view: TableView,
  hiddenColumns?: SPPI[],
  emptyState?: React.ReactNode,
}) {

  const { get } = useAngular();
  const fq = get(FormsQuestionService);

  const { cols, rows, idcol, loading, arraySort } = useTableListSimple({ table, view });

  cols.forEach(e => { if (hiddenColumns.contains(e.key)) e.hidden = true; });

  return <SelectTable {...({
    cols,
    rows,
    idcol,
    onClickRow: (id) => { fq.onClickEvent({ action: "edit", table, id }); },
    emptyState: loading ? null : emptyState,
    firstSort: arraySort[0],
  })} />;

}

export interface TableListInnerProps {
  /** Whatever the root of views.columns is. */
  table: TABLE_NAMES;
  /** This should be cached with useMemo. */
  views: MaybeArray<TableView> | readonly TableView[];
  loadingMarkup?: (curView: number) => React.ReactNode;
  emptyMarkup?: (curView: number) => React.ReactNode;
  hiddenColumns?: SPPI[];
  hideFilters?: boolean;
  primaryAction?: IndexFiltersPrimaryAction | undefined,
  resourceName?: { singular: string, plural: string },
  firstView?: number;
  onViewChange?: ({ view }: { view: TableView }) => void;
  pageSetupCallback?: (keepPage: boolean) => Promise<void>;
  onSelectRow: (id?: string, row?: unknown) => void;
  colsHook?: (cols: readonly ColumnBase[]) => void;
  selectMultiple?: boolean;
  selectionChange?: (rows: Set<string>) => void;
  // queryColumns?: SPPI[];

}



export function useTableListInner({
  table,
  views,
  firstView = 0,
  onViewChange,
  loadingMarkup,
  emptyMarkup,
  hiddenColumns = [],
  hideFilters = false,
  // primaryAction,
  resourceName,
  onSelectRow: onSelectRowProp,
  colsHook,
  selectMultiple,
  selectionChange,
}: TableListInnerProps) {

  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);

  ok(views.length > 0);

  const [curView, setView] = useState(firstView);

  const view = views[curView];

  ok(view);

  useLayoutEffect(() => { if (view) onViewChange?.({ view }); }, [onViewChange, view]);

  const { idcol, cols, rows, value, setValue, arrayList, arraySort, customs } = useTableListTree(table, view, colsHook);

  const colsMap = new Map(cols.map(e => [e.key, e]));

  const { curAppliedFilters, setAppliedFilters, filters, onClearAll, curMode, setMode } = useFiltersWithViews(cols, views, curView, setView);

  const emptyView = views.length && curView === -1 || typeof view?.AND === "symbol";

  const viewAND = view && Array.isArray(view.AND) && view.AND;

  const AND: PrismaWhereQuery[] | null = useMemo(
    () => emptyView ? null : [...viewAND || [], ...convertFiltersToQuery(curAppliedFilters)],
    [emptyView, viewAND, curAppliedFilters]
  );

  const badgeCounts = useMemo(() => new Map(views.filter(e => e?.getCount).map(e => [e, undefined] as const)), [views]);

  const { loading } = useTableListEffect(cols, setValue, data, table, arrayList, arraySort, badgeCounts, customs, JSON.stringify(AND));

  cols.forEach(e => { if (hiddenColumns.contains(e.key)) e.hidden = true; });

  const onClickRow = (id: string) => { onSelectRowProp(id, rows.find(e => idcol.get(e) === id)); };

  const emptyState = loading ? loadingMarkup?.(curView) : emptyMarkup?.(curView);

  const [curQuery, setQuery] = useState("");
  const query = useDeferredValue(curQuery);
  // console.log(curQuery);

  const viewTabs = views.map((item, index) => ({
    content: item.title,
    index,
    onAction: () => { },
    id: `${item.title}-${index}`,
    isLocked: true,
    badge: badgeCounts.get(item),
    disabled: item.hidden
  }) as TabProps);

  const filterMarkup = <IndexFilters
    cancelAction={{ onAction: onClearAll }}
    filters={filters}
    appliedFilters={curAppliedFilters}
    onClearAll={onClearAll}
    mode={curMode}
    setMode={setMode}
    onQueryClear={() => { console.log("clear"); }}
    queryValue={curQuery}
    onQueryChange={setQuery}
    // primaryAction={{type: "save", onAction: async () => { console.log("save"); return true; }, }}
    hideQueryField={false}
    hideFilters={false}
    tabs={viewTabs}
    selected={curView}
    onSelect={setView}
    canCreateNewView={false}
    disableStickyMode
    disableKeyboardShortcuts
  />;

  const tableMarkup = <SelectTable
    key={view.key}
    cols={cols}
    rows={rows}
    idcol={idcol}
    emptyState={emptyState}
    onClickRow={onClickRow}
    firstSort={arraySort[0]}
    loading={loading}
    query={curQuery}
    selectMultiple={selectMultiple}
    selectionChange={selectionChange}
    curAppliedFilters={curAppliedFilters}
    resourceName={resourceName}
  />;

  ok(cols.length > 0);

  return {
    emptyState,
    onClickRow,
    arraySort,
    resourceName,
    setMode,
    view,
    curView,
    setView,
    setAppliedFilters,
    customs,
    rows,
    cols,
    colsMap,
    idcol,
    AND,
    /** Optional render function to return markup if you don't need to customize it */
    useMarkup: () => <>
      {hideFilters ? null : filterMarkup}
      {tableMarkup}
    </>
  };

}



export function TableListInner(opts: TableListInnerProps) {
  return useTableListInner(opts).useMarkup();
}


export function useViewChangeCallbackSetRouterUrl(table: string, prefix: string, suffix: string, id: string) {
  const { get } = useAngular();
  const router = get(Router);
  const zone = get(NgZone);
  return useCallback(async ({ view }: { view: TableView }) => {
    const [, _table, _view, _id] = router.url.split("?")[0].split("/");
    const viewPart = [prefix, view.key, suffix].filter(truthy).join("-");
    const url = "/" + [table, viewPart, id].filter(truthy).join("/");
    const replaceUrl = table === _table && prefix === _view && id === _id;
    await router.navigateByUrl(url, { replaceUrl });
  }, [table, prefix, suffix, id, router, zone]);
}


export interface TableProps {
  emptyState?: React.ReactNode;
  firstSort?: string;
  curAppliedFilters?: AppliedFilterProps[];
  loading?: boolean;
  cols: readonly ColumnBase[],
  rows: any[],
  idcol: ColumnBase,
  pagination?: IndexTablePaginationProps;
}


export function useTable({
  cols,
  rows,
  emptyState,
  firstSort,
  curAppliedFilters,
  loading,
}: TableProps): JSX.Element {

  const notHiddenCols: ColumnBase[] = useMemo(() => cols.filter(e => !e.hidden), [cols]);

  const { sortedRows: filteredRows, ...sort } = useSortOpts(rows, cols, curAppliedFilters, firstSort);

  console.log("useTable", { filteredRows, curAppliedFilters, firstSort, notHiddenCols, sort })

  if (filteredRows.length === 0) return <>{emptyState ?? <EmptyState image="" />}</>;

  return loading ? <SpinnerBlock /> : <DataTable
    columnContentTypes={notHiddenCols.map(e => 'text')}
    headings={notHiddenCols.map(e => (
      <span className={styles.TableHeading} key={e.key} id={e.key}>{e.title}</span>
    ))}
    rows={filteredRows.map(row => notHiddenCols.map(col => valMarkup(col, row)))}
    sortable={notHiddenCols.map(e => !!e.sorter)}
    defaultSortDirection={sort.sortDirection}
    initialSortColumnIndex={sort.sortColumnIndex}
    onSort={sort.onSort}
  />
}

export interface SelectTableProps extends TableProps {
  onClickRow?: (row: string) => void;
  resourceName?: { singular: string, plural: string };
  query?: string;
  selectMultiple?: boolean;
  selectionChange?: (rows: Set<string>) => void;
}

function ProxyReducer() {

}

function SetProxy<T>(value: Set<T>, refresh: (target: Set<T>) => void) {
  return new Proxy(value, {
    get(target, p: keyof Set<T>, receiver) {
      switch (p) {
        case "add":
        case "delete":
          return (arg: T) => {
            target[p](arg);
            refresh(target);
          }
        case "clear":
          return () => {
            target.clear();
            refresh(target);
          }
        case "entries":
        case "keys":
        case "values":
          return () => target[p]();
        case "forEach":
          return ((...args) => target[p](...args)) as typeof target.forEach;
        case "has":
          return ((...args) => target[p](...args)) as typeof target.has;
        case Symbol.iterator:
        case Symbol.toStringTag:
        case "size":
          return target[p];
        default: {
          const t: never = p;
        }
      }
    },
  });
}


function useProxy<T>(init: () => Set<T>) {
  const setter = useDispatch((target: Set<T>) => { setValue(target); });
  const reducer = useCallback((state: Set<T>, action: Set<T>) => SetProxy(action, setter), [setter]);
  const [value, setValue] = useReducer(reducer, true, () => SetProxy(init(), setter));
  // console.log("useProxy", { value });s
  return value;
}

/**
 * showRowCheckbox changes the hook count
 */
export function SelectTable({
  cols,
  rows,
  idcol,
  emptyState,
  firstSort,
  curAppliedFilters,
  resourceName,
  onClickRow: onClickRow,
  selectionChange,
  loading,
  query,
  pagination,
  selectMultiple,
}: SelectTableProps): JSX.Element {
  // const hiddenIndex = JSON.stringify(rows.map(row => !matchAppliedFilters(row, curAppliedFilters)));
  // this can't be memoed because the array doesn't change, only the rows do
  const { sortedRows: notHiddenRows, ...sortOpts } = useSortOpts(rows, cols, curAppliedFilters, firstSort);
  // this could be memo-ed but the length of cols could change
  const notHiddenCols: ColumnBase[] = cols.filter(e => !e.hidden);



  const queryRows = new Set(notHiddenRows.filter(row => {
    if (!query) return true;
    if (!notHiddenCols.some(col => col.queryColumn)) return true;
    if (notHiddenCols.some(col => col.queryColumn && col.text(col.get(row))?.toString().toLowerCase().includes(query.toLowerCase()))) return true;
    return false;
  }).map(e => idcol.get(e)));

  // console.timeEnd("SelectTable sort and filter");

  const headings = notHiddenCols.map(e => ({
    title: e.title,
    alignment: "start",
    hidden: e.hidden,
    id: e.key,
  } satisfies IndexTableHeading)) as NonEmptyArray<IndexTableHeading>;

  ok(headings.length > 0, "headings.length is 0");

  const selection = useProxy(() => new Set<string>());

  const onSelectRowInner = useCallback((key: string, value: boolean | null) => {
    if (selectMultiple) {
      selection.has(key) ? (value !== true && selection.delete(key)) : (value !== false && selection.add(key));
    } else {
      selection.clear(); selection.add(key);
    }
    selectionChange?.(selection);
  }, [selectMultiple, selection, selectionChange]);

  const onSelectionChange = !selectMultiple ? undefined :
    useCallback((selectionType: SelectionType, toggleType: boolean, selection?: string | Range) => {
      console.log("onSelectionChange", { selectionType, toggleType, selection });
      if (typeof selection === "string") onSelectRowInner(selection, toggleType);
    }, [onSelectRowInner]);



  return loading ? <SpinnerBlock /> : <IndexTable
    resourceName={resourceName}
    itemCount={notHiddenRows.length}
    headings={headings}
    selectable={selectMultiple}
    {...sortOpts}
    sortable={notHiddenCols.map(e => !!e.sorter)}
    emptyState={emptyState}
    onSelectionChange={onSelectionChange}
    pagination={pagination}


  >{notHiddenRows.map((row, index) => (
    query && !queryRows.has(idcol.get(row)) ? null :
      <IndexTable.Row
        id={idcol.get(row)}
        key={idcol.get(row)}
        selected={selectMultiple && selection.has(idcol.get(row))}
        onClick={onClickRow ? () => { onClickRow(idcol.get(row)); } : undefined}
        position={index}
        tone={row.__tone__}

      >
        {notHiddenCols.map(col => colMarkup(col, row)).lift(e => onClickRow ? [
          e[0],
          (<td key="data-primary-link" data-primary-link style={{ display: "none" }}></td>),
          ...e.slice(1)
        ] : e)}
      </IndexTable.Row>
  ))}</IndexTable>;
}
const colMarkup = (col: ColumnBase, row: any) => {
  if (col.hidden) return null;
  const link = col.link ? col.link(row) : null;
  if (link)
    return <IndexTable.Cell key={col.key}><Link url={link}>{valMarkup(col, row)}</Link></IndexTable.Cell>;
  else
    return <IndexTable.Cell key={col.key}>{valMarkup(col, row)}</IndexTable.Cell>;
}



export function SpinnerBlock() {
  return (
    <Box padding="400" width="100%">
      <InlineStack align="center" blockAlign="center">
        <Spinner />
      </InlineStack>
    </Box>
  )
}
/** 
 * Returns an memoized array of the function with the index bound as the first argument. 
 * Remember to wrap the function in useCallback, otherwise it will just rebuild the array every time.
*/
function useArrayCallbackMemo(length: number, fn: (index: number) => any) {
  return useMemo(() => Array.from({ length }, (_, i) => fn.bind(undefined, i)), [length, fn]);
}

function matchAppliedFilters(row: unknown, curAppliedFilters?: AppliedFilterProps[]): boolean {
  if (!curAppliedFilters) return true;
  return curAppliedFilters.every(({ column, clientCheck }) => {
    if (!column || !clientCheck) return true;
    return clientCheck(row, column);
  });
}

/** Uses useMemo with an empty deps array to calculate the value once and return it. */
function useInit<T>(fn: () => T) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(fn, []);
}

export function useSortOpts(rows: any[], cols: readonly ColumnBase[], curAppliedFilters: AppliedFilterProps[] | undefined, firstSort: string | undefined) {

  const { startKey, startDirection } = useInit((): {
    startKey: string,
    startDirection: IndexTableSortDirection,
  } => {
    const sortcols = cols.filter(e => !e.hidden && e.sort).sort((a, b) => Math.abs(a.sort) - Math.abs(b.sort));
    if (firstSort) {
      const startKey = firstSort.startsWith("-") ? firstSort.slice(1) : firstSort;
      const startDirection = firstSort.startsWith("-") ? "descending" : "ascending";
      const dir = firstSort.startsWith("-") ? -1 : 1;
      cols.forEach((e) => { e.sort = (e.key === startKey) ? dir : 0; });
      return { startKey, startDirection };
    }
    if (sortcols.length > 0) return {
      startKey: sortcols[0].key,
      startDirection: sortcols[0].sort < 0 ? "descending" : "ascending"
    }
    return {
      startKey: "",
      startDirection: "ascending"
    };
  });

  const [curSortColumnKey, setSortColumnKey] = useState<string>(startKey);
  const [curSortDirection, setSortDirection] = useState<IndexTableSortDirection>(startDirection);

  const columnSort = JSON.stringify(
    cols
      .filter(e => !e.hidden && e.sort)
      .sort((a, b) => Math.abs(a.sort) - Math.abs(b.sort))
      .map(e => [e.key, e.sort])
  );
  const visibleColumns = JSON.stringify(
    cols
      .filter(e => !e.hidden)
      .map(e => e.key)
  );

  const onSort = useCallback((index: number, direction: IndexTableSortDirection) => {
    const dir = direction === "ascending" ? 1 : direction === "descending" ? -1 : 0;
    const visCols = JSON.parse(visibleColumns);
    const key = visCols[index];
    cols.forEach((e) => { e.sort = (e.key === key) ? dir : 0; });
    setSortColumnKey(key);
    setSortDirection(direction);
  }, [cols, visibleColumns]);

  const sortColumnIndex = cols.filter(e => !e.hidden).findIndex(e => e.key === curSortColumnKey);

  console.log("useSortOpts", { curSortColumnKey, curSortDirection, sortColumnIndex });

  const hiddenIndexWatch = curAppliedFilters && JSON.stringify(rows.map(row => !matchAppliedFilters(row, curAppliedFilters)));

  const colsKeyWatch = JSON.stringify(cols.map(e => e.key));
  const colsMap = useMemo(() => new Map(cols.map(e => [e.key as string, e] as const)), [cols, colsKeyWatch]);

  const sortedRows = useMemo(() => {
    const colsSort = JSON.parse(columnSort);
    const hiddenIndex = hiddenIndexWatch && JSON.parse(hiddenIndexWatch);
    return (hiddenIndex ? rows.filter((e, i) => !hiddenIndex[i]) : rows.slice()).sort((a: any, b: any) => {
      for (const [key, sort] of colsSort) {
        const col = colsMap.get(key);
        if (!col) continue;
        const res = col.sorter(a, b, Math.sign(sort) as 1 | -1);
        if (res) return res;
      }
      return 0;
    })
  }, [rows, colsMap, columnSort, hiddenIndexWatch]);

  return {
    sortColumnIndex,
    sortDirection: curSortDirection,
    onSort,
    sortedRows,
  } as const;

}


function useTableListEffect(
  cols: DataListColumn[],
  setValue: TableRowDispatch,
  data: DataService,
  table: TABLE_NAMES,
  arrayList: readonly SPPI[],
  arraySort: readonly SPPI[],
  badgeCounts: Map<Pick<TableView<any[]>, "AND">, number | undefined>,
  customs: CustomColumnState | undefined,
  _AND: string,
) {

  const refreshToken2 = useObservable(useRefresh());

  const canceled = useRef(false);

  const AND = JSON.parse(_AND);

  const { loading, result, error } = useAsyncEffect(
    async function () {
      // cols: readonly DataListColumn[], setValue: TableRowDispatch
      // const { AND, arrayList, arraySort, badgeCounts, canceled, customs, data, doRequest, table } = this;
      if (AND === null) {
        setValue({ action: "reset", newValue: [] });
        return false;
      }
      const query = new DataQueryGraph(table, undefined, data.userRole);

      customs?.onLoadHook(query, AND);

      Promise.all([...badgeCounts.keys()].map((e) => {
        const AND = [
          ...Array.isArray(e.AND) ? e.AND : [],
          // ...convertFiltersToQuery(convertViewToFilters(cols, e.filters)),
        ];
        return query.addPromise({
          action: "count",
          table: table,
          arg: { where: AND.length ? { AND } : undefined }
        }).then((r: number) => {
          if (canceled.current) return;
          badgeCounts.set(e, r);
        });
      }));

      const value: any[] = await data.dataGraphQuery(query, "transact", {
        action: "findMany",
        table: table,
        arg: {
          select: data.selectPaths(table, arrayList, false),
          where: AND.length ? { AND } : undefined,
        }
      });

      if (!canceled.current) setValue({
        action: "reset",
        newValue: customs ? customs.onValueFilter(value) : undefined
      });

      return true;

    },
    undefined,
    async () => { canceled.current = true; if (customs) customs.canceled = true; },
    [
      data,
      table,
      arrayList,
      arraySort,
      badgeCounts,
      customs,
      _AND,
      refreshToken2
    ]
  );

  return { loading, result, error, canceled: canceled.current, };

}

export function useTableListTree(
  table: TABLE_NAMES | undefined,
  view: TableView,
  colsHook?: (cols: readonly ColumnBase[]) => void,
) {
  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);

  const tableView = useMemo(() => TableViewOptions.fromViewWithOptions(table as TABLE_NAMES, view, view), [table, view]);

  const colsHook2 = useRef(colsHook);
  colsHook2.current = colsHook;

  const { idcol, cols, TableRowRedux, initialState, customs } = useMemo(() => {

    const cols = tableView.makeDataListColumns();

    const queryColumns = cols.filter(e => e.queryColumn);

    const idcol = new DataListIdFieldColumn("id");

    const setID = true;

    const { TableRowRedux, initialState } = makeTableRowRedux(setID, cols, idcol);

    const customs = table ? new CustomColumnState(table, data, cols) : undefined;

    colsHook2.current?.(cols);

    return { cols, idcol, TableRowRedux, initialState, customs, queryColumns };

  }, [tableView, table, data]);

  const [curValue, setValue2] = useReducer(TableRowRedux, initialState);
  const setValue = useCallback((act: TableRowAction) => { if (!act.action) debugger; setValue2(act); }, [setValue2]);

  const { arrayList, arraySort } = tableView;
  const { rows, value } = curValue;

  return { idcol, cols, rows, value, setValue, arrayList, arraySort, customs, tableView };
}

function useWindowViewState(views: MaybeTableViewArray<any[]>, table: TABLE_NAMES) {
  const { get } = useAngular();
  const router = get(Router);
  const zone = get(NgZone);
  const navigate = useNavigate();

  const { curView, view } = useMemo(() => {
    const curView = views.length ? views.findIndex(e => router.url === e.url) : 0;
    const view = views.length ? views[curView] : { AND: [], title: `${table} Table`, url: `/${table}/list` };
    return { curView, view };
  }, [router.url, table, views]);


  const goToView = curView === -1 && views.find(e => {
    const url = e.url ?? `/${table}/${e.key}`
    const [, table1, mode1, id1] = router.url.split("?")[0].split("/");
    const [, table2, mode2, id2] = url.split("/");
    return table1 === table2 && mode2.startsWith(mode1);
  });
  useLayoutEffect(() => {
    if (goToView) navigate(goToView.url ?? `/${table}/${goToView.key}`, { replace: true })
  }, [goToView])

  if (!view?.url) suspend(firstValueFrom(router.events));

  const setView = useCallback((newView: number) => {
    const view = views[newView];
    if (!view) return;
    navigate(view.url ?? `/${table}/${view.key}`);
  }, [table, views, router, zone]);

  return { curView, view, setView, };
}


export interface TableViewColumnCustom<T, V> extends TableViewColumn<any, V> {
  key: string,
  calculate: (row: T) => V;
  link?: (row: T) => string | null | undefined;
  // childgroup?: TableViewColumnCustom[];
}

export function useTableCols<T>(
  /** This gets memoed */
  colsOptionsMemo: () => readonly TableViewColumnCustom<T, any>[],
  colsDeps: any[],
  // /** This is only used for type, the value is not used */
  // rowType: T[],
  idKey: keyof T,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colsOptions = useMemo(colsOptionsMemo, colsDeps);

  ok(colsOptions.length > 0, "at least one header is required");

  const inner = useMemo(() => {

    const cols = colsOptions.map((c) => {
      const col = new DataListColumn(c.key, c.sort ?? 0, CustomColumnDef.getColumnClass(c.key, c.filterType ?? "none"));
      assignColumnOptions(col, c);
      return col;
    });

    const idcol = new DataListIdFieldColumn(idKey as string);

    const setID = true;

    const { TableRowRedux, initialState } = makeTableRowRedux(setID, cols, idcol);

    const colsMap = new Map(cols.map(e => [e.key, e]));

    return { cols, idcol, colsMap, TableRowRedux, initialState };

  }, [colsOptions, idKey]);

  const [curValue, setValue] = useReducer(inner.TableRowRedux, inner.initialState);

  const { rows, value } = curValue;

  return { ...inner, rows, setValue, value };
}



const TableCheck: unique symbol = Symbol("TableCheck");
class OuterError extends Error {
  constructor(message: string, public inner: Error) { super(message); }
}

function errorOnFirst<T>(message?: string): (source: Observable<T>) => Observable<T> {
  const error1 = new Error("Error on first emission operator")
  return (source: Observable<T>) => new Observable<T>(subscriber => {
    const error2 = new OuterError("Error on first emission subscriber", error1);
    const subscription = source.subscribe({
      next(value) { subscriber.error(new OuterError("Error on first emission" + message ? ": " + message : "", error2)); },
      error(err) { subscriber.error(err); },
      complete() { subscriber.complete(); }
    });
    return () => subscription.unsubscribe();
  });
}

function suspend(prom: Promise<any>) {
  // debugger;
  throw prom;
}

