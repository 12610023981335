import { BlockStack, Box, Button, ButtonGroup, Card, Divider, InlineError, InlineStack, Loading, MenuGroupDescriptor, Page, Text, Tooltip } from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";
import { Footer } from "@shopify/polaris/components/Modal/components";
import { CubesDinero, DataQueryGraph, DataService, FieldClass, Prisma, SPPI, String, StringPathProxy, TABLE_NAMES, TableViewColumn, WHERE_balanceWhereLine, field, ok, okNull, proxy, root } from "common";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { ButtonAwait, CardTitle, StatusBadge, Tone, emitGlobalRefresh, useAngular, useAsyncEffect, useLoadingMarkup, useObservable, useObserver, useRefresh, useSubscriptionClosed } from "react-utils";
import { NEVER } from "rxjs";
import { QuestionForm } from "../components/QuestionForm";
import { QuestionTableComp } from "../components/QuestionTableComp";
import { FormsQuestionService } from "../utils/forms-question.service";
import { DataPage, GroupDialog } from "../utils/question-dialog";
import { UIService } from "../utils/ui.service";
import { useBranchSelector } from "../utils/useBranchSelector";
import { DataListColumn, FGCV, QuestionGroup, QuestionValues } from "../utils";
import { TableView, TableViewClass, TableViewOptions } from "../tables/table-views";
import { SelectTable, TableListInner, TableListSimple, TableViewColumnCustom, useTableListTree } from "../tables/TableListInner";
import { capitalize } from "@shopify/polaris/utilities/capitalize";
import { useCustomTable } from "./useCustomTable";
import { ledgerTableColumns, ledgerTableViews } from "./page-branch-ledger";
import { plural } from "pluralize";


export function PageBranchSalesTax() {
  const { get, injector } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);
  const refresh = useRefresh();

  const actionGroups: MenuGroupDescriptor[] = [];

  const { curBranch, curBranchTitle, branchSelectorActionGroup } = useBranchSelector();

  if (data.status.branchType === "CENTRAL" && branchSelectorActionGroup)
    actionGroups.push(branchSelectorActionGroup);

  const [by, setBy] = useState<"month" | "quarter">("quarter");

  const [balance, setBalance] = useState(0);

  const [curColOptions, setColOptions] = useState<readonly TableViewColumnCustom<any, any>[]>([
    { title: capitalize(by), key: "period", filterType: "text", calculate: e => e.period },
  ]);

  const paymentsMarkup = useBranchPayments(curBranch);

  const taxesMarkup = useCustomTable(() => curColOptions, [curColOptions], async () => {
    const { list, rows } = await getSalesTaxTable(data, curBranch, by);
    if (curBranch) {
      const { branches, salesTax } = await data.server.queryBranchBalance({ AND: [{ id: curBranch }] });
      setBalance(salesTax.find(e => e[0] === curBranch)?.[1] || 0);
    }
    setColOptions(list);
    return rows;
  }, [curBranch, by], {
    idKey: "period",
    showFilters: false,
    emptyMarkup: curBranch ? undefined : () => (
      <Page title="Sales Tax" actionGroups={actionGroups} fullWidth >
        <Card padding="400">
          <Text as="p" variant="bodyMd">No Branch selected.</Text>
        </Card>
      </Page>
    ),
    resourceName: { singular: "Customer Payment", plural: "CustomerPayments" }
  });

  const branchStatus = useBranchStatusButtons(curBranch, balance);

  return <Page
    title={"Sales Tax for " + curBranchTitle}
    actionGroups={actionGroups}
    fullWidth
  >
    <BlockStack gap="400">
      <Card padding="400">
        <BlockStack gap="400">
          <CardTitle title="Sales Tax Payouts" padding="0" >
            <InlineStack gap="200" align="start">
              {branchStatus}
            </InlineStack>
          </CardTitle>
          {curBranch ? <Text as="p" variant="bodyMd">Balance: {DataListColumn.textCubesDinero(balance)}</Text> : null}
          <p>You can request your sales tax payouts at any time, typically whenever you need to pay your sales tax.</p>
          {paymentsMarkup}
        </BlockStack>
      </Card>
      <Card padding="400">
        <BlockStack gap="400">
          <CardTitle title="Sales Tax Summary" padding="0" />
          <p style={{ maxWidth: "40rem" }}>These are the numbers needed for filing your sales tax.</p>
          <ButtonGroup>
            <Button onClick={() => setBy("quarter")} variant={by === "quarter" ? "primary" : "secondary"} >View by Quarter</Button>
            <Button onClick={() => setBy("month")} variant={by === "month" ? "primary" : "secondary"}  >View by Month</Button>
          </ButtonGroup>
          {taxesMarkup}
          <p style={{ maxWidth: "40rem" }}>
            These numbers should only change if a customer payment gets voided or has not cleared yet.
            The payment date determines which {by} the sales tax is in.
            If multiple payments apply to a single invoice line, the date of the last payment is used.
          </p>
        </BlockStack>
      </Card>
    </BlockStack>
  </Page>;
}

function useBranchStatusButtons(branchID: string, balance: number): React.JSX.Element {
  const fq = useAngular().get(FormsQuestionService);
  const data = useAngular().get(DataService);

  const { result: stripeStatus } = useAsyncEffect(async () => {
    if (!branchID) return;
    return await data.server.serverStripeAccountGetStatus({ PaymentLedger: "Branch", hostID: branchID });
  });

  const hasinfo = !!stripeStatus?.payouts_enabled;
  const requirements = stripeStatus?.requirements;
  const hasFutureReqs = !!requirements?.eventually_due?.length;
  const hasCurrentReqs = !!requirements?.currently_due?.length;
  const currentdue = requirements?.current_deadline ? "by " + new Date(requirements.current_deadline).toLocaleString() : "soon";

  if (!branchID) return <Text as="p" variant="bodyMd">No Branch selected.</Text>;


  return <>
    <StatusArray children={[
      [
        !!hasinfo,
        "success",
        "Payouts enabled",
        "attention",
        "Payouts disabled"
      ],
      [
        hasFutureReqs,
        hasCurrentReqs ? "critical" : "warning",
        hasCurrentReqs ? "Stripe needs more info " + currentdue : "Stripe needs more info eventually",
        "success",
        "Sripe info up to date"
      ]
    ]} />
    <ButtonAwait disabled={!balance || !hasinfo} onClick={async () => {
      await data.server.serverRequestSalesTaxPayout({ branchID, amount: balance });
      emitGlobalRefresh();
    }}>Request Sales Tax Payout</ButtonAwait>
  </>
}

function StatusArray({ children }: { children: [boolean, Tone, string, Tone, string][]; }) {
  return <>{children.map(([value, trueTone, trueLabel, falseTone, falseLabel]) =>
    <StatusBadge {...{ value, trueTone, trueLabel, falseTone, falseLabel }} />
  )}</>;
}


abstract class Grouper<T, R> extends Map<string, R> {

  public abstract ingest(a: T): void;
  constructor() {
    super();
  }

  map<T>(fn: (value: R, key: string, map: Map<string, R>) => T, thisArg?: any): T[] {
    return [...this.entries()].map(([key, value]) => fn(value, key, this), thisArg);
  }

}

class OuterGrouper extends Grouper<TaxTableData, InnerGrouper> {
  constructor(public by: "month" | "quarter") {
    super();
  }
  ingest(e: TaxTableData) {

    ok(e.branch?.DisplayName);

    if (!this.has(e.branch.DisplayName))
      this.set(e.branch.DisplayName, new InnerGrouper(this.by));

    this.get(e.branch.DisplayName)!.ingest(e);
  }
}

class InnerGrouper extends Grouper<TaxTableData, {
  Total: number,
  Taxable: number,
  Exempt: number,
  SalesTax: Record<string, number>,
}> {
  Jurisdictions: Set<string> = new Set();
  constructor(public by: "month" | "quarter") {
    super();
  }
  ingest(e: TaxTableData) {
    let key;
    const date = e.paidOn;
    ok(date); // the where clause should have filtered out nulls
    if (this.by === "quarter") {
      const month = +date.split("-")[1];
      const quarter = (month - 1) / 3 | 0;
      const year = date.split("-")[0];
      key = `${year} Q${quarter + 1}`
    } else {
      const month = date.split("-")[1];
      const year = date.split("-")[0];
      key = `${year}-${month}`;
    }

    if (!this.has(key)) this.set(key, {
      Total: 0,
      Taxable: 0,
      Exempt: 0,
      SalesTax: {}
    });

    const totals = this.get(key)!;

    ok(e.line.customerLedgerLine);
    const TaxTotal = e.line.salesTaxLedgerLine.reduce((a, f) => a + f.Amount, 0);
    const Customer = e.line.customerLedgerLine.Amount - TaxTotal;

    totals.Total += Customer;

    if (TaxTotal === 0)
      totals.Exempt += Customer;
    else
      totals.Taxable += Customer;

    e.line.salesTaxLedgerLine.forEach(f => {
      const key = `${f.TaxJurisdiction} Sales Tax (${f.TaxPercent}%)`;
      if (!totals.SalesTax[key]) totals.SalesTax[key] = 0;
      totals.SalesTax[key] += f.Amount;
      this.Jurisdictions.add(key);
    })

  }

}

const taxTableDataQuery = (curBranch: string | undefined) => proxy.invoiceLine.findMany({
  select: {
    branch: { select: { DisplayName: true } },
    paidOn: true,
    line: {
      select: {
        Date: true,
        customerLedgerLine: { select: { Amount: true } },
        salesTaxLedgerLine: { select: { Amount: true, TaxJurisdiction: true, TaxPercent: true } },
      }
    }
  },
  where: {
    branchID: curBranch,
    line: {
      AND: [
        WHERE_balanceWhereLine(),
      ],
    }
  }
});
type TaxTableData = Awaited<ReturnType<typeof taxTableDataQuery>>[number];

export async function getSalesTaxTable(data: DataService, curBranch: string | undefined, by: "month" | "quarter") {
  const res = await data.singleDataQuery(taxTableDataQuery(curBranch || undefined));

  const groups = new OuterGrouper(by);

  res.forEach(e => { groups.ingest(e); });

  // const customField = (key: string, index: number) => new FieldClass(key, index, new CubesDinero(false), false, false);
  const list: TableViewColumnCustom<any, any>[] = [
    { title: capitalize(by), key: "period", filterType: "text", calculate: e => e.period },
    { title: "Gross Reciepts (before tax)", key: "Total", filterType: "currency", calculate: e => e.Total },
    { title: "Reciepts exempt from sales tax", key: "Exempt", filterType: "currency", calculate: e => e.Exempt },
    { title: "Taxable Reciepts (before tax)", key: "Taxable", filterType: "currency", calculate: e => e.Taxable },
  ];

  if (curBranch) {
    const values = [...groups.values()];
    if (values.length > 1) debugger;
    if (values.length === 0) return { list, rows: [] };
    const totals = values[0];
    if (!totals) return { list, rows: [] };
    totals.Jurisdictions.forEach(jurisdiction => {
      list.push({
        key: jurisdiction,
        title: jurisdiction,
        filterType: "currency",
        calculate: (row: any) => row[jurisdiction]
      });
    });
    const rows = totals.map((totals, period) => {
      const { Exempt, SalesTax, Taxable, Total } = totals;
      return { period, Total, Exempt, Taxable, ...SalesTax };
    }).sort((a, b) => {
      return -a.period.localeCompare(b.period);
    });
    return { list, rows };
  } else {
    list.unshift({ title: "Branch", key: "branch", filterType: "text", calculate: e => e.branch });
    list.push({ title: "Total Tax", key: "Tax", filterType: "currency", calculate: e => e.Tax });
    const rows = groups.map((totals, branch) => {
      return totals.map((totals, period) => {
        const { Exempt, SalesTax, Taxable, Total } = totals;
        return { branch, period, Total, Exempt, Taxable, Tax: Object.values(SalesTax).reduce((a, f) => a + f, 0), };
      });
    }).flat().sort((a, b) => {
      return -a.branch.localeCompare(b.branch) || -a.period.localeCompare(b.period);
    });
    return { list, rows };
  }




}

function useBranchPayments(curBranch: string | undefined) {

  const { get, injector } = useAngular();
  const ui = get(UIService);
  const data = get(DataService);
  const fq = get(FormsQuestionService);

  const views = useMemo(() => [TableViewClass.makeClientView("SalesTaxLedger", {
    key: "payments",
    title: "Payments",
    list: x => [
      x.line.Date.__,
      x.line.paymentLine.PaymentStatus.__,
      { key: x.Amount.__, title: "Branch" },
      { key: x.line.paymentLine.txnID.__, hidden: true },
    ],
    sort: x => [
      `-${x.line.Date.__}` as SPPI,
    ],
    AND: [{
      branchID: curBranch || undefined,
      line: {
        VoidSince: null,
        IS_TESTING: false,
        paymentLine: { is: {} },
      }
    }] satisfies Prisma.BranchLedgerWhereInput[],
  })], [curBranch]);

  const hiddenColumns = StringPathProxy<"SalesTaxLedger">()(x => curBranch ? [x.branch.DisplayName.__] : []);

  const onSelectRow = (id: string | undefined) => { if (id) fq.onClickLedgerLine("salesTax", id, false); }

  return <TableListInner
    table={"SalesTaxLedger"}
    views={views}
    hiddenColumns={hiddenColumns}
    onSelectRow={onSelectRow}
    hideFilters
  />;
}