import { DataService } from "common";
import React from "react";
import { useAngular, useLoadingMarkup } from "react-utils";
import { FormsQuestionService } from "../utils";
import { useCustomTable } from "./useCustomTable";
import { Banner } from "@shopify/polaris";

const tabLabels = ["All", "Ready", "Failed"] as const;
const tabEnum = Object.fromEntries(new Map(tabLabels.map((e, i) => [e, i]))) as Record<typeof tabLabels[number], number>;


export function useAutopayTable(curBranch: string | undefined, curTab: number, setTab?: React.Dispatch<React.SetStateAction<number>>, showFilters?: boolean) {
  const { get } = useAngular();
  const data = get(DataService);
  const fq = get(FormsQuestionService);

  const loadingMarkup = useLoadingMarkup("customer ledger lines");
  const emptyMarkup = () => <Banner title="No Unpaid Fees" tone="success" />;

  return useCustomTable(() => [
    { key: "Date", title: "Date", calculate: e => e.Date, sort: -1 },
    { key: "name", title: "Customer", queryColumn: true, calculate: e => e.customerBilling?.Name, link: e => `/Customer/edit/${e.customerID}` },
    { key: "email", title: "Email", queryColumn: true, calculate: e => e.customerEmail },
    { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
    { key: "unit", title: "Unit", calculate: e => e.rental.unit.Name, link: e => e.rental.unit.id && `/Unit/edit/${e.rental.unit.id}` },
    { key: "branch", title: "Branch", calculate: e => e.rental.unit.currentBranch.DisplayName },
    // { key: "willAutopay", title: "Will Autopay", filterType: "boolean", calculate: e => e.shouldAutopay && !e.autopayAttempted && e.oldLinesPaid, },
    { key: "customerReady", title: "Customer Ready", filterType: "boolean", calculate: e => e.customerReady },
    { key: "customerAutopay", title: "Customer Autopay", filterType: "boolean", calculate: e => e.customerAutoPay },
    { key: "shouldAutopay", title: "Should Autopay", filterType: "boolean", calculate: e => e.shouldAutopay },
    { key: "hasOldLines", title: "Old Lines Paid", filterType: "boolean", calculate: e => e.oldLinesPaid },
    // {
    //   key: "autopayAttempted",
    //   title: "Autopay Attempted",
    //   filterType: "boolean",
    //   calculate: e => e.autopayAttempted,
    //   markup: (val) => {
    //     return <Badge tone={val ? "critical" : "success"}>{val ? "Yes" : "No"}</Badge>
    //   }
    // },
    // { key: "currentAutopay", title: "Not Future", filterType: "boolean", calculate: e => !e.isFuture },
    { key: "autopayAttempts", title: "Autopay Attempts", filterType: "numeric", calculate: e => e.autopayAttempts },
    // { key: "attemptAutopay", title: "Attempt Autopay", filterType: "boolean", calculate: e => e.attemptAutopay },
    { key: "customerBalance", title: "Customer Balance", filterType: "currency", calculate: e => e.customerBalance },
  ], [], async () => {

    let newValue = await data.server.queryGetAutopayForCustomerLedger({
      AND: [{
        line: {
          VoidSince: null,
          OR: [
            { invoiceLine: { paidOn: null, branchID: curBranch || undefined } },
            { paymentLine: { PaymentStatus: { not: "Cleared" } } },
          ]
        }
      }]
    });

    switch (curTab) {
      case tabEnum.All:
        return newValue;
      case tabEnum.Failed:
        return newValue.filter(e => {
          // if this is in the future, this isn't failed
          if (e.isFuture) return false;
          // if we will attempt it, this isn't failed
          if (e.attemptAutopay) return false;
          // if autopay succeeded, this isn't failed
          if (e.autopayAttempted && e.autopayAttemptsStatus.some(e => e && e.inArray(["Approved", "Cleared"]))) return false;
          // autopay failed or won't be tried
          if (!e.shouldAutopay || e.autopayAttempted || e.isOld || !e.customerAutoPay || !e.customerReady || !e.oldLinesPaid) return true;
          debugger;
          return false;
        });
      case tabEnum.Ready:
        return newValue.filter(e => e.attemptAutopay);
      default:
        throw new Error("Invalid tab");
    }
  }, [curTab, curBranch], {
    idKey: "id",
    curTab,
    setTab,
    showFilters,
    tabLabels: setTab ? tabLabels : undefined,
    emptyMarkup,
    loadingMarkup,
    resourceName: { singular: "Customer Invoice Line", plural: "Customer Invoice Lines" },
  });

}

useAutopayTable.tabLabels = tabLabels;
useAutopayTable.tabEnum = tabEnum;
