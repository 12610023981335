import React, { Dispatch, SetStateAction, useCallback, useId, useMemo, useState } from 'react';
import { SelectIcon } from '@shopify/polaris-icons';
import { classNames, variationName } from '@shopify/polaris/components/Select/../../utilities/css';
import { Labelled, helpTextID } from '@shopify/polaris/components/Select/../Labelled';
import { LabelledProps } from '@shopify/polaris/components/Select/../Labelled';
import { Box } from '@shopify/polaris/components/Select/../Box';
import { Icon } from '@shopify/polaris/components/Select/../Icon';
import { Text } from '@shopify/polaris/components/Select/../Text';
import { Error } from '@shopify/polaris/components/Select/../../types';
import { useToggle } from '@shopify/polaris/components/Select/../../utilities/use-toggle';
import styles from '@shopify/polaris/components/Select/Select.scss';
import { Popover } from '@shopify/polaris';
import { useComboBoxState } from './TextFieldDropdown';




export interface SelectCustomProps {
  open: boolean;
  focus: boolean;

  optionsMarkup?: React.ReactNode;
  selectedMarkup?: React.ReactNode;
  /** Label for the select */
  label: React.ReactNode;
  /** Adds an action to the label */
  labelAction?: LabelledProps['action'];
  /** Visually hide the label */
  labelHidden?: boolean;
  /** Show the label to the left of the value, inside the control */
  labelInline?: boolean;
  /** Disable input */
  disabled?: boolean;
  /** like disabled, but without styling or dropdown icon */
  readonly?: boolean;
  /** Additional text to aide in use */
  helpText?: React.ReactNode;
  /** ID for form input */
  id?: string;
  /** Value for form input */
  value?: string;
  /** Display an error state */
  error?: Error | boolean;
  /** Callback when selection is changed. SelectedContext uses this, so it should be memo-ized. */
  onChange?(selected: string): void;
  /** Callback when select is focused */
  onFocus?(event?: React.FocusEvent<HTMLButtonElement>): void;
  /** Callback when focus is removed */
  onBlur?(event?: React.FocusEvent<HTMLButtonElement>): void;
  setState: React.Dispatch<{
    focus?: boolean | undefined;
    open?: boolean | undefined;
  }>;
  /** Visual required indicator, add an asterisk to label */
  requiredIndicator?: boolean;
  /** Indicates the tone of the select */
  tone?: 'magic';

}
const PLACEHOLDER_VALUE = '';
export function SelectCustom({
  optionsMarkup,
  selectedMarkup,
  label,
  labelAction,
  labelHidden: labelHiddenProp,
  labelInline,
  disabled,
  helpText,
  id: idProp,
  value = PLACEHOLDER_VALUE,
  error,
  onChange,
  focus,
  open,
  onFocus,
  onBlur,
  setState,
  requiredIndicator,
  tone,
  readonly,
  ...rest
}: SelectCustomProps) {

  const uniqId = useId();
  const id = idProp ?? uniqId;


  const className = classNames(
    styles.Select,
    error && styles.error,
    tone && styles[variationName('tone', tone) as keyof typeof styles],
    disabled && styles.disabled
  );

  const handleFocus = useCallback(() => { onFocus?.(); }, [onFocus]);

  const handleBlur = useCallback(() => { onBlur?.(); }, [onBlur]);

  const describedBy: string[] = [];
  if (helpText) {
    describedBy.push(helpTextID(id));
  }
  if (error) {
    describedBy.push(`${id}Error`);
  }

  const inlineLabelMarkup = labelInline && (
    <Box paddingInlineEnd="100">
      <Text
        as="span"
        tone={tone && tone === 'magic' && !focus ? 'magic-subdued' : 'subdued'}
        truncate
      >
        {label}
      </Text>
    </Box>
  );

  const dropdownId = useId();
  // console.log(disabled, readonly, error);
  return (
    <Labelled
      id={id}
      label={label}
      error={error}
      action={labelAction}
      labelHidden={labelInline ? true : labelHiddenProp}
      helpText={helpText}
      requiredIndicator={requiredIndicator}
      disabled={disabled}
      readOnly={readonly}
    >
      {/* <SelectCustom.SelectedContext.Provider value={selectedContext}> */}
      <Popover
        active={open}
        onClose={() => { console.log("onClose"); setState({ open: false }); }}
        fullWidth
        activator={<div className={className}>
          <button
            id={id}
            className={`${styles.Input} ${readonly ? "pointer-events-none" : ""}`}
            role="combobox"
            aria-labelledby="select button"
            aria-haspopup="listbox"
            aria-expanded="false"
            aria-controls={dropdownId}
            disabled={disabled || readonly}
            onClick={(disabled || readonly) ? undefined : !open ? handleFocus : handleBlur} />
          <div className={styles.Content} aria-hidden aria-disabled={disabled}>
            {inlineLabelMarkup}
            <span className={styles.SelectedOption}>{selectedMarkup}</span>
            <span className={styles.Icon}>
              {!readonly && <Icon source={SelectIcon} />}
            </span>
          </div>
          <div className={styles.Backdrop} />
        </div>}
      >
        {optionsMarkup}
      </Popover>
      {/* </SelectCustom.SelectedContext.Provider> */}
    </Labelled>
  );
  //     <div class="custom-select">
  //       <button
  //         class="select-button"
  //         role="combobox"
  //         aria-labelledby="select button"
  //         aria-haspopup="listbox"
  //         aria-expanded="false"
  //         aria-controls={dropdownId}
  //       >
  //         <span class="selected-value">Open this select menu</span>
  //         <span class="arrow"></span>
  //       </button>
  //       <ul class="Polaris-Card" role="listbox" id={dropdownId}>
  //         {options.map(option => (
  //           <li role="option" aria-selected={selected}>
  //             <input type="radio" id={id} name="social-account" />
  //             <label htmlFor={id}>{children}</label>
  //           </li>
  //         ))}
  //       </ul>
  //     </div>
}
SelectCustom.SelectedContext = React.createContext<{
  selected: string;
  onSelect: (value: string) => void;
}>(undefined as never);
