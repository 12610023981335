import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { QuestionTinyMCE } from "../utils/question-base";
import { Labelled } from "@shopify/polaris";


export function QuestionTinyMCEComp({ q, ...commonProps }: {
  q: QuestionTinyMCE;
  id: string;
  label: string;
  required: boolean;
  disabled: boolean;
  readOnly: boolean;
  readonly: boolean;
  autoComplete: string;
  helpText: string | undefined;
}) {
  return q.init ? (
    <Labelled {...commonProps}><Editor
      ref={editor => { if (editor) q.updateEditor(editor); }}
      init={q.init as any}
      value={q.form.value?.html ?? ""}
      onEditorChange={(newValue, editor) => {
        q.form.setValue({ html: newValue, text: editor.getContent({ format: "text" }) })
      }}
    /></Labelled>
  ) : null;
}