import { Box, Button, ButtonGroup, Card, InlineStack, Labelled, LabelledProps, Listbox, Text, TextField, Tooltip } from "@shopify/polaris";
import { AttributeField, ColumnBase, DataListColumn, QuestionSelectOptions } from '../utils';
import React, { useCallback, useId, useMemo, useState } from "react";
import { MatIcon, valMarkup } from "react-utils";
import { SelectButtons, SelectDropdown, SelectProps } from "./Select";
import styles from '@shopify/polaris/components/Select/Select.scss';
import { PopoverDropdown, useComboBoxState } from "./TextFieldDropdown";

interface QuestionSelectProps<T extends Record<string, any>> {
  id: string;
  label: string;
  required: boolean;
  disabled: boolean;
  readOnly: boolean;
  autoComplete: string;
  labelHidden?: boolean;
  options: T[];
  optionValue?: string & keyof T;
  optionDisabled?: string & keyof T;
  optionLabels: ColumnBase[];
  optionIcon?: string & keyof T;
  onFocus: () => void;
  placeholder: string | undefined;
  loading?: boolean;
  /** if optionValue is set, this will be T[optionValue], otherwise it will be T */
  value: any;
  /** if optionValue is set, value will be T[optionValue], otherwise it will be T */
  onChange: (value: any) => void;
  allowClear?: boolean;
  display?: AttributeField<"select", "display">;
}



export function QuestionSelectComp<T extends Record<string, string>>({
  optionDisabled,
  optionLabels,
  optionIcon,
  optionValue,
  options,
  value,
  onChange,
  label,
  display = "dropdown",
  ...props
}: QuestionSelectProps<T>) {

  const onChange2 = useCallback((selected: string) => {
    if (!selected) { onChange(null); return; }
    const selectedValue = options[+selected];
    if (optionValue) {
      onChange(selectedValue[optionValue]);
    } else {
      onChange(selectedValue);
    }
  }, [optionValue, options, onChange]);

  const options2 = useMemo(() => options.map((opt, i): SelectProps["options"][number] => ({
    value: "" + i,
    label: display === "buttons" ? optionLabels.map(label => label.walk(opt, 'text') ?? "---").join(" - ") :
      <>
        {optionIcon && <div className={styles.Prefix}>{<MatIcon>{opt[optionIcon]}</MatIcon>}</div>}
        <InlineStack as="span" wrap={false} gap="200" >{optionLabels.map(label => (
          <Tooltip content={label.title} key={label.title}>{valMarkup(label, opt)}</Tooltip>
        ))}</InlineStack>
      </>,
    disabled: optionDisabled ? !!opt[optionDisabled] : false,
    prefix: optionIcon ? <MatIcon>{opt[optionIcon]}</MatIcon> : undefined,
  })), [options, optionLabels, optionDisabled, optionIcon, display]);

  const selected = options.findIndex(opt => optionValue ? opt[optionValue] === value : opt?.id === value?.id);

  const value2 = selected === -1 ? "" : "" + selected;

  if (display === "dropdown")
    return <SelectDropdown
      options={options2}
      label={label}
      value={value2}
      onChange={onChange2}
      {...props}
    />;


  if (display === "buttons") {
    return <SelectButtons
      options={options2}
      label={label}
      value={value2}
      onChange={onChange2}
      {...props}
    />;
  }

  throw new Error("Unhandle display type for QuestionSelectComp: " + display);


}
