import { Badge, BlockStack, Box, Button, ButtonGroup, Card, Grid, MenuActionDescriptor, MenuGroupDescriptor, Page, Spinner, Text, useBreakpoints } from "@shopify/polaris";
import { DataQueryGraph, DataService, Generator, Modes, PaymentLedgerKeys, TABLE_NAMES, UIService, is, isTable, ok, okNull, schema } from "common";
import React, { useMemo, useState } from "react";
import { ButtonAwait, EventEmitter, Injector, PageSidebarStyles, StatusBadge, Tone, useAngular, useAsyncEffect,  useForward, useObservable, useObserver, useWatchMemo } from "react-utils";
import { QuestionForm } from "../components/QuestionForm";
import { BranchSelectorRefresh, DataPage, DataPageSchemaFunc, FGCR, FormsQuestionService, QuestionGroup, QuestionSubGroup, UI_Schema, useDataPage } from "../utils";

import Dinero from "dinero.js";
import pluralize from "pluralize";
import { useRefresh, useRoute } from "react-utils";
import { usePaymentProcessor } from "../payments/ProvidePaymentProcessor";
import { CustomColumnState } from "../tables/CustomColumnState";
import { Card2 } from "./Card2";

import { startWith } from "rxjs";
import { makePaymentDialog } from "../payments/makePaymentDialog";

export interface TableDetailProps {
  key?: string;
  ref?: React.RefObject<HTMLDivElement>;
}

function Status({ label, tone }: { label: string, tone: Tone }) {
  const progress = tone === "success" ? "complete" : "incomplete";
  return <Badge size="large" progress={progress} tone={tone}>{label}</Badge>;
}


export function EditTablePage() {

  const { table, view2: view, id } = useRoute();
  ok(isTable(table), "Invalid table name.");
  const { get, injector } = useAngular();
  const fq = get(FormsQuestionService);
  const data = get(DataService);
  const ui = get(UIService);

  const mode = ({ add: "CREATE", edit: "UPDATE" } as const)[view] || "READ";

  // this isn't required because globalRefresh and onSaveSuccess are already hooked 
  // into in the component and at the very least cause loadingChange to emit
  // useObservable(globalRefresh);

  const canEdit = table === "Owner" ? data.status.isAdmin || data.status.branchType === "CENTRAL" : true;

  console.log(schema.tables[table].attributes.privOpts?.first());

  const actionGroups: MenuGroupDescriptor[] = [];
  const devActions: MenuGroupDescriptor["actions"] = [];
  if (data.status.isArlen)
    actionGroups.push({
      title: "Dev Actions",
      actions: devActions,
    })

  devActions.push({
    content: "Delete",
    onAction: () => fq.deleteTableItem(table, id)
  });

  const secondaryActions: MenuActionDescriptor[] = [];

  if (table === "User" && mode === "UPDATE") {
    secondaryActions.push({
      content: "Sync Permissions",
      onAction: async () => {
        okNull(id);
        await data.server.serverSyncUserPermissions({ userID: id });
        fq.toast("success", "Success", "User permissions synced.");
      }
    })
  }


  const adapt = useDataPage(table, mode, id);

  let paymentModalsMarkup: React.JSX.Element | undefined;
  let paymentStatusMarkup: React.JSX.Element | undefined;

  if (mode === "UPDATE")

    if (table === "Branch" || table === "Owner" || table === "Division") {
      ok(is<DataPage<"Branch" | "Division" | "Owner", "UPDATE">>(adapt, true));
      const { result: stripeStatus } = useAsyncEffect(async () => {
        await data.server.serverStripeAccountUpdateStatus({ PaymentLedger: table, hostID: id });
        // console.log(await data.server.serverStripeAccountList({}));
        return await data.server.serverStripeAccountGetStatus({ PaymentLedger: table, hostID: id });
      });

      const hasinfo = !!stripeStatus?.payouts_enabled;
      const isAdmin = !!data.status.isAdmin;
      // const { result: stripeStatus } = useAsyncEffect(async () => {
      //   return await data.server.serverStripeAccountGetStatus({ PaymentLedger: "Branch", hostID: branchID });
      // }, undefined, undefined, [branchID]);
      const requirements = stripeStatus?.requirements;
      const hasFutureReqs = !!requirements?.eventually_due?.length;
      const hasCurrentReqs = !!requirements?.currently_due?.length;
      const currentdue = requirements?.current_deadline ? "by " + new Date(requirements.current_deadline).toLocaleString() : "soon";

      paymentStatusMarkup = !stripeStatus ? <Card></Card> : (
        <Card roundedAbove="sm">
          <Grid columns={{ xs: 1, lg: 2 }}>
            <Grid.Cell>
              <BlockStack gap="100">
                <Text as="p">{adapt.group?.form.value?.billing?.Name}</Text>
                <Text as="p">{adapt.group?.form.value?.billing?.Address?.description}</Text>
                <Text as="p">{adapt.group?.form.value?.billing?.Phone}</Text>
                <Text as="p">{(adapt.group?.form.value as any)?.Email}</Text>
              </BlockStack>
            </Grid.Cell>
            <Grid.Cell>
              <BlockStack gap="400">
                <StatusArray>
                  {[!!hasinfo, "success", "Payouts enabled", "attention", "Payouts disabled"]}
                  {[
                    hasFutureReqs,
                    hasCurrentReqs ? "critical" : "warning",
                    hasCurrentReqs ? "Stripe needs more info " + currentdue : "Stripe needs more info eventually",
                    "success",
                    "Sripe info up to date"
                  ]}
                </StatusArray>
                <ButtonGroup>
                  {isAdmin && <ButtonAwait variant="secondary" onClick={async () => {
                    await fq.onClickRecordPayout(table, id);
                  }}>Record External Payout</ButtonAwait>}
                  <ButtonAwait variant="primary" onClick={async () => {
                    await data.openStripeWindow(table, id);
                  }}>Update Payout Info</ButtonAwait>
                  {hasinfo && isAdmin && <ButtonAwait variant="primary" tone="critical" onClick={async () => {
                    const dialog = makePaymentDialog(fq, data, table, id, async (amount) => {
                      const result = await data.server.serverStripeAccountTransfer({
                        PaymentLedger: table, hostID: id, amount: amount.getAmount()
                      }).catch(error => {
                        alert("Something went wrong. Please contact support.");
                        return { success: false, reason: undefined };
                      });
                      if (result.success) adapt.pageSetup(false);
                      return result;
                    });
                    await dialog.pageSetup(false);
                  }}>Send Payout</ButtonAwait>}
                </ButtonGroup>
              </BlockStack>
            </Grid.Cell>
          </Grid>
        </Card >
      );
    }



  useForward(useRefresh(), adapt.onSaveSuccess);

  // useAsyncEffect(async () => {
  //   await adapt.pageSetup(false);
  // }, async () => {
  //   adapt.subs.unsubscribe();
  // }, undefined, [adapt]);


  // useAsyncEffect(async () => {
  //   const query = new DataQueryGraph(table, id, data.status.userRole);
  //   customs.onLoadHook(query, [{ id }]);
  //   data.dataGraphQuery(query, "requests");
  //   await Promise.all([
  //     customs.promiseBranchBalance ?? Promise.resolve(),
  //     customs.promiseOwnerBalance ?? Promise.resolve(),
  //     customs.promiseDivisionBalance ?? Promise.resolve(),
  //   ])
  //   setBalance(customs.balanceLookupMap?.get(id) ?? 0);
  // }, undefined, undefined, [globalRefreshToken]);

  useObserver(adapt.onSaveSuccess, async (e) => {
    if (table === "Branch" || table === "Owner" || table === "Division") {
      await data.server.serverRefreshDivisionGroups({});
    }
    BranchSelectorRefresh.emit({});
    if (mode === "CREATE") {
      fq.toast("success", "Success", "Save successful.");
      if (e.id) fq.showEdit(table, e.id);
      else fq.showList(table, {});
    } else {
      fq.toast("success", "Success", "Save successful.");
      adapt.pageSetup(false);
    }
  });

  const primaryAction: MenuActionDescriptor | null = (table === "Customer" || !schema.tables[table]) ? null : {
    content: canEdit ? 'Save' : 'Readonly',
    disabled: !canEdit,
    onAction: async () => { await adapt.onClickSave() }
  };

  const loading = useObservable(adapt.loadingChange, true);

  console.log("EditTablePage", table, view, id, adapt);

  const { lgDown, lgUp: twoColumns } = useBreakpoints();




  // const facilitatorAccountID = "ca68b0ab-453c-4fe6-be38-d1d1afbd0134";
  // const [paymentMethodOpen, setPaymentMethodOpen] = useState(false);
  // const accountID = "0675b279-e20d-407a-92ba-d9be7c22898f";
  // const { result: tokenPaymentMethods } = useAsyncEffect(async () => {
  //   const token = await data.server.serverBranchMoovToken({
  //     accountID,
  //     scopes: [
  //       `/fed.read`,
  //       `/accounts/${accountID}/bank-accounts.read`,
  //       `/accounts/${accountID}/bank-accounts.write`,
  //     ]
  //   });
  //   return token.token;
  // });
  // useLayoutEffect(() => {
  //   if (!tokenPaymentMethods) return;
  //   const paymentMethods = document.querySelector('moov-payment-methods') as any as PaymentMethodsProps | null;
  //   console.log(paymentMethods);
  //   if (!paymentMethods) return;
  //   paymentMethods.accountID = accountID;
  //   paymentMethods.token = tokenPaymentMethods;
  //   paymentMethods.open = true;
  //   paymentMethods.paymentMethodTypes = ["bankAccount"];
  //   paymentMethods.onError = (e) => { debugger; console.log(e); }
  //   paymentMethods.onCancel = () => { paymentMethods.open = false; }
  //   paymentMethods.onResourceCreated = (e) => { globalRefresh.emit({}); }
  //   paymentMethods.onSuccess = () => { globalRefresh.emit({}); }
  // });

  const styles = PageSidebarStyles(twoColumns);

  return (
    <Page
      // eslint-disable-next-line no-restricted-globals
      backAction={{ onAction: () => { fq.showList(table, {}) } }}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      actionGroups={actionGroups}
      title={schema.tables[table] ? pluralize(table) : table}
      compactTitle={true}
    >
      <Box paddingBlock="600">
        <BlockStack gap="400" >
          {/* {paymentMethodOpen && <moov-payment-methods />} */}
          {loading && <Spinner size="small" />}
          {!loading && paymentStatusMarkup}
          {!loading && canEdit && adapt.group && <QuestionForm group={adapt.group} mode={mode} />}
          {paymentModalsMarkup}
        </BlockStack>
        <Box padding="300" />
      </Box>
    </Page>
  )

}

function StatusArray({ children }: { children: [boolean, Tone, string, Tone, string][]; }) {
  return <>{children.map(([value, trueTone, trueLabel, falseTone, falseLabel]) =>
    <StatusBadge {...{ value, trueTone, trueLabel, falseTone, falseLabel }} />
  )}</>;
}
