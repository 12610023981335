import { parentFilterArg } from "./cubes-schema-from-prisma";
import { Member, TableType, rls_layer_1, PermissionsOptionsBuilder, PermissionsOptions } from "./cubes-schema-helpers";
import { GenericPathProxyWithTable, PrivLevel, PrivScope, SPPF, SPPFH, SPPI, StringPathProxy, TypeTreeWithID, ViewListFunc, TableViewColumn } from "./cubes-utils";
import { RecordType, RecordMember, IsArray } from "./graphql-declarator";

import * as sc from "./cubes-schema";
type EnumKeys<T> = T extends `${infer X}` ? X : never
export type MemberKeys<T> = T extends `${infer X}` ? X :
  { [K in string & keyof T]:
    K extends `$${string}` ? never :
    K extends `__${string}` ? never :
    K;
  }[string & keyof T];

export class DirectiveSimple<T extends DirectiveOptions> { __host?: Record<T, true>; }

type Boolean<T extends boolean = boolean> = T;
type CubesDinero<T extends number = number> = T;
type Float<T extends number = number> = T;
type ID<T extends string = string> = T;
type Int<T extends number = number> = T;
type ScalarDate<T extends string = string> = T;
type ScalarDateTime<T extends string = string> = T;
type ScalarEmail<T extends string = string> = T;
type ScalarIPAddress<T extends string = string> = T;
type ScalarJSON<T extends any = never> = T;
type ScalarPhone<T extends string = string> = T;
type ScalarTime<T extends string = string> = T;
type ScalarTimestamp<T extends string = string> = T;
type ScalarURL<T extends string = string> = T;
type String<T extends string = string> = T;
type SignupSource = MemberKeys<sc.SignupSource>;
type BranchUserLevel = MemberKeys<sc.BranchUserLevel>;
type AccountingType = MemberKeys<sc.AccountingType>;
type ActionType = MemberKeys<sc.ActionType>;
type AuthProviderAmplify = MemberKeys<sc.AuthProviderAmplify>;
type AuthStrategyAmplify = MemberKeys<sc.AuthStrategyAmplify>;
type BillingStatus = MemberKeys<sc.BillingStatus>;
type BranchType = MemberKeys<sc.BranchType>;
type CacheQuery = MemberKeys<sc.CacheQuery>;
type ChargeSource = MemberKeys<sc.ChargeSource>;
type ConfirmType = MemberKeys<sc.ConfirmType>;
type CustomerType = MemberKeys<sc.CustomerType>;
type DocusignEnvelopeStatus = MemberKeys<sc.DocusignEnvelopeStatus>;
type EditorType = MemberKeys<sc.EditorType>;
type FieldFilterType = MemberKeys<sc.FieldFilterType>;
type InvoiceStatus = MemberKeys<sc.InvoiceStatus>;
type ItemType = MemberKeys<sc.ItemType>;
type LookupMethod = MemberKeys<sc.LookupMethod>;
type ModelOperation = MemberKeys<sc.ModelOperation>;
type ModelSubscriptionLevel = MemberKeys<sc.ModelSubscriptionLevel>;
type NoticeTypes = MemberKeys<sc.NoticeTypes>;
type OwnerPaymentSchedule = MemberKeys<sc.OwnerPaymentSchedule>;
type PaymentLedger = MemberKeys<sc.PaymentLedger>;
type PaymentStatus = MemberKeys<sc.PaymentStatus>;
type PersonIdentityDocType = MemberKeys<sc.PersonIdentityDocType>;
type PostgresRoles = MemberKeys<sc.PostgresRoles>;
type PostgresTablePermissions = MemberKeys<sc.PostgresTablePermissions>;
type PostgresUserRoles = MemberKeys<sc.PostgresUserRoles>;
type ProRating = MemberKeys<sc.ProRating>;
type QuickbooksChartOfAccountsCategory = MemberKeys<sc.QuickbooksChartOfAccountsCategory>;
type ReferentialActions = MemberKeys<sc.ReferentialActions>;
type RentalStatus = MemberKeys<sc.RentalStatus>;
type RentalType = MemberKeys<sc.RentalType>;
type SectionType = MemberKeys<sc.SectionType>;
type TransactionType = MemberKeys<sc.TransactionType>;
type UnitStatus = MemberKeys<sc.UnitStatus>;


interface CustomerCardInfo {
  __type?: "record",
  CardNumber?: String
  CardType?: String
  ExpirationDate?: String
  NameOnCard?: String
  Last4?: String
}




type ModelQueryMap = sc.ModelQueryMap["__value"];
type ModelMutationMap = sc.ModelMutationMap["__value"];
type ModelSubscriptionMap = sc.ModelSubscriptionMap["__value"];
type TimestampConfiguration = sc.TimestampConfiguration["__value"];

export class model<H extends TableType> extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: {
    uniques?: String<MemberKeys<H>>[][]
    queries?: ModelQueryMap
    mutations?: ModelMutationMap
    subscriptions?: ModelSubscriptionMap
    timestamps?: TimestampConfiguration
  }) {
    super();
  }
}
export class mapsTo extends DirectiveSimple<"OBJECT" | "FIELD_DEFINITION"> {
  constructor(public attr: {
    name?: String
  }) {
    super();
  }
}
type AuthRuleAmplify = sc.AuthRuleAmplify["__value"];

export class authAmplify extends DirectiveSimple<"OBJECT" | "FIELD_DEFINITION"> {
  constructor(public attr: {
    rules?: AuthRuleAmplify[]
  }) {
    super();
  }
}
export class authPostgres extends DirectiveSimple<"OBJECT" | "FIELD_DEFINITION"> {
  constructor(public attr: {
    role?: "app" | EnumKeys<PostgresUserRoles>
    privelages?: EnumKeys<PostgresTablePermissions>[]
  }) {
    super();
  }
}
export class index<H extends TableType> extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    name?: String
    sortKeyFields?: String<MemberKeys<H>>[]
  }) {
    super();
  }
}
export class hasOne extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    remote?: String
    fields?: String[]
    relationName?: String
    updateVerb?: String
  }) {
    super();
  }
}
export class hasMany<T extends TableType, H extends TableType> extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    indexName?: String
    fields?: String<MemberKeys<H>>[]
    limit?: Int
    remote?: String<MemberKeys<T>>
    detailType?: String
  }) {
    super();
  }
}
export class belongsTo<H extends RecordType> extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    root?: String<MemberKeys<H>>
    fields?: String<MemberKeys<H>>[]
    // unique?: Boolean
    onUpdate?: EnumKeys<ReferentialActions>
    onDelete?: EnumKeys<ReferentialActions>
    isRelation?: boolean;
  }) {
    super();
  }
}
export class belongsToRoot<H extends RecordType> extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    /** The belongsTo member for which this scalar is the root */
    rootFor?: String<MemberKeys<H>>
  }) {
    super();
  }
}
export class manyToMany extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    relationName?: String
    limit?: Int
  }) {
    super();
  }
}
export class key extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: {
    fields?: String[]
    name?: String
    queryField?: String
  }) {
    super();
  }
}
export class aws_iam extends DirectiveSimple<"OBJECT" | "FIELD_DEFINITION"> {
  constructor(public attr: {
    a1?: String
  }) {
    super();
  }
}
export class aws_api_key extends DirectiveSimple<"OBJECT" | "FIELD_DEFINITION"> {
  constructor(public attr: {
    a1?: String
  }) {
    super();
  }
}
export class aws_subscribe extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    mutations?: String
  }) {
    super();
  }
}
interface FieldPropsBase<T extends RecordMember<any>, H extends RecordType> {
  title?: String
  filterType?: EnumKeys<FieldFilterType>
  helptext?: String
  lefticon?: String[]
  righticon?: String[]
  /** This implies onlyfor: ["UPDATE"] and also makes fields readonly. */
  preventUpdate?: Boolean
  /** This implies onlyfor: ["CREATE"] and also makes fields readonly. */
  preventCreate?: Boolean
  /** This implies onlyfor: ["DELETE"] and also makes fields readonly. */
  preventDelete?: Boolean
  /** This is only used for getValue calls. It does not make fields readonly. */
  onlyfor?: EnumKeys<ConfirmType>[]
  inputType?:
  | 'text'
  | 'email'
  | 'number'
  | 'integer'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'time'
  | 'week'
  | 'currency';

  // | "button"
  // | "checkbox"
  // | "color"
  // | "date"
  // | "datetime-local"
  // | "email"
  // | "file"
  // | "hidden"
  // | "image"
  // | "month"
  // | "number"
  // | "password"
  // | "radio"
  // | "range"
  // | "reset"
  // | "search"
  // | "submit"
  // | "tel"
  // | "text"
  // | "time"
  // | "url"
  // | "week";
  hidden?: Boolean
  default?: ScalarJSON<PrismaJson.AllTypes["field_default"]>
  replicate?: String<`./${MemberKeys<H>}` | `../${string}` | `./${MemberKeys<H>}/${string}`>
  subform?: String
  unique?: Boolean
  /** Prevents the field from being added to the database schema. 
   * This might not prevent certain schema attributes like index or mapsTo 
   * from still being attempted and probably causing errors. 
   */
  clientSideOnly?: Boolean
  clientSideLoad?: ScalarJSON<PrismaJson.AllTypes["PrismaPromise"]>
  clientSidePath?: String
  fieldClass?: String
  /** If true, prevents users from various operations that could be affected by RLS. */
  rlsRestrict?: Boolean
  useDBNull?: Boolean
  arrayWhere?: unknown
}

interface FieldPropsInput<T extends RecordMember<any>, H extends RecordType> extends FieldPropsBase<T, H> {
  arrayList?: ViewListFunc<T>
  arraySort?: SPPF<T>
  extraGetPaths?: SPPFH<T, H>
}
interface FieldPropsOutput<T extends RecordMember<any>, H extends RecordType> extends FieldPropsBase<T, H> {
  arrayList?: (SPPI | TableViewColumn)[]
  arraySort?: SPPI[]
  extraGetPaths?: SPPI[]
}
export interface FilterWith<T extends TableType, H extends RecordType> {
  __type?: "record",
  filterThis: String<MemberKeys<T>>,
  filterWith: String<MemberKeys<H> | `../${string}` | `${MemberKeys<H>}/${string}`>
  onlyfor?: EnumKeys<ConfirmType>[]
  queryName?: String
}
export class field<T extends RecordMember<any>, H extends RecordType> extends DirectiveSimple<"FIELD_DEFINITION" | "ENUM_VALUE"> {
  public attr: FieldPropsOutput<T, H>;
  declare public input: FieldPropsInput<T, H>;
  constructor(attr: FieldPropsInput<T, H>) {
    super();

    this.attr = attr as any;
    if (attr.arrayList) this.attr.arrayList = StringPathProxy<T>()(attr.arrayList);
    if (attr.arraySort) this.attr.arraySort = StringPathProxy<T>()(attr.arraySort);
    // if (attr.arrayFilter) this.attr.arrayFilter = StringPathProxy<T>()(attr.arrayFilter);
    if (attr.extraGetPaths) this.attr.extraGetPaths = StringPathProxy<T, H>()(attr.extraGetPaths);
  }
}
export class validators extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    min?: number
    max?: number
    minLength?: number;
    maxLength?: number;
    required?: boolean;
    requiredTrue?: boolean;
    email?: boolean;
    pattern?: string | RegExp
  }) {
    super();
  }
}
export class inputNumber extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    min?: number
    max?: number
    minLength?: number;
    maxLength?: number;
    required?: boolean;
    requiredTrue?: boolean;
    email?: boolean;
    pattern?: string | RegExp
  }) {
    super();
  }
}
export class select<T extends RecordMember<any>, H extends RecordType> extends DirectiveSimple<"FIELD_DEFINITION" | "ENUM_VALUE"> {
  constructor(public attr: {
    display?: String<"buttons" | "listbox" | "dropdown" | "checkbox" | "radiocircle">;
    forReadonly?: { hostTable: string, relation: MemberKeys<H> }
  }) {
    super();
  }
}

interface formsPropsBase<H extends RecordType> {
  singular?: String
  plural?: String
  defaultTab?: String
  extraForms?: ScalarJSON<PrismaJson.AllTypes["forms_extraForms"]>
}
interface formsPropsInput<H extends RecordType> extends formsPropsBase<H> {
  // list?: SPPF<H> // SPPI<string>[]
  // sort?: SPPF<H> // SPPI<string>[]
  filter?: SPPF<H> // SPPI<string>[]
}
interface formsPropsOutput<H extends RecordType> extends formsPropsBase<H> {
  // list?: SPPI<string>[]
  // sort?: SPPI<string>[]
  filter?: SPPI[]
}


export class forms<H extends RecordType> extends DirectiveSimple<"ENUM" | "OBJECT"> {
  attr: formsPropsOutput<H>;
  constructor(attr: formsPropsInput<H>) {
    super();
    this.attr = attr as any;
    // if (attr.list) this.attr.list = StringPathProxy<H>()(attr.list);
    // if (attr.sort) this.attr.sort = StringPathProxy<H>()(attr.sort);
    if (attr.filter) this.attr.filter = StringPathProxy<H>()(attr.filter);
  }
}
export class uniquePrisma<H extends TableType> extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: {
    fields?: String<MemberKeys<H>>[]
    name?: String
    map?: String
    sort?: String
    clustered?: Boolean
  }) {
    super();
  }
}
export class relationPrisma<T extends TableType, H extends TableType> extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    name?: String
    remote?: String<MemberKeys<T>>
    fields?: String<MemberKeys<H>>[]
    references?: String<MemberKeys<T>>[]
    map?: String
    onUpdate?: EnumKeys<ReferentialActions>
    onDelete?: EnumKeys<ReferentialActions>
  }) {
    super();
  }
}
export class indexPrisma<H extends TableType> extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: {
    fields: String<MemberKeys<H>>[]
    name?: String
    map?: String
    length?: Number
    sort?: String
    clustered?: Boolean
    type?: String
    ops?: String
  }) {
    super();
  }
}
export class editor extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    type?: EnumKeys<EditorType>
    options?: ScalarJSON<PrismaJson.AllTypes["editor_options"]>
  }) {
    super();
  }
}
export class indexComposite<H extends TableType> extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: {
    fields?: String<MemberKeys<H>>[]
    name?: String
    map?: String
    sort?: String
    clustered?: Boolean
    type?: String
    ops?: String
  }) {
    super();
  }
}
export class lookup<T extends TableType, H extends RecordType> extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    // queryName_internal?: String<`List${string}Lookup`>
    // queryName?: String<string>
    // querySort?: String<"ASC" | "DESC">
    // targetTable?: String
    // hostTable?: String
    // currentHasOne_RemoteID?: String<MemberKeys<T>>
    // currentHasOne?: String<MemberKeys<H>>
    // dropdownList?: String<MemberKeys<T> | `${MemberKeys<T>}/${string}`>[]
    // dropdownSort?: String<MemberKeys<T> | `${MemberKeys<T>}/${string}`>[]
    // dropdownFilter?: FilterWith<T, H>[]
    // lookupOnly?: Boolean
    // optionValue?: String<MemberKeys<T>>
    // belongsTo: String<MemberKeys<H>>
    onRelation?: boolean;
    optionValue?: MemberKeys<T>,
    optionFilterWith?: FilterWith<T, H>[],
    optionFilterWhere?: parentFilterArg<T>[],
    targetTable: string,
  }) {
    super();
  }
}
export class filter<H extends TableType> extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: {
    USER_BRANCH_ID?: ScalarJSON<parentFilterArg<H>>
  }) {
    super();
  }
}
export class attributes<T extends RecordType, H extends RecordType> extends DirectiveSimple<"OBJECT" | "FIELD_DEFINITION"> {
  constructor(public attr: {
    target?: String<DirectiveOptions>
    attrs?: ScalarJSON<PrismaJson.AllTypes["Attributes"]>
  }) {
    super();
  }
}
export class primaryKey extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    sortKeyFields?: String[]
  }) {
    super();
  }
}
export class preset extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    field?: String
    value?: String
  }) {
    super();
  }
}
export class amplifyFunction extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
  }) {
    super();
  }
}
export class currency extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
  }) {
    super();
  }
}
export class history extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
  }) {
    super();
  }
}
export class prejoin<T extends TableType, H extends RecordType> extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    childField?: String<MemberKeys<T>>
    otherField?: String<MemberKeys<T>>
    prefill?: Boolean
  }) {
    super();
  }
}
export class ledger extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: {
    AccountType?: EnumKeys<QuickbooksChartOfAccountsCategory>
  }) {
    super();
  }
}
export class fieldComposite extends DirectiveSimple<"FIELD_DEFINITION" | "OBJECT"> {
  constructor(public attr: {
    name?: String
    opts?: ScalarJSON<PrismaJson.AllTypes["fieldComposite_opts"]>
    host?: String
    target?: String
    directive?: Boolean
  }) {
    super();
  }
}
export class noticeTags extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    NoticeType: EnumKeys<NoticeTypes>
    tags?: String[]
  }) {
    super();
  }
}
const groupsymbol: unique symbol = Symbol("group");
export class group extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: {
    [groupsymbol]: never
  }) {
    super();
  }
}
const groupchildsymbol: unique symbol = Symbol("groupchild");
export class groupchild extends DirectiveSimple<"FIELD_DEFINITION"> {
  constructor(public attr: {
    [groupchildsymbol]: never
  }) {
    super();
  }
}
type RealType<T> = T extends IsArray<infer X> ? RealType<X> : T extends Member<infer X, any> ? X : T;
// export type rls_security = "rls_security.user_id" | "rls_security.user_level" |  "rls_security.branch_id" | "rls_security.division_id";
// export type rls_layer_1_inner<H extends TableType, K extends MemberKeys<H>> = RealType<H[K]> extends TableType ? {
//   [L in MemberKeys<RealType<H[K]>>]?: rls_security | MemberKeys<H>;
// } : rls_security;
// export type rls_layer_1<H extends TableType> = {
//   [K in MemberKeys<H>]?: H[K] extends Member<any, true> ? rls_security | rls_layer_1_inner<H, K> : rls_layer_1_inner<H, K>
// };

/** The default is to allow if not specified */
export class rowlevelsecurity<H extends TableType> extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: {
    web_admin?: rls_layer_1<H>,
    web_central_admin?: false | rls_layer_1<H>,
    web_central_user?: false | rls_layer_1<H>,
    web_dealer_admin?: false | rls_layer_1<H>,
    web_dealer_user?: false | rls_layer_1<H>,
  }) {
    super();
  }
}

export class initdata extends DirectiveSimple<"OBJECT"> {
  attr: { data: string }
  constructor(data: string) {
    super();
    this.attr = { data };
  }
}

interface rlsPropsInput<T extends TableType, H extends TableType> {
  remoteTable: { new(): T, name: string }
  groups: (x: TypeTreeWithID<H>) => SPPI;
  rows: (x: TypeTreeWithID<H>) => SPPI;
}
export interface RLS {
  /** The reference table that is used for groups and rows permissions. */
  remoteTable: string;
  /** The path to the group id for the reference table */
  groups: SPPI;
  /** The path to the row id for the reference table */
  rows: SPPI;
}
export class rls extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: RLS) { super(); }
  // constructor(public input: rlsPropsInput<T, H>) {
  //   super();
  //   this.attr = input as any;
  //   const name = input.remoteTable?.name;
  //   if (!name) throw new Error("remoteTable must be specified");
  //   this.attr.remoteTable = name;
  //   if (input.groups) this.attr.groups = GenericPathProxyWithTable(name, input.groups);
  //   if (input.rows) this.attr.rows = GenericPathProxyWithTable(name, input.rows);
  // }
}

export class privOpts extends DirectiveSimple<"OBJECT"> {
  constructor(public attr: PermissionsOptions) {
    super();
  }
}

interface _Attributes {
  indexPrisma: indexPrisma<any>
  amplifyFunction: amplifyFunction
  attributes: attributes<any, any>
  authAmplify: authAmplify
  authPostgres: authPostgres
  aws_api_key: aws_api_key
  aws_iam: aws_iam
  aws_subscribe: aws_subscribe
  belongsToRoot: belongsToRoot<any>
  belongsTo: belongsTo<any>
  currency: currency
  editor: editor
  field: field<any, any>
  fieldComposite: fieldComposite
  filter: filter<any>
  forms: forms<any>
  group: group
  hasMany: hasMany<any, any>
  hasOne: hasOne
  history: history
  index: index<any>
  indexComposite: indexComposite<any>
  initdata: initdata
  inputNumber: inputNumber
  key: key
  ledger: ledger
  lookup: lookup<any, any>
  manyToMany: manyToMany
  mapsTo: mapsTo
  model: model<any>
  noticeTags: noticeTags
  prejoin: prejoin<any, any>
  preset: preset
  primaryKey: primaryKey
  relationPrisma: relationPrisma<any, any>
  rowlevelsecurity: rowlevelsecurity<any>
  select: select<any, any>
  uniquePrisma: uniquePrisma<any>
  validators: validators
  rls: rls
  privOpts: privOpts
}

type ForHost<T, K, O extends DirectiveOptions = any> = T extends { __host?: Record<O, true> } ? K : never;
type t2 = ForHost<_Attributes["field"], "field", "FIELD_DEFINITION">;
type t3 = field<any, any> extends { attr: infer X } ? X : false;
export type Attributes<O extends DirectiveOptions = any> = {
  [K in keyof _Attributes as ForHost<_Attributes[K], K, O>]?: _Attributes[K]["attr"][]
}
export type Attribute<O extends DirectiveOptions = any> = {
  [K in keyof _Attributes as ForHost<_Attributes[K], K, O>]?: _Attributes[K]["attr"]
}
export type Directives<O extends DirectiveOptions = any> = {
  [K in keyof _Attributes as ForHost<_Attributes[K], K, O>]?: _Attributes[K]
}
export type DirectiveInput<O extends DirectiveOptions = any> = Directives<O>[keyof Directives<O>];
export type DirectiveOptions =
  | "SCHEMA"
  | "SCALAR"
  | "OBJECT"
  | "FIELD_DEFINITION"
  | "ARGUMENT_DEFINITION"
  | "INTERFACE"
  | "UNION"
  | "ENUM"
  | "ENUM_VALUE"
  | "INPUT_OBJECT"
  | "INPUT_FIELD_DEFINITION"
  ;
