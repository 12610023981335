var styles = {
  "Toast": "Polaris-Frame-Toast",
  "Action": "Polaris-Frame-Toast__Action",
  "error": "Polaris-Frame-Toast--error",
  "CloseButton": "Polaris-Frame-Toast__CloseButton",
  "LeadingIcon": "Polaris-Frame-Toast__LeadingIcon",
  "toneMagic": "Polaris-Frame-Toast--toneMagic",
  "WithActionOnComponent": "Polaris-Frame-Toast__WithActionOnComponent"
};

export { styles as default };
