import React, { Dispatch, SetStateAction, useCallback, useId, useMemo, useState } from 'react';
import { SelectIcon } from '@shopify/polaris-icons';
import { classNames, variationName } from '@shopify/polaris/components/Select/../../utilities/css';
import { Labelled, helpTextID } from '@shopify/polaris/components/Select/../Labelled';
import { LabelledProps } from '@shopify/polaris/components/Select/../Labelled';
import { Box } from '@shopify/polaris/components/Select/../Box';
import { Icon } from '@shopify/polaris/components/Select/../Icon';
import { Text } from '@shopify/polaris/components/Select/../Text';
import { Error } from '@shopify/polaris/components/Select/../../types';
import { useToggle } from '@shopify/polaris/components/Select/../../utilities/use-toggle';
import styles from '@shopify/polaris/components/Select/Select.scss';
import { MatIcon } from 'react-utils';





export interface TextFieldReadonlyProps {
  fieldMarkup?: React.ReactNode;
  /** Label for the select */
  label: React.ReactNode;
  /** Adds an action to the label */
  labelAction?: LabelledProps['action'];
  /** Visually hide the label */
  labelHidden?: boolean;
  /** Show the label to the left of the value, inside the control */
  labelInline?: boolean;
  /** Disable input */
  disabled?: boolean;
  /** Additional text to aide in use */
  helpText?: React.ReactNode;
  /** ID for form input */
  id?: string;
  /** Display an error state */
  error?: Error | boolean;
  /** Callback when selection is changed. SelectedContext uses this, so it should be memo-ized. */
  onChange?(selected: string): void;
  /** Callback when select is focused */
  onFocus?(event?: React.FocusEvent<HTMLButtonElement>): void;
  /** Callback when focus is removed */
  onBlur?(event?: React.FocusEvent<HTMLButtonElement>): void;
  onClick?(): void;
  /** Visual required indicator, add an asterisk to label */
  requiredIndicator?: boolean;
  /** Indicates the tone of the select */
  tone?: 'magic';
}
const PLACEHOLDER_VALUE = '';

export function TextFieldReadonly({
  fieldMarkup,
  label,
  labelAction,
  labelHidden: labelHiddenProp,
  labelInline,
  disabled,
  helpText,
  id: idProp,
  error,
  onChange,
  onFocus,
  onBlur,
  onClick,
  requiredIndicator,
  tone,
}: TextFieldReadonlyProps) {

  const uniqId = useId();
  const id = idProp ?? uniqId;


  const className = classNames(
    styles.Select,
    error && styles.error,
    tone && styles[variationName('tone', tone) as keyof typeof styles],
    disabled && styles.disabled,
  );

  const describedBy: string[] = [];
  if (helpText) {
    describedBy.push(helpTextID(id));
  }
  if (error) {
    describedBy.push(`${id}Error`);
  }

  const inlineLabelMarkup = labelInline && (
    <Box paddingInlineEnd="100">
      <Text
        as="span"
        tone={tone && tone === 'magic' ? 'magic-subdued' : 'subdued'}
        truncate
      >
        {label}
      </Text>
    </Box>
  );

  return (
    <Labelled
      id={id}
      label={label}
      error={error}
      action={labelAction}
      labelHidden={labelInline ? true : labelHiddenProp}
      helpText={helpText}
      requiredIndicator={requiredIndicator}
      disabled={disabled}
    >
      <div className={className}>
        {onClick && <button
          id={id}
          className={styles.Input + " cursor-pointer"}
          disabled={disabled}
          onClick={onClick} />}
        <div className={styles.Content} aria-disabled={disabled}>
          {inlineLabelMarkup}
          <span className={styles.SelectedOption}>{fieldMarkup}</span>
          {onClick && <span className={styles.Icon}>
            <MatIcon>arrow_forward_ios</MatIcon>
          </span>}
        </div>
        <div className={styles.Backdrop} />
      </div>
    </Labelled>
  );

}
