import { useCallback, useMemo } from "react";

import { NgZone } from "@angular/core";
import { Router } from "@angular/router";
import {
  BlockStack,
  Box,
  Card,
  InlineGrid,
  InlineStack,
  Page,
  Text
} from '@shopify/polaris';
import { ColorBackgroundAlias } from "@shopify/polaris-tokens";
import { MenuGroupDescriptor } from "@shopify/polaris/types";
import { Customer, CustomerLedger, DataService, Prisma, Rental, SPPF, SPPI, dateCubes } from "common";
import { format } from "date-fns";
import { plural, singular } from "pluralize";
import { Chart } from 'primereact/chart';
import { Route } from "react-router";
import { RevenueChartsDataSource, StatusBadge, Tone, useAngular, useAsyncEffect } from "react-utils";
import { TableListSimple } from "../tables/TableListInner";
import { TableView } from "../tables/table-views";
import { useBranchSelector } from '../utils';
// import { useSingleDataQuery } from "common";
/** calls singular for the given text if count is 1 */
function singularCount(text: string, count: number) {
  return count === 1 ? singular(text) : text;
}
/** calls plural for the given text if count is not 1 */
function pluralCount(text: string, count: number) {
  return count !== 1 ? plural(text) : text;
}

export function CardChart({ type, props, title, button, onClick }: {
  type: string; props: any; title: string; button: string; onClick?: () => void
}) {
  return (
    <Card>
      <InlineStack align="space-between">
        <Text as="h2" variant="headingMd">{title}</Text>
        {/* <Button onClick={onClick}>{button}</Button> */}
      </InlineStack>
      <Chart type={type} height="200px" {...props} />
    </Card>
  );
}

export default function RouteDashboard() {
  return (
    <Route path="Branch/dashboard">
      <Route path="" Component={Dashboard} />
      <Route path=":curBranch" Component={Dashboard} />
    </Route>
  );
}

function StatusArray({ children }: { children: [boolean, Tone, string, Tone, string][]; }) {
  return <>{children.map(([value, trueTone, trueLabel, falseTone, falseLabel]) =>
    <StatusBadge {...{ value, trueTone, trueLabel, falseTone, falseLabel }} />
  )}</>;
}

export function Dashboard() {
  const { get } = useAngular();
  const data = get(DataService);
  const router = get(Router);
  const zone = get(NgZone);
  const actionGroups: MenuGroupDescriptor[] = [];
  const { branchSelectorActionGroup, curBranch, curBranchTitle } = useBranchSelector();

  if (branchSelectorActionGroup && data.status.branchType === "CENTRAL")
    actionGroups.push(branchSelectorActionGroup);

  const { result } = useAsyncEffect(async () => await Promise.all([
    RevenueChartsDataSource(data, curBranch, true),
    RevenueChartsDataSource(data, curBranch, false),
    getStripeStatus(data, curBranch),
  ]), async () => { }, async () => { }, [curBranch]);

  const [curRevenueChartData, curUnpaidChartData, stripeStatus] = result ?? [];

  const rentalStatusWatchView = useMemo(() => makeRentalStatusWatchView(curBranch), [curBranch]);

  const customerLatePaymentView = useMemo(() => makeCustomerLatePaymentView(curBranch), [curBranch]);

  const customerPaymentsView = useMemo(() => makeCustomerPaymentsView(curBranch), [curBranch]);

  const hasinfo = !!stripeStatus?.payouts_enabled;
  const requirements = stripeStatus?.requirements;
  const hasFutureReqs = !!requirements?.eventually_due?.length;
  const hasCurrentReqs = !!requirements?.currently_due?.length;
  const currentdue = requirements?.current_deadline ? "by " + new Date(requirements.current_deadline).toLocaleString() : "soon";

  return <Page
    title={curBranchTitle || "No Branch Selected"}
    actionGroups={actionGroups}
    fullWidth
  >
    {/* {loading ? loadingMarkup() : <p>No branch selected</p>} */}
    {(curBranch || data.userRole === "web_admin") ? <BlockStack gap="500">
      {stripeStatus && <Card>
        <BlockStack gap="400">
          <Text as="span" variant="headingSm">Stripe Account Status</Text>
          <StatusArray>
            {[!!hasinfo, "success", "Payouts enabled", "attention", "Payouts disabled"]}
            {[
              hasFutureReqs,
              hasCurrentReqs ? "critical" : "warning",
              hasCurrentReqs ? "Stripe needs more info " + currentdue : "Stripe needs more info eventually",
              "success",
              "Sripe info up to date"
            ]}
          </StatusArray>
        </BlockStack>
      </Card>}
      {(curBranch && curRevenueChartData && curUnpaidChartData ? <InlineGrid columns={2} gap="500">
        <CardChart type="bar" props={curRevenueChartData} title="Revenue" button="Ledger" onClick={() => {
          router.navigateByUrl(`/Branch/ledger-valid/${curBranch}`)
        }} />
        <CardChart type="pie" props={curUnpaidChartData} title="Awaiting Payment" button="Pending" onClick={() => {
          router.navigateByUrl(`/Branch/ledger-pending/${curBranch}`)
        }} />
      </InlineGrid> : null)}
      <Card padding="0">
        <Box padding="300">
          <Text as="span" variant="headingSm">Rentals Moving In or Moving Out</Text>
        </Box>
        <TableListSimple table="Rental" view={rentalStatusWatchView} emptyState={curBranch ? <span>None</span> : <span>No branch selected</span>} />
      </Card>
      <Card padding="0">
        <Box padding="300">
          <Text as="span" variant="headingSm">Customers with Past Due payments</Text>
        </Box>
        <TableListSimple table="Customer" view={customerLatePaymentView} emptyState={curBranch ? <span>None</span> : <span>No branch selected</span>} />
      </Card>
      <Card padding="0">
        <Box padding="300">
          <Text as="span" variant="headingSm">Customer Payments</Text>
        </Box>
        <TableListSimple table="CustomerLedger" view={customerPaymentsView} emptyState={<span>None</span>} />
      </Card>
    </BlockStack> : <Card>No branch selected</Card>}
  </Page>;

}

async function getStripeStatus(data: DataService, curBranch: string | undefined) {
  if (!curBranch) return;
  return await data.server.serverStripeAccountGetStatus({ PaymentLedger: "Branch", hostID: curBranch });
}

function makeRentalStatusWatchView(curBranch: string) {
  return ({
    key: "attention",
    title: "Attention",
    helptext: "",
    getCount: true,
    AND: [
      curBranch ? { unit: { currentBranchID: curBranch } } : {},
      { customer: { IS_TESTING: false } },
      { RentalStatus: { "in": ["Reserved", "Scheduled", "Moving_Out", "Completed"] } },
    ],
    list: (x => [
      x.customer.billing.Name.__,
      x.customer.Email.__,
      x.unit.Name.__,
      x.RentalStatus.__,
      x.StartDate.__,
      x.EndDate.__,
    ]) as SPPF<Rental>,
  } as TableView<any[], "Rental">);
}

function makeCustomerLatePaymentView(curBranch: string) {
  return ({
    key: "attention",
    title: "Attention",
    helptext: "",
    getCount: true,
    AND: [{
      IS_TESTING: false,
      // OR: curBranch ? [{
      //   AllRentals: {
      //     some: {
      //       unit: { currentBranchID: curBranch },
      //       RentalStatus: { notIn: ["Archived", "Released", "Reserved"] },
      //     }
      //   }
      // }, {
      //   AllRentals: {
      //     every: {
      //       unit: { currentBranchID: curBranch },
      //     }
      //   }
      // }, {
      //   AllRentals: {
      //     every: {
      //       RentalStatus: { in: ["Archived", "Released", "Reserved"] },
      //     },
      //     some: {
      //       unit: { currentBranchID: curBranch },
      //     }
      //   }
      // }] : [],
      LedgerLines: {
        some: {
          line: {
            VoidSince: null,
            Date: { lte: format(Date.now(), dateCubes) },
            invoiceLine: {
              paidOn: null,
              item: { ItemName: "Unit Rental" },
              rental: {
                unit: curBranch ? { currentBranchID: curBranch } : {},
                RentalStatus: { notIn: ["Archived", "Reserved"] },
              }
            }
          }
        }
      }
    }] satisfies Prisma.CustomerWhereInput[],
    list: (x => [
      x.billing.Name.__,
      x.billing.Address.description.__,
      x.PaymentInfoValid.__,
    ]) as SPPF<Customer>,
    sort: x => ["nextAutopayDate" as SPPI],

  } as TableView<any[], "Customer">)
}

function makeCustomerPaymentsView(curBranch: string) {
  return ({
    key: "attention",
    title: "Attention",
    helptext: "",
    getCount: true,
    AND: [{
      customer: curBranch ? { AllRentals: { some: { activeUnit: { currentBranchID: curBranch } } } } : undefined,
      line: { VoidSince: null, IS_TESTING: false, paymentLine: { is: {} } }
    }] satisfies Prisma.CustomerLedgerWhereInput[],
    list: x => [
      x.line.Date.__,
      x.line.customerLedgerLine.customer.billing.Name.__,
      x.Amount.__,
      x.line.paymentLine.PaymentStatus.__,
      { key: x.line.paymentLine.id.__, hidden: true, filterType: "none" },
    ],
    sort: x => [
      `-${x.line.Date.__}` as SPPI,
    ],
  } as TableView<any[], "CustomerLedger">)
}

function CardBlurb({ title, text: helptext, background, link }: { title: string, link?: string, text?: string, dir?: "up" | "down", background?: ColorBackgroundAlias }) {
  // <Icon source={ArrowUpMinor} color="primary" />
  const { get } = useAngular();
  const router = get(Router);
  const onClick = useCallback(() => link && router.navigateByUrl(link), [router, link]);
  return (
    <Card background={background} >
      <div className="flex flex-column align-items-center select-none cursor-pointer" style={{
        color: "var(--color-text-brand-on-bg-fill)",
      }} onClick={onClick}>
        <span style={{ fontSize: "1.5rem", }}>
          <span style={{
            display: "inline-block",
            verticalAlign: "bottom",
          }}></span>
          <span>{title}</span>

        </span>
        <p>{helptext}</p>
      </div>
    </Card>
  );
}
