import { EventEmitter, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Badge, Banner, BlockStack, Box, Button, ButtonProps, Card, Checkbox, Divider, Icon, InlineStack, MenuGroupDescriptor, ModalProps, Page, Spinner, Text, Tooltip } from '@shopify/polaris';
import { DataQueryGraph, DataService, GenericPathProxy, Modes, ok, PaymentLedgerKeys, PrismaQuery, proxy, SelectTypeTree, StringPathProxy, TABLE_NAMES, Unit } from 'common';
import { ButtonAwait, CardTitle, CheckboxAwait, emitGlobalRefresh, ModalHostEvents, StatusBadge, Tone, useAngular, useAsyncEffect, useLoadingMarkup, useObservable, useObserver, useRefresh, useWatchMemo } from "react-utils";
import { DataPage, FGCR, FormsQuestionService, GroupDialog, QuestionBase, QuestionGroup, QuestionInputNumber, QuestionRender, QuestionSimple, QuestionSubGroup, QuestionTable, showPageEditModal, TypedQuestionGroup, UI_Schema, UIService, useBranchSelector, useDataPage } from "../utils";
import { memo, PropsWithChildren, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { CustomColumnState } from '../tables/CustomColumnState';
import Dinero from "dinero.js";
import { renderQuestionItem } from '../components/QuestionForm';
import { InfoIcon } from '@shopify/polaris-icons';

function formatDinero(item: Dinero.Dinero | number) {
  if (typeof item === "number") item = Dinero({ amount: item });
  return item.toFormat("$0,0.00");
}

declare const close: unknown;

export function PageBranchUnitsGrid() {

  const { get } = useAngular();
  const data = get(DataService);
  const ui = get(UIService);
  const actionGroups: MenuGroupDescriptor[] = [];

  const { curBranch, branchSelectorActionGroup } = useBranchSelector();

  const loadingMarkup = useLoadingMarkup("branches");

  const refresh = useObservable(useRefresh());

  if (branchSelectorActionGroup && data.status.branchType === "CENTRAL")
    actionGroups.push(branchSelectorActionGroup);

  const { result, loading } = useAsyncEffect(async () => {
    const query = new DataQueryGraph("UnitType", "", data.status.userRole);
    if (!curBranch) return null;
    const [units] = await Promise.all([
      query.addPromise(proxy.unitType.findMany({
        select: {
          id: true,
          Name: true,
          AllUnits: {
            include: { currentRental: { include: { customer: { include: { billing: true, }, } } } },
            where: { currentBranchID: curBranch }
          }
        }
      })),
      data.dataGraphQuery(query, "requests")
    ]);
    units.sort((a, b) => {
      const [aSize, aExtra = ""] = a.Name.split(" ");
      const [bSize, bExtra = ""] = b.Name.split(" ");
      const aName = aSize.split("x");
      const bName = bSize.split("x");
      if (aName.length < bName.length) return -1;
      if (aName.length > bName.length) return 1;
      if (+aName[0] < +bName[0]) return -1;
      if (+aName[0] > +bName[0]) return 1;
      if (+aName[1] < +bName[1]) return -1;
      if (+aName[1] > +bName[1]) return 1;
      if (aExtra < bExtra) return -1;
      if (aExtra > bExtra) return 1;
      return 0;
    })
    units.forEach((e) => {
      e.AllUnits.sort((a, b) => {
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
      });
    })
    return units;
  }, undefined, undefined, [curBranch, refresh]);

  type _result = typeof result & {};
  const rentalStatus = (f: _result[number]["AllUnits"][number]) => f.currentRental?.RentalStatus?.split("_")?.join("") ?? "";
  const billingStatus = (f: _result[number]["AllUnits"][number]) => f.currentRental?.customer?.BillingStatus?.split("_")?.join("") ?? "";
  const available = (f: _result[number]["AllUnits"][number]) => f.Unavailable ? "Unavailable" : "Available";

  return (
    <Page actionGroups={actionGroups} title="Units Grid">
      {curBranch ? <Card>
        <Banner title="New! Click on units to see their details." tone="info" >
          <p>Clicking on a unit will open a popup with more information and links to related records.</p>
        </Banner>
        <div className='Cubes-Unit-Grid'>
          <div>
            <div className="grid-type">
              <div className="grid-unit Rental-Status- Billing-Status-">Available</div>
              <div className="grid-unit Rental-Status-Rented Billing-Status-Normal">Rented</div>
              <div className="grid-unit Rental-Status-Rented Billing-Status-Late">Late</div>
              <div className="grid-unit Rental-Status-Rented Billing-Status-LockedOut">Locked Out</div>
              <div className="grid-unit Rental-Status-Rented Billing-Status-Auction">Auction</div>
              <div className="grid-unit Rental-Status-MovingOut Billing-Status-Normal">MovingOut</div>
              <div className="grid-unit Rental-Status-Completed Billing-Status-Normal">Completed</div>
              <div className="grid-unit Rental-Status-Retained Billing-Status-Normal">Retained</div>
            </div>
          </div>
          {result?.map(type => (
            <div className="grid-type">
              <Text as="h2" variant='headingMd'>{type.Name}</Text>
              {type.AllUnits.map(unit => {
                return (
                  <Tooltip content={
                    unit.currentRental ? (
                      <BlockStack>
                        <div>{rentalStatus(unit)} - {billingStatus(unit)}</div>
                        <div>{unit.currentRental?.StartDate ?? ""} - {unit.currentRental?.EndDate ?? ""}</div>
                        {/* <Text as="p" variant='headingMd'>Location</Text> */}
                        {/* <Text as="p">{unit.currentLocation?.description ?? ""}</Text> */}
                        <Text as="p" variant='headingMd'>Notes</Text>
                        <Text as="p">{unit.Notes}</Text>
                        <Text as="p" variant='headingMd'>Customer</Text>
                        <div>{unit.currentRental?.customer?.billing?.Name ?? ""}</div>
                        {/* <div>{unit.currentRental?.customer?.billing?.Address?.description ?? ""}</div> */}

                        <CustomerAutopayBadge value={!!unit.currentRental?.customer?.AutoPay} />
                      </BlockStack>
                    ) : (
                      <BlockStack>
                        <Text as="p" variant='headingMd'>Status</Text>
                        <UnitAvailableBadge value={!unit.Unavailable} />
                        {/* <Text as="p" variant='headingMd'>Location</Text> */}
                        {/* <Text as="p">{unit.currentLocation?.description ?? ""}</Text> */}
                        <Text as="p" variant='headingMd'>Notes</Text>
                        <Text as="p">{unit.Notes}</Text>
                      </BlockStack>
                    )
                  }>
                    <div
                      className={`grid-unit Billing-Status-${billingStatus(unit)} Rental-Status-${rentalStatus(unit)} Unit-Status-${available(unit)} select-none cursor-pointer`}
                      onClick={() => {
                        onClickShowUnitStatus(unit.id, ui);
                        // if (unit.currentRental?.customer?.id)
                        //   router.navigateByUrl(`/Customer/edit/${unit.currentRental?.customer?.id}`);
                        // else
                        //   onClickShowUnitStatus(unit.id);
                      }
                      }
                    >{unit.Name}</div>
                  </Tooltip>
                );
              })}
            </div>
          ))}
        </div>
      </Card> : loading ? loadingMarkup() : <p>No branch selected</p>}
    </Page>
  );
}



async function onClickShowUnitStatus(unitID: string, ui: UIService) {
  showPageEditModal({
    table: "Unit",
    id: unitID,
    group: (mode) => {
      const { res: group } = TypedQuestionGroup("Unit", {
        Notes: new QuestionSimple("InputText", {}),
        currentLocation: ui.QuestionGeocodeAddress("UPDATE", true, {}),
      }, x => [
        x.id.__,
        x.Name.__,
        x.Unavailable.__,
        x.unitType.Name.__,
        x.currentBranch.DisplayName.__,
        x.currentRental.customer.id.__,
        x.currentRental.customer.Email.__,
        x.currentRental.customer.AutoPay.__,
        x.currentRental.customer.billing.Name.__,
        x.currentRental.customer.billing.Address.__,
        x.currentRental.customer.billing.Phone.__,
        x.currentRental.StartDate.__,
        x.currentRental.EndDate.__,
        x.currentRental.RentalStatus.__,
        x.currentOwner.billing.Name.__,
      ] as const, {});

      return group;
    },
    useTitle: (page) => {
      const value = page.useFresh();
      return "Unit " + (value?.unitType ? (value.Name ?? "") + " - " + (value.unitType.Name ?? "") : "")
    },
    useWhenLoaded: (page, onClose) => {
      ok(page.group);
      const { get } = useAngular();
      const data = get(DataService);

      const unit = page.useFresh();
      ok(unit);
      ok(unit.unitType);

      const currentRental = unit?.currentRental;

      const onSetUnitStatus = useCallback(async () => {
        ok(unit);
        await data.server.serverSetUnitStatus({ unitID, available: !!unit.Unavailable });
        emitGlobalRefresh();
      }, [unit, data]);

      useObservable(page.group.form.valueChanges);


      const button = (url: string, buttonText: string) => <page.ButtonAwait url={url} onClick={onClose}>{buttonText}</page.ButtonAwait>;
      return <>
        {currentRental?.customer ? <Section title="Rental" button={button("/Customer/edit/" + currentRental.customer.id, "Go to Customer")}>
          <Text as="p">{currentRental.customer.billing?.Name}</Text>
          <Text as="p">{currentRental.customer.billing?.Address?.description}</Text>
          <Text as="p">{currentRental.customer.billing?.Phone}</Text>
          <Box paddingBlock="200"><CustomerAutopayBadge value={!!currentRental.customer.AutoPay} /></Box>
          <Text as="p">Start Date: {currentRental.StartDate}</Text>
          {currentRental.EndDate ? <Text as="p">End Date: {currentRental.EndDate}</Text> : null}
        </Section> : null}
        <Section
          title="Unit"
          button={button("/Unit/edit/" + unit.id, "Go to Unit")}
        >
          <Text as="p">{unit.Name} - {unit.unitType.Name}</Text>
          {/* <Text as="p">{unit.currentBranch.DisplayName}</Text> */}
          {/* <Text as="p">{unit.currentOwner?.billing.Name}</Text> */}
          {/* <Text as="p">{}</Text> */}
          {currentRental ? null : (
            <Box paddingBlock="200">
              <InlineStack gap="400">
                <UnitAvailableBadge value={!unit.Unavailable} />
                <page.ButtonAwait tone='critical' onClick={onSetUnitStatus}>
                  {!unit.Unavailable ? "Mark Not Available" : "Mark Available"}
                </page.ButtonAwait>
              </InlineStack>
            </Box>
          )}
        </Section>
        <Section title="Location">
          <page.PageControl control={page.group.controls.currentLocation} />
        </Section>
        <Section title="Notes">
          <page.PageControl control={page.group.controls.Notes} />
        </Section>
      </>;
    }
  });

}

const Section = ({ title, button, children }: PropsWithChildren<{
  title: string;
  button?: JSX.Element;
}>) => (
  <Box>
    <InlineStack gap="400" align="space-between">
      <Text as="h3" variant='headingMd'>{title}</Text>
      {button}
    </InlineStack>
    <Box paddingInlineStart="400">
      <BlockStack>
        {children}
      </BlockStack>
    </Box>
  </Box>
);


const AddressLookupField = () => {
  return <Text as="span">Address Lookup Field</Text>
}

const UnitAvailableBadge = memo(
  ({ value }: { value: boolean }) => {
    return <StatusBadge
      value={value}
      trueTone="success"
      trueLabel="Available"
      falseTone="warning"
      falseLabel="Not Available"
    />
  }
);

const CustomerAutopayBadge = memo(
  ({ value }: { value: boolean }) => {
    return <StatusBadge
      value={value}
      trueTone="success"
      trueLabel="Autopay"
      falseTone="critical"
      falseLabel="No Autopay"
    />
  }
);


