
import { EventEmitter, Injector, NgZone } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router, UrlMatcher, UrlSegment } from '@angular/router';
import { DataQueryGraph, Modes, okNull, PrismaExtra, TYPE_NAMES, Branch, Customer, ok, StringPathProxy as SPP, proxy, SPPI, TABLE_NAMES } from 'common';
import { FGCR, QuestionGroup, QuestionInputNumber, QuestionSimple, DISABLE_HYBRID_CUSTOMER_PAGE, FormsQuestionService, QuestionTable, QuestionGroupButton, QuestionSelect, RentalListState } from '../utils';
import type { ClickEvent, } from '../utils';
import { Subscription } from 'rxjs';
import { DataService } from 'common';
import { UIService } from 'common';
import { Dialog, GroupDialog } from './question-dialog';
import { ZoneGuard } from "common";
import { CustomerType } from 'prisma-client';

import { Tone } from '@shopify/polaris/components/Badge';
import { globalMessage } from 'react-utils';


export abstract class TablePageBase {

  abstract pageSetup_beforeLoad(keepPage: boolean, data: DataQueryGraph): Promise<boolean>;
  abstract pageSetup_afterLoad(keepPage: boolean): Promise<boolean>;

  abstract table: TABLE_NAMES;
  abstract id: string;
  abstract tabs?: any;
  abstract pageTitle: any;
  abstract showSave: any;

  protected abstract router: Router;
  protected abstract data: DataService;
  protected abstract ui: UIService;
  protected abstract fq: FormsQuestionService;

  canDeactivate(): boolean | Promise<boolean> {
    return true;
  }

  mode: Modes;
  subform: any;
  charts: any;
  subforms?: { label: string }[];
  onSave: any;


  // protected dialog?: GroupDialog<any, any, any> | GroupDataDialog<any, any, any>;
  onSaved: never;
  private _loading?: boolean | undefined;
  public get loading(): boolean | undefined {
    return this._loading;
  }
  public set loading(value: boolean | undefined) {
    if (value === this._loading) return;
    this._loading = value;
    this.loadingChange.emit(this._loading);
  }
  loadingChange = new EventEmitter<boolean>()

  private _table!: UrlSegment;
  private _mode!: UrlSegment;
  private _id?: UrlSegment;
  protected subs: Subscription;
  buttons: QuestionGroupButton[] = [];


  #fresh: any;
  get fresh() { return this.#fresh === undefined ? undefined : JSON.parse(this.#fresh); }
  set fresh(v: any) { this.#fresh = v === undefined ? undefined : JSON.stringify(v); }

  private _value: Record<string, any> | undefined;
  public get value(): Record<string, any> | undefined {
    return this._value;
  }
  public set value(value: Record<string, any> | undefined) {
    this._value = value;
    this.valueChange.emit(this._value);
  }
  public valueChange = new EventEmitter<Record<string, any> | undefined>();
  paths: SPPI[];


  get buttonsVisible() {
    return this.buttons?.some(b => !this.subform ? !b.subform : b.subform === this.subform.label);
  }
  buttonloading = new Set();
  async buttonclick(e: any, gb: QuestionGroupButton) {
    try {
      this.buttonloading.add(gb);
      await gb.onClick();
    } finally {
      this.buttonloading.delete(gb);
    }
  }

  cancelLoading() {
    this.loading = false;
  }

  catchError = (e: any) => {
    console.log(e);
    this.cancelLoading();
    alert("An error occurred while loading the page. You can try refreshing the page.");
  };


  async handleRouteUrl(url: { table: string, view: string, id: string }) {
    try {
      this.loading = true;
      if (!this.parseRoute(url)) return;
      this.ui.title = this.pageTitle;
      if (!await this.pageSetup(false)) return;
    } finally {
      this.loading = false;
    }
  }


  private parseRoute(url: { table: string, view: string, id: string }): boolean {
    this.table = url.table as TABLE_NAMES;
    switch (url.view) {
      case "edit": this.mode = "UPDATE"; break;
      case "add": this.mode = "CREATE"; break;
      // default: this.router.navigateByUrl(`/${url.table}/list`); return false;
    }
    if (!url.id) return false;
    this.id = url.id as string;
    return true;
  }

  pageSetupCallback = (keepPage: boolean) => this.pageSetupLoading(keepPage);

  async pageSetupLoading(keepPage: boolean) {
    try {
      this.loading = true;
      return await this.pageSetup(keepPage);
    } finally {
      this.loading = false;
    }
  }

  async pageSetup(keepPage: boolean): Promise<boolean> {

    this.loading = true;

    this.paths = [];
    this.buttons = [];

    const data = new DataQueryGraph(this.table, this.id, this.data.userRole);

    if (!await this.pageSetup_beforeLoad(keepPage, data)) return false;

    if (this.id && this.mode === "UPDATE") {

      const fresh = await this.data.dataGraphQuery(data, "requests", {
        action: "findUnique",
        table: this.table as any,
        arg: {
          where: { id: this.id },
          select: this.data.selectPaths(this.table, this.paths, false)
        }
      }).catch(this.catchError);

      if (!fresh) return false;

      this.fresh = fresh;

      this.value = fresh;
    } else {
      await this.data.dataGraphQuery(data, "requests").catch(this.catchError);
    }

    if (!await this.pageSetup_afterLoad(keepPage)) return false;

    // await this.dialog?.pageSetup(true);

    requestAnimationFrame(() => this.tabs?.updateInkBar());

    this.loading = false;

    return true;

  }



}

export class TablePageCustomerAdapter extends TablePageBase {

  static matcher: UrlMatcher = (url) => {
    if (url.length < 2) return null;
    const table = url[0].path;
    const mode = url[1].path;
    if (table === "Customer" && !mode.startsWith("list")
      || table === "Rental" && !mode.startsWith("list")
    ) {
      return { consumed: url.slice(0, 3), posParams: { table: url[0], mode: url[1], id: url[2] } };
    }
    return null;
  };
  public ui: UIService;
  public zone: NgZone;
  public data: DataService;
  public router: Router;
  public rentalHelper;
  // public route: ActivatedRoute;
  public fq: FormsQuestionService;
  constructor(
    public injector: Injector
  ) {
    super();
    this.ui = injector.get(UIService);
    this.data = injector.get(DataService);
    this.zone = injector.get(NgZone);
    this.router = injector.get(Router);
    // this.route = injector.get(ActivatedRoute);
    this.fq = injector.get(FormsQuestionService);
    this.rentalHelper = injector.get(RentalListState);

    this.subs = new Subscription();

    const cols = this.customerInfoCols = this.ui.getCustomerInfoColumns();
    this.customerInfoDisplay = [
      {
        "Email": (row: unknown) => cols.Email.valText(row),
        "Name": (row: unknown) => cols.Name.valText(row),
        "Address": (row: unknown) => cols.Address.valText(row),
        "Phone": (row: unknown) => cols.Phone.valText(row),
        "Notes": (row: unknown) => cols.Notes.valText(row),
      },
      {
        "Customer Type": (row: unknown) => cols.CustomerType.valText(row),
        "Billing Day": (row: unknown) => cols.BillingDay.valText(row),
        "Late Fee Exempt": (row: unknown) => cols.LateFeeExempt.get(row) ? "Yes" : "No",
        "Tax Exempt": (row: unknown) => cols.TaxExempt.get(row) ? "Yes" : "No",
        "Storage Agreement": (row: unknown) => cols.StorageAgreementStatus.valText(row),
      },
    ] as const;

    this.customerStatusDisplay = [
      (row) => (cols.Email.get(row) && cols.EmailVerified.get(row))
        ? ["Email Verified", "success", "success"]
        : ["Email not Verified", "error", "critical"],
      (row) => cols.StorageAgreementCompleted.get(row)
        ? ["Storage Agreement Signed", "success", "success"]
        : ["Storage Agreement not Signed", "error", "critical"],
      (row) => cols.PaymentInfoValid.get(row)
        ? ["Payment Info Valid", "success", "success"]
        : ['Payment Info not Valid', "warn", "attention"],
    ];


  }

  onSaveSuccess = new EventEmitter<{}>();

  tabs?: any;

  pageTitle: string = "Editing Customer";
  showSave = false;
  onlypage = DISABLE_HYBRID_CUSTOMER_PAGE;
  otherContactsDialog?: Dialog<any, any, any>;

  balancePending: Promise<number | null> = Promise.resolve(null);
  balance: Promise<number | null> = Promise.resolve(null);

  customerInfoCols;
  customerInfoDisplay;
  customerStatusDisplay: ((row: unknown) => [string, 'success' | 'info' | 'warn' | 'error', Tone])[];
  // customerLedger?: QuestionGroup<any, { LedgerLines: QuestionTable<any> }>;
  // customerEmails?: QuestionGroup<any, { SentEmails: QuestionTable<any> }>;

  get CUSTOMER_IS_TESTING() {
    if (this.value && typeof this.value.IS_TESTING !== "boolean")
      console.error("CUSTOMER_IS_TESTING not defined", this.value);
    return !!this.value && this.value.IS_TESTING === true;
  }

  // get customerRentals() { return this.value && this.value["AllRentals"]; }

  async pageSetup_beforeLoad(keepPage: boolean, data: DataQueryGraph): Promise<boolean> {
    if (this.mode === "CREATE") {
      if (this.table === "Customer")
        alert("Please go to the customer list to create a new customer.");
      if (this.table === "Rental")
        alert("Please go to the customer page to create a rental for that customer.");
      return false;
    } else if (this.table === "Rental") {
      const rental = await this.data.singleDataQuery(this.data.proxy.rental.findUnique({
        where: { id: this.id },
        select: { customerID: true }
      }));
      if (rental?.customerID)
        this.fq.showEdit("Customer", rental.customerID, true);
      return false;
    } else if (!this.id) {
      return false;
    }

    if (!keepPage) {
      this.subforms = [{ label: "General" }, { label: "Ledger" }, { label: "Emails" }];
      this.subform = this.subforms[0];
    }



    requestAnimationFrame(() => this.tabs?.updateInkBar());

    // if (this.onlypage) {

    //   this.customerLedger = this.ui.schema.CustomerFormLedgerControls("UPDATE") as any;
    //   this.customerLedger?.onLoadHook(data);
    //   console.log(this.customerLedger);

    // }

    // this.customerEmails = this.ui.schema.CustomerFormEmails("UPDATE") as any;
    // this.customerEmails?.onLoadHook(data);
    // console.log(this.customerEmails);

    this.balance = data.addPromise(proxy.customerLedger.aggregate({
      _sum: { Amount: true },
      where: {
        customerID: this.id,
        line: {
          VoidSince: null,
          OR: [
            { paymentLine: { PaymentStatus: "Cleared" } },
            { paymentLine: { PaymentStatus: "Approved" } },
            { paymentLine: null },
          ],
        }
      }
    })).then(x => x._sum.Amount);



    const paths: string[] = [
      "id",
      ...Object.values(this.ui.getCustomerInfoColumns()).map(e => e.key),
      // ...Object.values(this.ui.getRentalColumns()).map(e => `AllRentals/${e.key}`),
      // ...this.customerLedger?.controls.LedgerLines?.cols.map(e => `LedgerLines/${e.key}`) ?? [],
      // ...this.customerLedger?.controls.LedgerLines?.extraGetPaths?.map(e => `LedgerLines/${e}`) ?? [],
      // ...this.customerEmails?.controls.SentEmails?.cols.map(e => `SentEmails/${e.key}`) ?? [],
      // ...this.customerEmails?.controls.SentEmails?.extraGetPaths?.map(e => `SentEmails/${e}`) ?? [],
    ];

    this.paths.push(...paths as any[]);
    return true;
  }
  async pageSetup_afterLoad(keepPage: boolean): Promise<boolean> {

    // if (this.table === "Customer" && this.customerLedger)
    //   this.customerLedger.controls.LedgerLines.form.setValue(this.fresh.LedgerLines);
    // if (this.table === "Customer" && this.customerEmails)
    //   this.customerEmails.controls.SentEmails.form.setValue(this.fresh.SentEmails);

    if (this.customerInfoDisplay[1]["Customer Type"](this.value) !== "Commercial")
      Object.defineProperty(this.customerInfoDisplay[0], "Billing Day", { enumerable: false });



    if (this.table === "Customer" && this.mode === "UPDATE") {
      if (this.data.userRole === "web_admin") {
        this.buttons.push({
          title: "Refresh Paid Lines",
          subform: "Ledger",
          onlyClean: true,
          onClick: async () => {
            okNull(this.id);
            await this.onClickRefreshPaidLines();
          },
        });

      }

      this.buttons.push({
        title: "Account Info",
        subform: "General",
        onlyClean: true,
        // icon: "pi pi-envelope",
        onClick: async () => {
          okNull(this.id);
          await this.onClickEditEmail();
        },
      });
      this.buttons.push({
        title: "Billing Info",
        subform: "General",
        onlyClean: true,
        // icon: "pi pi-pencil",
        onClick: async () => {
          okNull(this.id);
          await this.onClickEditCustomerBilling();
        },
      });
      this.buttons.push({
        title: "Other Contacts",
        subform: "General",
        onlyClean: true,
        // icon: "pi pi-pencil",
        onClick: async () => {
          okNull(this.id);
          await this.onClickEditOtherContacts();
        },
      });

      this.buttons.push({
        title: "Set Password",
        subform: "General",
        onlyClean: true,
        // icon: "pi pi-envelope",
        onClick: async () => {
          okNull(this.id);
          await this.onClickSetPassword();
        },
      });

      this.buttons.push({
        title: "Schedule Rental",
        subform: "General",
        onlyClean: true,
        // icon: "pi pi-calendar",
        onClick: async () => {
          okNull(this.id);
          await this.rentalHelper.onClickScheduleUnit(this.id, false);
        },
      });


      this.buttons.push({
        title: "Record Payment",
        onlyClean: true,
        subform: "Ledger",
        // icon: "pi pi-shopping-bag",
        onClick: async () => {
          okNull(this.id);
          await this.fq.onClickRecievePayment(this.id);
        },
      });
      const payValid = !!this.customerInfoCols.PaymentInfoValid.get(this.value);

      this.buttons.push({
        title: "Payment Info",
        onlyClean: true,
        subform: "Ledger",
        // icon: "pi pi-pencil",
        // severity: payValid ? undefined : "warning",
        onClick: async () => {
          okNull(this.id);
          await this.onClickPaymentDetails();
        },
      });
      if (payValid) {
        this.buttons.push({
          title: "Charge Customer",
          onlyClean: true,
          subform: "Ledger",
          severity: "danger",
          icon: "pi pi-credit-card",
          onClick: async () => {
            okNull(this.id);
            await this.onClickChargePayment();
          },
        });
      }


      if (!this.customerInfoCols.EmailVerified.get(this.value)) {
        this.buttons.push({
          title: "Resend Verification Email",
          onlyClean: true,
          subform: "General",
          severity: "danger",
          onClick: async () => {
            okNull(this.id);
            await this.data.server.serverSendVerificationEmail({ customerID: this.id, });
            this.fq.toast("success", "Email Sent", "Successfully sent a new confirmation email.");
          },
        });
      }

      if (this.customerInfoCols.EmailVerified.get(this.value) && !this.customerInfoCols.StorageAgreementCompleted.get(this.value)) {
        this.buttons.push({
          title: "Check Storage Agreement",
          onlyClean: true,
          // severity: "warning",
          subform: "General",
          onClick: async () => {
            okNull(this.id);
            await this.data.server.serverCheckStorageAgreement({ id: this.id });
            await this.pageSetupLoading(true);
          },
        });
      }

      if (this.data.userRole === "web_admin") {
        // this.buttons.push({
        //   title: "Open Customer Payment Portal",
        //   iconPos: "right",
        //   icon: "pi pi-external-link",
        //   subform: "Ledger",
        //   onClick: async () => {
        //     ok(this.id);
        //     const { link } = await this.data.server("serverCustomerPaymentLink")({ customerID: this.id, Email: this.value.Email });
        //     window.open(link, '_blank');
        //     // alert("The customer payment portal cannot be opened through this button at the moment. This should be temporary.");
        //   },
        // });
      }



    }
    return true;
  }

  table: "Customer" | "Rental";
  id: string;



  @ZoneGuard()
  private async handleClickEvent(e: ClickEvent) {
    if (e.action !== "add" && e.action !== "edit" && e.action !== "delete") {
      console.error(e);
    } else if (e.table === "CustomerOtherContacts" && e.action === "delete") {
      // this is going to be the other contacts form, which displays table rows
      await this.fq.deleteTableItem(e.table, e.id);
      // await this.dialog?.pageSetup(true);
    } else if (e.table === "Rental" && e.action === "edit") {
      this.fq.showEdit(e.table, e.id);
      // } else if (e.table === "InvoiceLine" && e.action === "add") {
      // } else if (e.table === "CustomerOtherContacts") {
      // if (e.action === "edit") {
      //   this.onClickEditOtherContactItem("UPDATE", e.id);
      // } else if (e.action === "add") {
      //   this.onClickEditOtherContactItem("CREATE");
      // }
      // } else if (e.table === "CustomerLedger" && e.action === "edit"
      //   || e.table === "Unit" && e.action === "edit") {
      //   this.fq.onClickEvent.emit(e);
    } else {
      console.error(e);
    }
  }


  // @ZoneGuard()
  // async onClickEditOtherContactItem(mode: Modes, id?: string) {
  //   const dialog = this.fq.createGroupDataDialog(
  //     "CustomerOtherContacts", mode,
  //     () => {
  //       const group = this.ui.schema.CustomerOtherContacts(mode);
  //       group.controls.customerID.hidden = true;
  //       group.controls.customerID.form.setValue(this.id);
  //       return group;
  //     }
  //   );
  //   dialog.onSaveSuccess.subscribe(async () => {
  //     dialog.subs.unsubscribe();
  //     this.otherContactsDialog?.pageSetup(true);
  //   });
  //   dialog.id = id ?? null;
  //   await dialog.pageSetup(true);

  // }

  @ZoneGuard()
  async onClickEditOtherContacts() {
    const dialog = this.fq.createGroupDataDialog("Customer", "UPDATE", () => this.ui.schema.CustomerFormOtherContacts("UPDATE"));
    dialog.id = this.id;
    dialog.modalSize = "large"
    dialog.showOkCancel = true;
    await dialog.pageSetup(false);
    dialog.onSaveSuccess.subscribe((e) => { this.onSaveSuccess.emit(e); dialog.onClose(); });
  }

  @ZoneGuard()
  async onClickRefreshPaidLines() {
    await this.data.server3("serverSyncPaymentLines")({ customerID: this.id });
    this.onSaveSuccess.emit({});
  }

  async onClickCheckStorageAgreement(id: string) {
    await this.data.server.serverCheckStorageAgreement({ id: this.id });
    this.onSaveSuccess.emit({});
  }

  @ZoneGuard()
  async onClickEditEmail() {
    ok(this.mode === "UPDATE");
    ok(this.table === "Customer");
    ok(this.id);
    ok(this.value);
    const { id } = this.value;
    const dialog = this.fq.createBasicGroupDialog("Customer", "UPDATE", "", () => {
      ok(this.id);
      const group = this.ui.schema.CustomerFormEditEmail("UPDATE");
      if (this.data.userRole === "web_admin") {
        // group.controls.Email.preventUpdate = true;
        group.controls.AWSID.hidden = false;
        group.controls.AWSID.preventUpdate = true;
        group.controls.StorageAgreementCompleted.preventUpdate = false;
        group.controls.StorageAgreementCompleted.subform = undefined;
      } else {
        group.controls.StorageAgreementCompleted.hidden = true;
        group.controls.AWSID.hidden = true;
      }
      (["Email", "AWSID", "IS_TESTING", "StorageAgreementCompleted", "id"] as const).forEach(e => {
        ok(this.value);
        if (this.value[e] === undefined) console.error(`${e} should not be undefined`);
        group.form.controls[e].setValue(this.value[e], { emitEvent: false });
      });

      return group;
    }, async function (value): Promise<void> {

      const { Email, IS_TESTING, AWSID, StorageAgreementCompleted } = value;
      ok(id);
      ok(Email);

      const emailUpdated = await this.data.server.serverEditCustomer({
        id,
        Email,
        IS_TESTING,
        ...this.data.userRole === "web_admin" ? {
          AWSID,
          StorageAgreementCompleted,
        } : {},
      });
      if (emailUpdated === null) {
        globalMessage.add({
          severity: 'info',
          summary: "Nothing Changed",
          detail: "The Email address does not appear to have changed. Nothing was updated.",
          life: 10000
        });
      } else if (emailUpdated === false) {
        globalMessage.add({
          severity: 'warn',
          summary: "Email Failed to Send",
          detail: "The email address was changed, but the verification email failed for an unknown reason. It has been logged."
        });
      } else if (emailUpdated === true) {
        globalMessage.add({
          severity: 'success',
          summary: "Email Updated",
          detail: "The email address was changed, and we sent an email to the new address."
        });
      }

      this.onSaveSuccess.emit({});

      this.subs.unsubscribe();

      return;
    });
    dialog.showDelete = false;
    await dialog.pageSetup(false);
    dialog.onSaveSuccess.subscribe((e) => { this.onSaveSuccess.emit(e); });
  }

  @ZoneGuard()
  async onClickEditCustomerBilling() {
    if (this.table !== "Customer")
      throw new Error("Not Customer table");
    if (!this.id)
      throw new Error("id not set");
    const dialog = this.fq.createGroupDataDialog(
      "Customer", "UPDATE", () => {
        const group = this.ui.schema.CustomerFormBillingInfo("UPDATE");
        group.controls.BillingDay.onlyfor = [];
        group.controls.CustomerType.onlyfor = [];
        group.controls.firstContactBranch.onlyfor = [];
        group.controls.HowTheyFoundUs.onlyfor = [];
        group.subs.add(group.form.valueChanges.subscribe((e) => {
          const hidden = e.CustomerType !== PrismaExtra.CustomerType.Commercial;
          group.controls.BillingDay.hidden = hidden;
        }));
        return group;
      }
    );
    dialog.onSaveSuccess.subscribe(async () => {
      await saveCustomerType();
      dialog.subs.unsubscribe();
      this.onSaveSuccess.emit({});
    });

    dialog.id = this.id;


    await dialog.pageSetup(false);
    okNull(dialog.group);

    const saveCustomerType = async () => {
      ok(this.id);
      ok(dialog.group);
      ok(dialog.hasFresh());
      const { id } = this;
      const CustomerType = dialog.group.controls.CustomerType.form.value as CustomerType | null;
      ok(CustomerType);
      const BillingDay = CustomerType === "Commercial" ? dialog.group.controls.BillingDay.form.value : undefined;
      const firstContactID = dialog.group.controls.firstContactBranch.form.value?.id ?? null;
      if (dialog.fresh.CustomerType !== CustomerType || BillingDay && dialog.fresh.BillingDay !== BillingDay || firstContactID !== dialog.fresh.firstContactBranch?.id) {
        await this.data.server3("serverEditCustomer")({
          id,
          CustomerType,
          BillingDay,
          firstContactID,
        }).catch(() => {
          alert("The billing info was saved, except for the CustomerType");
        });
      }
    };
  }

  async onClickVerifyEmail(customerID: string) {
    await this.fq.messageConfirmation("Send Verification Email", "Are you sure you want to send a verification email to this customer?", async () => {
      await this.data.server.serverSendVerificationEmail({ customerID });
      this.fq.toast("success", "Email Sent", "The verification email was sent successfully.");
    });
  }

  @ZoneGuard()
  async onClickViewCustomerEmails() {
    if (this.table !== "Customer")
      throw new Error("Not Customer table");
    if (!this.id)
      throw new Error("id not set");
    const dialog = this.fq.createGroupDataDialog(
      "Customer", "UPDATE", () => {
        const group = this.ui.schema.CustomerFormEmails("UPDATE");
        const table = group.controls.SentEmails as QuestionTable<any>;
        table.config = "no_header_or_card";
        return group;
      }
    );
    dialog.showOkCancel = false;
    dialog.cancelLabel = "Close";
    dialog.showDelete = false;
    dialog.modalSize = "large";
    dialog.id = this.id;
    await dialog.pageSetup(false);
    return dialog;
  }
  // paymentMode: "make-payment" | "payment-method" | '' = '';

  paymentModeChange: EventEmitter<"make-payment" | "update-payment" | ''> = new EventEmitter();

  @ZoneGuard()
  async onClickPaymentDetails() {
    this.paymentModeChange.emit("update-payment");
  }
  @ZoneGuard()
  async onClickChargePayment() {
    this.paymentModeChange.emit("make-payment");
  }
  @ZoneGuard()
  async onClosePayment() {
    this.paymentModeChange.emit("");
  }
  @ZoneGuard()
  async onClickSetPassword() {
    const { id, fq } = this;
    const dialog = this.fq.createBasicGroupDialog(
      "Customer", "UPDATE", id,
      () => {
        return new QuestionGroup({
          __typename: "Customer",
          controls: {
            "password": new QuestionSimple("InputText", {
              title: "Password",
              attr_validators: { minLength: 8, pattern: /([0-9].*[a-z])|([a-z].*[0-9])/ },
              helptext: "The password must be at least 8 long and have at least one number (0-9) and one lowercase letter (a-z)",
              required: true,
            }),
          }
        });
      },
      async function onSave() {
        if (!this.group?.controls.password.form.value)
          return;
        const password = this.group.controls.password.form.value;
        await fq.messageConfirmation("Set Password", "Are you sure you want to set this password?", async () => {
          await this.data.server3("serverCustomerPassword")({ customerID: id, password });
          this.onSaveSuccess.emit({});
          fq.toast("success", "Password Set", "The password was set successfully.");
        });
        return;
      });
    await dialog.pageSetup(true);

  }
}

