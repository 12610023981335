import { DataService } from "common";
import { ChartConfiguration, ChartData, ChartOptions } from "chart.js";
import { useAngular, useAsyncEffect } from './index';


const colorsBad = [
  "#f58d7a",
  "#f05438",
  "#ed3817",
  "#d02d10",
  "#af260e",
];
const colorsGood = [
  "#BBF2EC",
  "#8ED8D0",
  "#5DA69D",
  "#396661",
  // "#1D3330",
];
const labelsMap: Record<string, string> = {
  "30": "Past 30 days",
  "60": "Past 30-60 days",
  "90": "Past 60-90 days",
  "-30": "Next 30 days",
  "-60": "Next 30-60 days",
  "-90": "Next 60-90 days",
}

export function useRevenueChartsDataSource(curBranch: string, paid: boolean): { loading: boolean; result: { data: ChartData, options: ChartOptions } | undefined; } {
  const { get } = useAngular();
  const dataSrvc = get(DataService);

  return useAsyncEffect(async () => {
    return await RevenueChartsDataSource(dataSrvc, curBranch, paid);
  }, async () => { }, async () => { }, [curBranch, paid])
}


export async function RevenueChartsDataSource(dataSrvc: DataService, curBranch: string | undefined, paid: boolean) {

  if (!curBranch) return;

  const data: any = await dataSrvc.server3("clientBalanceQuery")({ id: curBranch, ledger: "Branch", lineType: "Invoice", paid });

  const chartdata: ChartConfiguration<"bar", number[], unknown>["data"] = paid ? mapPaidData(data) : mapUnpaidData(data);

  const options: ChartOptions = {
    plugins: {
      legend: {
        position: paid ? "top" : "right"
      }
    },
    ...paid ? {
      scales: {
        y: { beginAtZero: true }
      }
    } : {},
    responsive: true,
    maintainAspectRatio: false,
  };

  return { data: chartdata, options };
}



export function mapUnpaidData(actualdataInvoice: { sum: number; days_since: number; }[]) {
  const periods: Record<string, number> = {};
  actualdataInvoice.forEach(e => {
    e.days_since = +e.days_since;
    e.sum = +e.sum;
    const group = `${Math.ceil(Math.abs(e.days_since) / 30) * Math.sign(e.days_since) * 30 || 30}`;
    if (!periods[group]) periods[group] = 0;
    periods[group] += e.sum;
  });

  const keys = Object.keys(periods).sort((a, b) => +b - +a);

  const colors = keys.map(e => {
    const colorPos = ["30", "60", "90"].indexOf(e);
    const colorNeg = ["-30", "-60", "-90"].indexOf(e);
    switch (+e >= 0) {
      case true: return colorPos >= 0 ? colorsBad[colorPos] : colorsBad[colorsBad.length - 1];
      case false: return colorNeg >= 0 ? colorsGood[colorNeg] : colorsGood[colorsGood.length - 1];
      default: throw new Error("never");
    }
  });



  const labels = keys.map(e => labelsMap[e] || (Math.sign(+e) >= 0 ? `Before 90 days` : `After 90 days`));


  // const actualdata = actualdataInvoice.map((e, i) => ({ sum: e.sum - actualdataPayment[i].sum, periodof: e.periodof }));
  const data: ChartConfiguration<"bar", number[], unknown>["data"] = {
    labels,
    datasets: [{
      data: keys.map(k => +`${periods[k]}e-2`),
      backgroundColor: colors,
      label: "Invoice",
    }]
  };
  console.log(data);
  return data;
}


export function mapPaidData(actualdataInvoice: { sum: number; periodof: string; }[]) {
  const periods: Record<string, number> = {};
  actualdataInvoice.forEach(e => {
    e.sum = +e.sum;
    const group = e.periodof;
    if (!periods[group]) periods[group] = 0;
    periods[group] += e.sum;
  });

  const keys = Object.keys(periods).sort((a, b) => a.localeCompare(b));

  // const actualdata = actualdataInvoice.map((e, i) => ({ sum: e.sum - actualdataPayment[i].sum, periodof: e.periodof }));
  const data: ChartConfiguration<"bar", number[], unknown>["data"] = {
    labels: keys,
    datasets: [{
      data: keys.map(k => +`${periods[k]}e-2`),
      backgroundColor: "#54CAE0",
      label: "Invoice",
    }]
  };
  console.log(data);
  return data;
}
// // import { Header } from "@shopify/polaris/build/ts/src/components/Page/components";
// export class useRevenueChartsDataSource2 extends useAsyncEffectBase<{ data: any; options: any; } | undefined, void> {
//   cancelled: boolean;
//   constructor(
//     // private setChartData: Dispatch<SetStateAction<ChartProps>>,
//     // private setChartOptions: Dispatch<SetStateAction<ChartProps["options"]>>,
//     private data: DataService,
//     private curBranch: string,
//     private paid: boolean
//   ) {
//     super([data, curBranch, paid]);
//   }
//   // mount: () => Promise<any>;
//   // unmount: () => Promise<void>;
//   // cancel: () => Promise<void>;
//   async mount() {


//     if (!this.curBranch)
//       return;

//     const actualdataInvoice = await this.data.server("clientBalanceQuery")({
//       id: this.curBranch,
//       ledger: "Branch",
//       lineType: "Invoice",
//       paid: this.paid
//     }) as { sum: number; periodof: number; }[];
//     console.log(actualdataInvoice);
//     const periods: any = {};

//     actualdataInvoice.forEach((e) => { periods[e.periodof] = [0, 0]; });
//     actualdataInvoice.forEach(e => periods[e.periodof][0] = +`${e.sum}e-2`);

//     const keys = Object.keys(periods).sort((a, b) => a.localeCompare(b));
//     const colors = [
//       "#54CAE0",
//       "#E06853",
//       "#85E053",
//       "#AF53E0",
//     ];
//     const colors2 = [
//       "#BBF2EC",
//       "#8ED8D0",
//       "#5DA69D",
//       "#396661",
//       "#1D3330",
//     ];
//     // const actualdata = actualdataInvoice.map((e, i) => ({ sum: e.sum - actualdataPayment[i].sum, periodof: e.periodof }));
//     const data: ChartConfiguration<"bar", number[], unknown>["data"] = {
//       labels: keys,
//       datasets: ["Invoice" /* , "Payment" */].map((e, i) => ({
//         data: keys.map(k => periods[k][i]),
//         backgroundColor: this.paid ? "#54CAE0" : colors2,
//         label: e,
//       }))
//     };
//     console.log(data);

//     const options: ChartOptions = {
//       plugins: {
//         legend: {
//           position: this.paid ? "top" : "right"
//         }
//       },
//       ...this.paid ? {
//         scales: {
//           y: { beginAtZero: true }
//         }
//       } : {},
//       responsive: true,
//       maintainAspectRatio: false,
//     };

//     return { data, options };

//     // if (!this.cancelled) this.setChartData({ data, options });
//   }
//   async unmount() {
//   }
//   async cancel() {
//     this.cancelled = true;
//   }

// }
