var styles = {
  "TopBar": "Polaris-TopBar",
  "Container": "Polaris-TopBar__Container",
  "LogoDisplayControl": "Polaris-TopBar__LogoDisplayControl",
  "LogoDisplayContainer": "Polaris-TopBar__LogoDisplayContainer",
  "LogoContainer": "Polaris-TopBar__LogoContainer",
  "hasLogoSuffix": "Polaris-TopBar--hasLogoSuffix",
  "Logo": "Polaris-TopBar__Logo",
  "LogoLink": "Polaris-TopBar__LogoLink",
  "ContextControl": "Polaris-TopBar__ContextControl",
  "NavigationIcon": "Polaris-TopBar__NavigationIcon",
  "focused": "Polaris-TopBar--focused",
  "IconWrapper": "Polaris-TopBar__IconWrapper",
  "LeftContent": "Polaris-TopBar__LeftContent",
  "Search": "Polaris-TopBar__Search",
  "RightContent": "Polaris-TopBar__RightContent",
  "SecondaryMenu": "Polaris-TopBar__SecondaryMenu"
};

export { styles as default };
