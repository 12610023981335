import { BlockStack, Page } from "@shopify/polaris";
import { DataQueryGraph, DataService, PrismaExtra, SPPI, UIService } from "common";
import { format } from "date-fns";
import { useMemo } from "react";
import { useAngular, useAsyncEffect, useObservable } from "react-utils";
import { QuestionTableComp } from "../components/QuestionTableComp";
import { useCustomTable } from "./useCustomTable";

async function PromiseAllKeysAwaited<T extends Record<string, Promise<any>>>(proms: T): Promise<{ [K in keyof T]: Awaited<T[K]> }> {
  const entries = [...Object.entries(proms)].map(async ([k, v]) => ({ [k]: await v }));
  return (await Promise.all(entries)).reduce((n, e) => Object.assign(n, e), {} as any);
}


export function PageSystemLedger1() {
  const { get, injector } = useAngular();
  const ui = get(UIService);
  const data = get(DataService);


  const table = useMemo(() => ui.QuestionHasMany("CentralHoldingLine", undefined, undefined, {
    hidden: true,
    onlyfor: [],
    clientSideOnly: true,
    arrayList: ["Name", "Amount"] as SPPI[],
    subform: "Ledger",
    title: "System Holding Current Balance",
    preventUpdate: true,
    preventCreate: true,
    preventDelete: true,
  }), [ui]);

  useAsyncEffect(async function () {
    const query = new DataQueryGraph("CentralHoldingLine", "", "web_admin");
    /** Not void, not testing, invoice line paid and before today, or payment line approved or cleared, or adjustment. */
    function payoutBalanceWhereLine() {
      return ({
        VoidSince: null,
        IS_TESTING: false,
        OR: [
          // if an invoice line is paid, then the split has recieved its money, regardless of the date
          { invoiceLine: { paidOn: { not: null }, } },
          // an invoice line is not marked paid until the payment is cleared
          { paymentLine: { PaymentStatus: "Cleared", } },
          // no idea what these are. Probably adjustments. They should be included regardless.
          { paymentLine: null, invoiceLine: null, }
        ]
      }) satisfies PrismaExtra.Prisma.TransactionWhereInput;
    }
    const proms = ({
      BranchLedger: query.addPromise(data.proxy.branchLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        }
      })),
      OwnerLedger: query.addPromise(data.proxy.ownerLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      DivisionLedger: query.addPromise(data.proxy.divisionLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      CentralLedger: query.addPromise(data.proxy.centralLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      SalesTax: query.addPromise(data.proxy.salesTaxLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        }
      })),
      // Before february first, the payment fee was added on top of the amount the 
      // customer paid so it never entered the ledger and was handled by the customer.
      // After february first, the payment fee is deducted from the payout to branch and owner.
      // CustomerPaymentFee: query.addPromise(data.proxy.paymentLine.aggregate({
      //   _sum: { PaymentFee: true },
      //   where: {
      //     line: {
      //       ...payoutBalanceWhereLine(),
      //       customerLedgerLine: {},
      //       Date: { gte: "2024-02-01" },
      //     },
      //     PaymentStatus: { in: ["Cleared", "Approved"] },
      //   }
      // })),
      /** The owner and branch payment fee are set based on the transactions they apply to */
      OwnerPaymentFee: query.addPromise(data.proxy.invoiceLine.aggregate({
        _sum: { OwnerPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: {
            VoidSince: null,
            IS_TESTING: false,
          }
        }
      })),
      /** The owner and branch payment fee are set based on the transactions they apply to */
      BranchPaymentFee: query.addPromise(data.proxy.invoiceLine.aggregate({
        _sum: { BranchPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: {
            VoidSince: null,
            IS_TESTING: false,
          }
        }
      })),
    });

    await data.dataGraphQuery(query, "requests");

    const {
      BranchPaymentFee,
      SalesTax,
      BranchLedger,
      OwnerPaymentFee,
      OwnerLedger,
      DivisionLedger,
      CentralLedger,
    } = await PromiseAllKeysAwaited(proms);


    table.setState({
      action: "reset",
      newValue: [
        { Name: "Branch Balance", Amount: (BranchLedger._sum.Amount ?? 0) - (BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
        { Name: "Sales Tax", Amount: SalesTax._sum.Amount },
        { Name: "Owner Balance", Amount: (OwnerLedger._sum.Amount ?? 0) - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
        { Name: "Division Ledger", Amount: DivisionLedger._sum.Amount },
        { Name: "Central Ledger", Amount: CentralLedger._sum.Amount },
        // { Name: "Customer Payment Fee", Amount: -(CustomerPaymentFee._sum.PaymentFee ?? 0) },
        // { Name: "- Branch Ledger", Amount: BranchLedger._sum.Amount },
        // { Name: "- Branch Payment Fee", Amount: -(BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
        // { Name: "- Owner Ledger", Amount: OwnerLedger._sum.Amount ?? 0 },
        // { Name: "- Owner Payment Fee", Amount: -(OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
        {
          Name: "System Holding Balance",
          Amount: 0
            + (BranchLedger._sum.Amount ?? 0)
            + (SalesTax._sum.Amount ?? 0)
            + (OwnerLedger._sum.Amount ?? 0)
            + (DivisionLedger._sum.Amount ?? 0)
            + (CentralLedger._sum.Amount ?? 0)
            // the payment fee is deducted by the processor so it does not exist in the holding balance
            - (BranchPaymentFee._sum.BranchPaymentFee ?? 0)
            - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0)
        },
        {
          Name: "Payout Holding Balance",
          Amount: 0
            + (SalesTax._sum.Amount ?? 0)
            + (BranchLedger._sum.Amount ?? 0)
            - (BranchPaymentFee._sum.BranchPaymentFee ?? 0)
            + (OwnerLedger._sum.Amount ?? 0)
            - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0)
            + (DivisionLedger._sum.Amount ?? 0)
        }
      ]
    })
  });
  useObservable(table.form.valueChanges);
  return (
    <Page fullWidth>
      <BlockStack gap="300">
        {table.rows.length > 0 && <QuestionTableComp q={table} mode="UPDATE" />}
      </BlockStack>
    </Page>
  );

}



export function PageSystemLedger() {
  const { get, injector } = useAngular();
  const ui = get(UIService);
  const data = get(DataService);

  const tableMarkup = useCustomTable(() => [
    { key: "Name", title: "Name", calculate: e => e.Name },
    { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
  ], [], async () => {

    const query = new DataQueryGraph("CentralHoldingLine", "", "web_admin");

    function payoutBalanceWhereLine() {
      return ({
        VoidSince: null,
        IS_TESTING: false,
        OR: [
          { invoiceLine: { paidOn: { not: null }, } },
          { paymentLine: { PaymentStatus: { in: ["Cleared", "Approved"] }, } },
          // { paymentLine: null, invoiceLine: null, }
        ]
      }) satisfies PrismaExtra.Prisma.TransactionWhereInput;
    }

    const proms = ({
      BranchLedger: query.addPromise(data.proxy.branchLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        }
      })),
      OwnerLedger: query.addPromise(data.proxy.ownerLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      DivisionLedger: query.addPromise(data.proxy.divisionLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      CentralLedger: query.addPromise(data.proxy.centralLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      SalesTax: query.addPromise(data.proxy.salesTaxLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        }
      })),
      // Before february first, the payment fee was added on top of the amount the 
      // customer paid so it never entered the ledger and was handled by the customer.
      // After february first, the payment fee is deducted from the payout to branch and owner.
      // CustomerPaymentFee: query.addPromise(data.proxy.paymentLine.aggregate({
      //   _sum: { PaymentFee: true },
      //   where: {
      //     line: {
      //       ...payoutBalanceWhereLine(),
      //       customerLedgerLine: {},
      //       Date: { gte: "2024-02-01" },
      //     },
      //     PaymentStatus: { in: ["Cleared", "Approved"] },
      //   }
      // })),
      /** The owner and branch payment fee are set based on the transactions they apply to */
      OwnerPaymentFee: query.addPromise(data.proxy.invoiceLine.aggregate({
        _sum: { OwnerPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: {
            VoidSince: null,
            IS_TESTING: false,
          }
        }
      })),
      /** The owner and branch payment fee are set based on the transactions they apply to */
      BranchPaymentFee: query.addPromise(data.proxy.invoiceLine.aggregate({
        _sum: { BranchPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: {
            VoidSince: null,
            IS_TESTING: false,
          }
        }
      })),
    });

    await data.dataGraphQuery(query, "requests");

    const {
      BranchPaymentFee,
      SalesTax,
      BranchLedger,
      OwnerPaymentFee,
      OwnerLedger,
      DivisionLedger,
      CentralLedger,
    } = await PromiseAllKeysAwaited(proms);


    return [
      { Name: "Central Ledger", Amount: CentralLedger._sum.Amount },
      { Name: "Division Ledger", Amount: DivisionLedger._sum.Amount },
      { Name: "Sales Tax", Amount: SalesTax._sum.Amount },
      { Name: "Branch Balance", Amount: (BranchLedger._sum.Amount ?? 0) - (BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
      { Name: "Branch Payment Fee (subtracted)", Amount: (BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
      { Name: "Owner Balance", Amount: (OwnerLedger._sum.Amount ?? 0) - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
      { Name: "Owner Payment Fee (subtracted)", Amount: (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
      {
        Name: "System Holding Balance",
        Amount: 0
          + (BranchLedger._sum.Amount ?? 0)
          + (SalesTax._sum.Amount ?? 0)
          + (OwnerLedger._sum.Amount ?? 0)
          + (DivisionLedger._sum.Amount ?? 0)
          + (CentralLedger._sum.Amount ?? 0)
          // the payment fee is deducted by the processor so it does not exist in the holding balance
          - (BranchPaymentFee._sum.BranchPaymentFee ?? 0)
          - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0)
      },
      {
        Name: "Payout Holding Balance",
        Amount: 0
          + (BranchLedger._sum.Amount ?? 0)
          + (SalesTax._sum.Amount ?? 0)
          + (OwnerLedger._sum.Amount ?? 0)
          + (DivisionLedger._sum.Amount ?? 0)
          // + (CentralLedger._sum.Amount ?? 0)
          // the payment fee is deducted by the processor so it does not exist in the holding balance
          - (BranchPaymentFee._sum.BranchPaymentFee ?? 0)
          - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0)
      }
    ]
  }, [], {
    idKey: "Name",
    curTab: 0,
    setTab: undefined,
    showFilters: false,
    onSelectRow: async () => { },
    tabLabels: [],
  });

  return (
    <Page fullWidth>
      <BlockStack gap="300">
        {tableMarkup}
      </BlockStack>
    </Page>
  );

}
