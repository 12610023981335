import { BlockStack, Box, ButtonGroup, Card, Divider, FilterInterface, IndexFilters, IndexFiltersMode, InlineError, InlineStack, Spinner, Text } from "@shopify/polaris";
import { ConfirmType, DataQueryGraph, DataService, MemberKeys, TABLE_NAMES, TYPE_NAMES, UIService, schema, truthy } from "common";
import { ColumnBase, DataListColumn, EditTreeAccessor, FGCR, FormsQuestionService, GroupDataDialog, GroupDialog, LedgerTables, QuestionTable } from '../utils';
import React, { useCallback, useMemo, useState } from "react";
import { ButtonAwait, useAngular, useObservable } from "react-utils";
import { SelectTableProps, TableListInner, TableProps, SelectTable, useTable } from "../tables/TableListInner";
import { AppliedFilterProps, SetAppliedFiltersProps, useAppliedFilters } from "../tables/useFilters";
import { mapColumnsToFilters } from "../tables/useFilters";
import { plural, singular } from "pluralize";
import { PlusIcon } from "@shopify/polaris-icons";
import { emptyArray } from "./QuestionItem";

export function QuestionTableComp({ q, mode }: {
  q: QuestionTable<any>;
  mode: MemberKeys<ConfirmType>;
}) {

  const { get } = useAngular();
  const fq = get(FormsQuestionService);

  const [firstSort] = q.arraySort ?? [];
  const [curAppliedFilters, setAppliedFilters] = useAppliedFilters([]);

  useObservable(q.form.valueChanges, q.form.value);
  const loading = useObservable(q.loadingChange, q.loading);

  const headerMarkup = (<>
    <>
      <InlineStack gap="300" align="space-between">
        <Text as="h2" variant="headingMd">{q.title || q.rowType}</Text>
        <ButtonGroup>
          {q.showAdd && <ButtonAwait
            icon={PlusIcon}
            onClick={async () => { q.onCreate && q.onCreate(); }}
          >
            Add {q.rowType}
          </ButtonAwait>}
        </ButtonGroup>
      </InlineStack>
    </>

  </>);

  const { rows, cols, idcol, rowType } = q;

  const tableMarkup = <>
    {q.showFilter && <QuestionTableFilter
      cols={q.cols}
      curAppliedFilters={curAppliedFilters}
      setAppliedFilters={setAppliedFilters}
    />}
    {q.errortext && <Box padding="200"><InlineError fieldID={q.key} message={q.errortext} /></Box>}
    <SelectTable {...({
      rows,
      cols,
      idcol,
      onClickRow: q.showEdit ? (id) => { q.onSelect && q.onSelect(rows.find(e => idcol.get(e) === id)); } : undefined,
      showRowCheckbox: false,
      emptyState: q.emptySize === "small" ? "No rows found" : undefined,
      firstSort,
      curAppliedFilters,
      loading,
      resourceName: {
        singular: rowType,
        plural: plural(rowType)
      }
    })} />
  </>;
  switch (q.config) {


    case "no_card":
      return (
        <BlockStack gap="0">
          <Box padding="300">
            {headerMarkup}
          </Box>
          <Divider />
          {tableMarkup}
        </BlockStack>
      );
    case "no_header":
      return (
        <Card padding="0">
          <BlockStack gap="0">
            {tableMarkup}
          </BlockStack>
        </Card>
      )
    case "no_header_or_card":
      return (
        <BlockStack gap="0">
          {tableMarkup}
        </BlockStack>
      );
    case "header_above_card":
      return (
        <BlockStack gap="300">
          {headerMarkup}
          <Card padding="0">
            <BlockStack gap="0">
              {tableMarkup}
            </BlockStack>
          </Card>
        </BlockStack>
      );
    case "header_in_card":
    default:
      return (
        <Card padding="0">
          <BlockStack gap="0">
            <Box padding="300">
              {headerMarkup}
            </Box>
            <Divider />
            {tableMarkup}
          </BlockStack>
        </Card>
      )
  }

}

function QuestionTableFilter({ cols, curAppliedFilters, setAppliedFilters }: {
  cols: readonly ColumnBase[];
  curAppliedFilters: AppliedFilterProps[];
  setAppliedFilters: React.Dispatch<SetAppliedFiltersProps>;
}) {

  const filters: FilterInterface[] = [
    {
      key: "",
      label: "View",
      filter: null,
      pinned: true,
      hideClearButton: true,
      disabled: true,
    },
    ...cols
      .filter((e): e is DataListColumn => e.filterType !== "none" && e instanceof DataListColumn)
      .map(mapColumnsToFilters(curAppliedFilters, setAppliedFilters))
      .filter(truthy)
  ];

  const onClearAll = useCallback(() => {
    setAppliedFilters({ action: "clear" });
  }, [setAppliedFilters]);

  const [curMode, setMode] = useState(IndexFiltersMode.Default);

  const filterMarkup = <IndexFilters
    cancelAction={{ onAction: onClearAll }}
    filters={filters}
    appliedFilters={curAppliedFilters}
    onClearAll={onClearAll}
    mode={curMode}
    setMode={setMode}
    onQueryChange={() => { }}
    onQueryClear={() => { }}
    hideFilters={false}
    hideQueryField={true}
    tabs={emptyArray}
    selected={0}
    canCreateNewView={false}
    disableStickyMode
    disableKeyboardShortcuts />;

  return filterMarkup;
}
