import { EventEmitter } from '@angular/core';
import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn, Validators, ɵFormGroupRawValue, ɵRawValue, ɵTypedOrUntyped, ɵValue } from '@angular/forms';
import { Complete, FileUpload, getValueByPath, TYPE_NAMES, Anything, Attributes, forms, ValueTree, resolveDots, DataQueryGraph, objMap, Modes, okNull, Roles, isPrivScope, isPrivLevel, Prisma, PrismaQuery, ProxyPromise, ok, SPPI, dateCubes, arrayListKeys, ToSelectObject, SelectTypeTree, GenericPathProxy, Root, MemberKeys, proxy, TABLE_NAMES,  } from "common";
// import { MenuItem } from 'primeng/api';
import { asyncScheduler, distinctUntilChanged, EMPTY, from, map, merge, Observable, ObservableInput, observeOn, Subscription, switchMap, tap } from 'rxjs';
import { ColumnBase, DataListColumn } from './question-classes';
import { RawEditorOptions } from 'tinymce';
// import { InputMask } from 'primeng/inputmask';
// import { FileUploadChangeEvent, FileUploadInput, FileUploadResult, FileUploadSelectEvent } from './file-upload/file-upload.component';
import { format, formatISO } from 'date-fns';
import { makeTableRowRedux, TableRowDispatch, TableRowRedux } from "../tables/TableRowRedux";
import { RecordType, WHERE_balanceWhereLine, root } from 'common';
import { JSX } from 'react';
import { DefaultArgs, GetFindResult, GetResult } from '../../../../../../../server/datamath/prisma/client/runtime/library';
/** Basically uses implements to make sure it's got the correct fields */
interface ExtendedControlTypeClasses {
  Calendar: QuestionCalendar;
  Table: QuestionTable<any>;
  SubGroup: QuestionSubGroup<FGCR>;
  InputMask: QuestionInputMask;
  InputNumber: QuestionInputNumber;
  InputFormat: QuestionInputFormat;
  // ButtonRow: QuestionButtonRow;
  Select: QuestionSelect<any, any>;
  SelectLabel: QuestionSelectLabel;
  AutoComplete: QuestionAutoComplete<any, any>;
  FileUpload: QuestionFileUpload;
  PhotoGallery: QuestionPhotoUploadGallery;
  CheckboxGrid: QuestionCheckboxGrid<any>;
  TinyMCE: QuestionTinyMCE
  Render: QuestionRender
}

const cloneJSON = (value: any) => {
  const DBNULL = `DBNULL-${Date.now()}-${Math.random()}-DBNULL`;
  value = JSON.stringify(value, (k, v) => v === Prisma.DbNull ? DBNULL : v);
  value = JSON.parse(value, (k, v) => v === DBNULL ? Prisma.DbNull : v);
  return value;
}

type ExtendedControlType = keyof ExtendedControlTypeClasses;

export type SimpleControlType =
  | "CheckBox"
  | "RadioButton"
  | "Chips"
  | "InputText"
  | "InputGroup"
  | "InputLabel"
  | "InputLabelCheckBox"
  | "Textarea"
  | "Password"
  | "AutoCompleteAddressGoogle"
  | "Hidden"
  | "RawText"
  ;

export type DataListHeight = "flex" | `${number}rem` | "full" | undefined;

export type ControlType = SimpleControlType | ExtendedControlType;

/**
* Extract from T those types that are assignable to U and T
*/
type ExtractOnly<T, U extends T> = T extends U ? T : never;

type ExpandRecursively<T> =
  T extends Array<infer X> ? ExpandRecursively<X> :
  T extends object ? T extends infer O ? { [K in keyof O]: ExpandRecursively<O[K]> } : never :
  T;
// type t = ExpandRecursively<Attributes<"FIELD_DEFINITION">["field"]>;
type Attribute<D extends keyof Attributes<any>> = (Attributes<any>[D] & {})[number];

export type AttributeField<
  D extends keyof Attributes<"FIELD_DEFINITION">,
  F extends keyof (Attributes<"FIELD_DEFINITION">[D] & {})[number]> =
  (Attributes<"FIELD_DEFINITION">[D] & {})[number][F];

type Props<T extends {}> = { [P in keyof T]: T[P] extends Anything ? T[P] : never; }

export type QuestionIs = (type: ControlType) => boolean extends (type: infer X) => boolean ? (type: X) => boolean : never;

export interface QuestionOptions<T, TYPE extends ControlType> extends Attribute<"field"> {
  order?: number;
  title?: string;
  required?: boolean;
  allowAutocomplete?: boolean;
  fieldClass?: string;
  controlType?: TYPE;
  calculate?: () => any;
  /** not valid for standalone controls */
  onChange?: (val: any) => void;
  onLoadHook?: (tag: DataQueryGraph) => void;
  readonly?: boolean;
  __prismafield?: Record<string, string>;
  attr_field?: Attribute<"field">
  attr_validators?: Attribute<"validators">;
  /** 
   * A custom select path which will be assigned to the form value on load.
   * 
   */
  selectPath?: SPPI;
}

export abstract class QuestionBase<T, TYPE extends ControlType> implements Complete<QuestionOptions<T, TYPE>> {
  static order: number = 1;
  #key!: string;
  public get key(): string {
    if (!this.#key) throw new Error("get key() called before key is set");
    return this.#key;
  }
  public set key(value: string) {
    this.#key = value;
  }
  // #title!: string;
  // public get title(): string {
  //   return this.#title || this.key;
  // }
  // public set title(value: string) {
  //   this.#title = value;
  // }
  title: string | undefined;
  order: number = QuestionBase.order++;
  __prismafield: Record<string, string> | undefined;

  attr_field: Attribute<"field">
  attr_validators: Attribute<"validators">;
  hidden: AttributeField<"field", "hidden">;
  preventUpdate: AttributeField<"field", "preventUpdate">;
  preventCreate: AttributeField<"field", "preventCreate">;
  preventDelete: AttributeField<"field", "preventDelete">;
  onlyfor: AttributeField<"field", "onlyfor">;
  lefticon: AttributeField<"field", "lefticon">;
  righticon: AttributeField<"field", "righticon">;
  arrayList: AttributeField<"field", "arrayList">;
  arraySort: AttributeField<"field", "arraySort">;
  arrayWhere: AttributeField<"field", "arrayWhere">;
  helptext: AttributeField<"field", "helptext">;
  replicate: AttributeField<"field", "replicate">;
  filterType: AttributeField<"field", "filterType">;
  subform: AttributeField<"field", "subform">;
  unique: AttributeField<"field", "unique">;
  extraGetPaths: AttributeField<"field", "extraGetPaths">;
  clientSideOnly: AttributeField<"field", "clientSideOnly">;
  clientSideLoad: AttributeField<"field", "clientSideLoad">;
  clientSidePath: AttributeField<"field", "clientSidePath">;
  // allowedScope: AttributeField<"field", "allowedScope">;
  // allowedLevel: AttributeField<"field", "allowedLevel">;
  useDBNull: AttributeField<"field", "useDBNull">;
  inputType: AttributeField<"field", "inputType">;
  rlsRestrict: AttributeField<"field", "rlsRestrict">;

  selectPath: SPPI | undefined;

  errortext: string | undefined;

  allowAutocomplete: boolean = false;
  required: boolean = false;
  #readonly: boolean | undefined;
  public get readonly(): boolean | undefined {
    return this.#readonly
      || !!this.disabled
      || !!this.calculate
      || !!this.replicate
      || false;
  }
  public set readonly(value: boolean | undefined) {
    this.#readonly = value;
  }

  #parent?: QuestionGroupBase<any, any>;
  onLoadHook: ((tag: DataQueryGraph) => void) | undefined;
  get parent(): QuestionGroupBase<any, any> | undefined {
    return this.#parent;
  }
  set parent(v) {
    this.#parent?.subs.remove(this.subs);
    this.#parent = v;
    this.#parent?.subs.add(this.subs);
    this.parentChanges.emit(v);
  }
  parentChanges = new EventEmitter<QuestionGroupBase<any, any>>();
  subs = new Subscription();
  get disabled(): boolean { return this.form.disabled; }
  set disabled(v: boolean) { !v ? this.form.enable() : this.form.disable(); }

  hostClass: string = "p-fluid";

  fieldClass: string = "field col-12";
  validators: ValidatorFn[] = [];
  calculate: (() => any) | undefined;

  public abstract form: FormControl<T | null>;
  public abstract readonly controlType: TYPE;
  get flexmain() { return ""; }
  get _display_text() {
    return this.form.value?.toString() || "";
  }

  constructor(options: QuestionOptions<T, TYPE>) {
    Object.assign(this, options);
    this.ok("order");
    this.baseForm();

    if (this.clientSideLoad && !this.onLoadHook) {
      this.onLoadHook = async tag => {
        if (!this.clientSideLoad) return;
        const value = await tag.addPromise(this.handleClientSideLoadHook(tag));
        this.form.setValue(this.clientSidePath ? getValueByPath(value, this.clientSidePath.split("/")) : value);
      }
    }
  }

  handleClientSideLoadHook(tag: DataQueryGraph) {
    const req = this.clientSideLoad as ProxyPromise;
    ok(this.clientSideLoad);
    return JSON.parse(JSON.stringify(this.clientSideLoad), (key, val) => {
      if (val === `clientSideLoad_${tag.root}_id`)
        return tag.id;
      if (val === `clientSideLoad_field_arrayList`)
        // break typechecking here since we don't need it and this one seems to be expensive
        return (PrismaQuery.selectPathsProxy as any)(req.table, () => ["id", ...this.arrayList?.map(arrayListKeys) ?? []]);
      if (val === "clientSideLoad_balanceWhereLine")
        return WHERE_balanceWhereLine();
      if (val === "clientSideLoad_today")
        return format(Date.now(), dateCubes);
      if (typeof val === "string" && val.startsWith("clientSideLoad_"))
        debugger;
      return val;
    });
  }

  default: string | undefined;
  #onlymode_disabled: FormControl["disabled"] = true;
  /** Map the value going out through this.getValue */
  mapGetValue: (val: any) => any = e => e;
  getValue(mode: Modes, role: Roles | ""): any {
    if (this.onlyfor && this.onlyfor.indexOf(mode) === -1
      || this.preventUpdate && mode === "UPDATE"
      || this.preventCreate && mode === "CREATE"
    )
      return undefined;
    else if (this.rlsRestrict && role === "web_user")
      return undefined;
    else if (this.form.value === null && this.useDBNull)
      return Prisma.DbNull;
    else
      return this.mapGetValue(this.form.value);
  }
  onlymode(mode: Modes | "", role: Roles | "") {
    if (mode) {
      this.#onlymode_disabled = this.form.disabled;
      if (this.onlyfor && this.onlyfor.indexOf(mode) === -1
        || this.preventUpdate && mode === "UPDATE"
        || this.preventCreate && mode === "CREATE"
      )
        this.form.disable({ emitEvent: false });
    } else if (role) {
      this.#onlymode_disabled = this.form.disabled;
      if (this.rlsRestrict && role === "web_user")
        this.form.disable({ emitEvent: false });

    } else {
      if (!this.#onlymode_disabled) this.form.enable({ emitEvent: false });
    }

  }
  /**
   * Also check QuestionTable
   */
  baseForm(): void {
    if (this.form) throw new Error("form already set")
    this.validators.push(control => {
      const isEmpty = (control.value == null || control.value === "" || Number.isNaN(control.value));
      if (this.required && isEmpty) {
        if (this.rlsRestrict) return null;
        return { required: true };
      }
      return null;
    });
    Object.entries(this.attr_validators ?? {}).forEach(([k, v]) => {
      switch (k) {
        case "max":
        case "maxLength":
        case "min":
        case "minLength":
          this.validators.push(Validators[k](v as number)); break;
        case "pattern":
          this.validators.push(Validators[k](v as string | RegExp)); break;
        case "email":
          this.validators.push(Validators[k]);
      }

    })
    this.form = new FormControlQuestion(this, null, (control) => {
      return this.validators.reduce((n, e) => {
        const res = e(control);
        return res ? Object.assign(n ?? {} as any, res) : n;
      }, null);
    });
    if (this.default) this.form.setValue(JSON.parse<any>(JSON.stringify(this.default)));
    if (this.calculate) this.form.setValue(this.calculate());
    if (this.replicate) throw new Error("replicate not implemented");
  }
  /** recieves valueChanges from immediate parent group */
  onChange: ((val: any) => void) | undefined;
  is<TT extends ExtendedControlType>(controlType: TT): this is ExtendedControlTypeClasses[TT];
  is<TT extends SimpleControlType>(controlType: TT): this is QuestionBase<T, TT>;
  is(controlType: ControlType): boolean {
    return this.controlType === controlType;
  }

  ok<K extends (keyof this) & string>(key: K): asserts this is Record<K, NonNullable<this[K]>> {
    if (this[key] === null || this[key] === undefined) {
      throw new Error("Assertion failed: Options must include " + key);
    }
  }
}

// export class QuestionHidden extends QuestionBase

// "InputText" | "LeftIcon" | "RightIcon" | "AutoComplete" | "CheckBox" | "RadioButton" | "Calendar" | "Chips" | "InputNumber" | "InputGroup" | "Textarea" | "Password"

export type QuestionSimpleValueType<TYPE extends SimpleControlType> = TYPE extends never ? never
  : TYPE extends "InputLabelCheckBox" ? boolean
  : TYPE extends "CheckBox" ? boolean
  : TYPE extends "InputNumber" ? number
  : string;

export class QuestionSimple<TYPE extends SimpleControlType>
  extends QuestionBase<QuestionSimpleValueType<TYPE>, TYPE>
  implements Complete<QuestionOptions<QuestionSimpleValueType<TYPE>, TYPE>> {
  declare public form: FormControl<QuestionSimpleValueType<TYPE> | null>;
  constructor(public controlType: TYPE, opts: QuestionOptions<QuestionSimpleValueType<TYPE>, TYPE>) {
    super(opts);
    Object.assign(this, opts);
    if (this.controlType === "Hidden") this.hidden = true;
  }
}

// export interface QuestionHiddenTypedOptions<T, PATHS> extends QuestionOptions<T, "Hidden"> {
//   extraGetPaths: PATHS;
// }
function test() {
  type T = "Unit";
  const func = (x: SelectTypeTree<T>) => [
    x.id.__,
    x.Name.__,
    x.Unavailable.__,
    x.unitType.Name.__,
    x.currentBranch.DisplayName.__,
    x.currentRental.customer.id.__,
    x.currentRental.customer.Email.__,
    x.currentRental.customer.AutoPay.__,
    x.currentRental.customer.billing.Name.__,
    x.currentRental.customer.billing.Address.__,
    x.currentRental.customer.billing.Phone.__,
  ] as const;
  type R = ReturnType<typeof func>;
  type t1 = { select: ToSelectObject<R> };
  type t2 = GetResult<Prisma.TypeMap<DefaultArgs>["model"][T]["payload"], t1, 'findUnique'>;
  type t3 = GetFindResult<Prisma.TypeMap<DefaultArgs>["model"][T]["payload"], t1>
  // type c2 = { [K in keyof C as C[K] extends undefined ? never : K]: C[K] & {} };
  // type t3 = TypedGroupControlsTypes<c2>;
  type t4 = { [K in keyof (t1 & {} & t3)]: (t1 & {} & t3)[K] };
}
type TQG1<T extends TABLE_NAMES, R extends readonly any[]> = GetResult<Prisma.TypeMap<DefaultArgs>["model"][T]["payload"], { select: ToSelectObject<R> }, 'findUnique'>;
type TQG2<T extends TABLE_NAMES, C extends { [K in MemberKeys<typeof root.types[T]>]?: QuestionBase<any, any> }> = { [K in keyof C as C[K] extends undefined ? never : K]: C[K] & {} };
type TQG3<T extends TABLE_NAMES, C extends { [K in MemberKeys<typeof root.types[T]>]?: QuestionBase<any, any> }> = TypedGroupControlsTypes<TQG2<T, C>>;
type TQG4<
  T extends TABLE_NAMES,
  C extends { [K in MemberKeys<typeof root.types[T]>]?: QuestionBase<any, any> },
  R extends readonly any[]
> = { [K in keyof (TQG1<T, R> & {} & TQG3<T, C>)]: (TQG1<T, R> & {} & TQG3<T, C>)[K] };
export type TypedQuestionGroupType<
  T extends TABLE_NAMES,
  C extends { [K in MemberKeys<typeof root.types[T]>]?: QuestionBase<any, any> },
  R extends readonly any[]
> = QuestionGroup<T, TQG2<T, C>, TQG4<T, C, R>>;

export function TypedQuestionGroup<
  T extends TABLE_NAMES,
  C extends { [K in MemberKeys<typeof root.types[T]>]?: QuestionBase<any, any> },
  R extends readonly any[]
>(
  type: T,
  controls: C,
  extraGetPaths: (e: SelectTypeTree<T>) => R,
  options: Omit<QuestionGroupOptions<T, never>, "__typename" | "controls" | "extraGetPaths"> = {}
) {
  // proxy.unit.findUnique({ where: { id: "1" }, select: extraGetPaths(root.types[type]) });
  type c2 = { [K in keyof C as C[K] extends undefined ? never : K]: C[K] & {} };
  type t1 = GetResult<Prisma.TypeMap<DefaultArgs>["model"][T]["payload"], { select: ToSelectObject<R> }, 'findUnique'>;
  type t3 = TypedGroupControlsTypes<c2>;
  type t4 = { [K in keyof (t1 & {} & t3)]: (t1 & {} & t3)[K] };
  const res = new QuestionGroup<T, c2, t4>({
    ...options,
    __typename: type,
    controls: controls as c2,
    extraGetPaths: GenericPathProxy()(extraGetPaths),
  });

  return {
    error: "" as number extends R["length"] ? `selector must return a const array` : undefined,
    res: res as number extends R["length"] ? `selector must return a const array` : typeof res,
    controls: controls as c2,
    type1: {} as t1,
    type2: {} as TypedGroupControlsTypes<c2>,
  }

  // return res as (number extends R["length"] ? `selector must return a const array` : typeof res);
}
type TypedGroupControlsTypes<C extends FGCR> = {
  [K in keyof C]:
  C[K] extends QuestionSubGroup<infer FGCR> ? TypedGroupControlsTypes<FGCR> :
  C[K] extends QuestionTable<infer T> ? T[] :
  C[K] extends QuestionBase<infer T, infer TYPE> ? T :
  never;
}

export function asTypedQuestionGroup<
  G extends QuestionGroup<any, any>,
  // C extends { [K in MemberKeys<typeof root.types[T]>]?: QuestionBase<any, any> },
  R extends readonly any[],
  T extends TABLE_NAMES = G extends QuestionGroup<infer T, any> ? T : never
>(group: G, extraGetPaths: (e: SelectTypeTree<T>) => R) {
  // type c2 = { [K in keyof C as C[K] extends undefined ? never : K]: C[K] & {} };
  type t1 = GetResult<Prisma.TypeMap<DefaultArgs>["model"][T]["payload"], { select: ToSelectObject<R> }, 'findUnique'>;
  type t3 = TypedGroupControlsTypes<G["controls"]>;
  type t4 = { [K in keyof (t1 & {} & t3)]: (t1 & {} & t3)[K] };
  if (!group.extraGetPaths) group.extraGetPaths = [];
  group.extraGetPaths.push(...GenericPathProxy()(extraGetPaths));
  return group as any as QuestionGroup<T, G["controls"], t4>;
}

// export class QuestionHiddenTyped<VALUE>
//   extends QuestionBase<VALUE, "Hidden">
//   implements Complete<QuestionOptions<VALUE, "Hidden">> {
//   declare public form: FormControl<VALUE | null>;
//   public controlType = "Hidden" as const;

//   static selectPathsProxy<T extends TYPE_NAMES, R extends readonly any[]>(
//     type: T, a: (e: SelectTypeTree<typeof root.types[T], []>) => R
//   ): number extends R["length"] ? "selector must return a const array" : ToSelectObject<R> {
//     return GenericPathProxy()(a);
//   }
//   constructor(
//     typeName: TYPE_NAMES,
//     opts: QuestionOptions<VALUE, "Hidden">
//   ) {
//     super(opts);
//     Object.assign(this, opts);
//     this.hidden = true;
//     PrismaQuery.selectPathsProxy(this.extraGetPaths, () => []);
//   }
// }

export interface QuestionRenderOptions<T> extends QuestionOptions<string, "Render"> {
  render(this: QuestionRender<T>): React.JSX.Element;
}
export class QuestionRender<T = string>
  extends QuestionBase<T, "Render">
  implements Complete<QuestionRenderOptions<T>> {

  declare public form: FormControl<T>;
  public controlType: 'Render' = "Render";

  constructor(opts: QuestionRenderOptions<T>) {
    super(opts);
    Object.assign(this, opts);
    this.ok("render");

  }
  render: () => JSX.Element;


}


export interface QuestionCalendarOptions extends QuestionOptions<string, "Calendar"> {
  format?: string;
  showDate?: boolean;
  showTime?: boolean;
}
export class QuestionCalendar
  extends QuestionBase<string, "Calendar">
  implements Complete<QuestionCalendarOptions> {

  declare public form: FormControl<string | null>;
  public controlType: 'Calendar' = "Calendar";

  constructor(opts: QuestionCalendarOptions) {
    if (opts.default === "now")
      opts.default = opts.format
        ? format(new Date(), opts.format)
        : formatISO(new Date());
    super(opts);
    Object.assign(this, opts);

  }

  showDate: boolean | undefined;
  showTime: boolean | undefined;
  format: string | undefined;
}

export interface QuestionInputMaskOptions extends QuestionOptions<string, "InputMask"> {
  mask: string;
  autoClear?: boolean;
  slotChar?: string;
  unmask?: boolean;
  viewchild?: (e: any) => void;
}
export class QuestionInputMask
  extends QuestionBase<string, "InputMask">
  implements Complete<QuestionInputMaskOptions> {
  declare public form: FormControl<string | null>;
  // 
  public controlType: 'InputMask' = "InputMask";
  constructor(opts: QuestionInputMaskOptions) {
    super(opts);
    Object.assign(this, opts);
    this.ok("mask");
  }
  viewchild: ((e: any) => void) | undefined;
  mask!: string;
  autoClear: boolean = true;
  slotChar: string = "_";
  unmask: boolean = false;
}


export interface QuestionInputNumberOptions extends QuestionOptions<number, "InputNumber"> {
  inputMode: "currency" | "decimal" | "integer",
  // /** required if inputMode is currency */
  // currency?: "USD",
  minFractionDigits?: number;
  maxFractionDigits?: number;
  min?: number;
  max?: number;
  prefix?: string;
  suffix?: string;
}
export class QuestionInputNumber
  extends QuestionBase<number, "InputNumber">
  implements Complete<QuestionInputNumberOptions> {
  declare public form: FormControl<number | null>;
  public controlType: 'InputNumber' = "InputNumber";
  mask!: string;

  constructor(opts: QuestionInputNumberOptions) {
    super(opts);
    Object.assign(this, opts);
    this.ok("inputMode");
    // if (this.inputMode === 'currency') this.ok('currency');

    this.validators.push(control => {
      if (typeof this.min === "number") {
        if (control.value < this.min) return { min: true };
      }
      if (typeof this.max === "number") {
        if (control.value > this.max) return { max: true };
      }
      return null;
    });
  }

  prefix: string | undefined;
  suffix: string | undefined;
  minFractionDigits: number | undefined;
  maxFractionDigits: number | undefined;
  min: number | undefined;
  max: number | undefined;
  inputMode: "currency" | "decimal" | "integer";
  // currency: 'USD' | 'EUR' | 'CNY' | undefined;
  // forminner = new FormControl<number | null>(null);


}


export interface QuestionInputFormatOptions extends QuestionOptions<string, "InputFormat"> {
  format: (a: string) => string,
  parse: (a: string) => string,
}
export class QuestionInputFormat
  extends QuestionBase<string, "InputFormat">
  implements Complete<QuestionInputFormatOptions> {
  declare public form: FormControl<string | null>;
  // 
  public controlType: 'InputFormat' = "InputFormat";
  mask!: string;
  constructor(opts: QuestionInputFormatOptions) {
    super(opts);
    Object.assign(this, opts);
  }
  format: (a: string) => string;
  parse: (a: string) => string;

}


export interface QuestionCheckboxGridOptions<T extends Record<string, string[]>> extends QuestionOptions<T, "CheckboxGrid"> {
  /** Sets which axis to use as the first key. */
  mode: "row" | "column";
  rowLabels: { label: string, value: string }[];
  columnLabels: { label: string, value: string, selection: boolean | null; }[];
  allowSelectAllRows?: boolean;
  value?: Record<string, string[]>;
}

export class QuestionCheckboxGrid<T extends Record<string, string[]>>
  extends QuestionBase<T, "CheckboxGrid">
  implements Complete<QuestionCheckboxGridOptions<T>> {
  declare public form: FormControl<T | null>;
  public controlType: 'CheckboxGrid' = "CheckboxGrid";
  constructor(opts: QuestionCheckboxGridOptions<T>) {
    super(opts);
    Object.assign(this, opts);
    this.ok("mode");
    this.ok("rowLabels");
    this.ok("columnLabels");

    if (!this.value) this.value = this.form.value ?? {} as T;

    (this.byRows ? this.rowLabels : this.columnLabels).forEach(({ value }: any) => {
      if (!this.value[value]) (this.value as any)[value] = [] as any;
    });
  }
  // these are readonly because the value object is generated
  value!: T;
  allowSelectAllRows: boolean | undefined;
  get byRows() { return this.mode === "row"; }

  readonly mode!: "row" | "column";
  readonly rowLabels!: { label: string, value: string }[];
  readonly columnLabels!: { label: string, value: string, selection: boolean | null }[];

  onUpdate() {
    this.form.setValue(this.value);
  }
}

// export interface QuestionButtonRowButton {
//   label: string;
//   align: "left" | "center" | "right";
//   click: () => void;
// }
// export interface QuestionButtonRowOptions extends QuestionOptions<string, "ButtonRow"> {
//   buttonsLeft?: MenuItem[];
//   buttonsCenter?: MenuItem[];
//   buttonsRight?: MenuItem[];
// }
// export class QuestionButtonRow
//   extends QuestionBase<string, "ButtonRow">
//   implements Complete<QuestionButtonRowOptions>{
//   declare public form: FormControl<string | null>;
//   public controlType: 'ButtonRow' = "ButtonRow";
//   constructor(opts: QuestionButtonRowOptions) {
//     super(opts);
//     Object.assign(this, opts);
//   }
//   buttonsLeft: MenuItem[] | undefined;
//   buttonsCenter: MenuItem[] | undefined;
//   buttonsRight: MenuItem[] | undefined;

// }

export interface QuestionSelectLabelOptions extends QuestionOptions<string, "SelectLabel"> {
  optionLabels: { walk: DataListColumn["walk"], title: string }[];
  optionIcon?: string;
  placeholder?: string;
  value?: any;
  click?: () => void;
}
export class QuestionSelectLabel
  extends QuestionBase<string, "SelectLabel">
  implements Complete<QuestionSelectLabelOptions> {
  declare public form: FormControl<string | null>;
  public controlType: 'SelectLabel' = "SelectLabel";
  constructor(opts: QuestionSelectLabelOptions) {
    super(opts);
    Object.assign(this, opts);
  }
  optionLabels: ColumnBase[];
  optionIcon: string | undefined;
  placeholder: string | undefined;
  click: (() => void) | undefined;
  value: any;
}

export interface QuestionTinyMCEOptions extends QuestionOptions<{ html: string, text: string }, "TinyMCE"> {
  preset?: "Letter" | "Email";
}
export class QuestionTinyMCE
  extends QuestionBase<{ html: string, text: string }, "TinyMCE">
  implements Complete<QuestionTinyMCEOptions> {
  declare public form: FormControl<{ html: string, text: string } | null>;
  public controlType: 'TinyMCE' = "TinyMCE";

  constructor(opts: QuestionTinyMCEOptions) {
    super(opts);
    // set init first so the accessor works right
    switch (this.preset) {
      case "Email": this.init = TinyMCE_Email2; break;
      case "Letter": this.init = TinyMCE_Letter; break;
      default: this.init = null;
    }
    Object.assign(this, opts);

  }

  preset: "Letter" | "Email" | undefined;
  init: RawEditorOptions & { selector?: undefined; target?: undefined; } | null;
  tags: string[];
  updateTags(tags: string[]) {
    this.tags = tags;
    if (!this.init) return;
    this.init = {
      ...this.init,
      mergetags_list: tags.map(e => ({ title: e, value: e })),
      setup: (editor: any) => {
        console.log("setup", editor, this.form.value);
        // since writeValue initially sees an object, we have to set this manually
        editor.on("init", () => {
          if (this.form.value?.html) editor.setContent(this.form.value.html);
        })
      }
    }
  }

  updateEditor(editor: any) {

    // these are private properties so we have to use any
    editor.emitOnChange = (inner: any) => {
      if (editor.onChangeCallback) {
        const html = inner.getContent({ format: "html" });
        const text = inner.getContent({ format: "text" });
        editor.onChangeCallback({ html, text });
      }
    }

    editor.writeValue = function (value: any) {
      console.log(value);
      editor.constructor.prototype.writeValue.call(this, value?.html);
    }
  }
}

export interface QuestionSelectOptions<T, VK extends keyof T & string> extends QuestionOptions<T[VK], "Select">, Attribute<"select"> {
  // tree: Promise<ITreeAccessor<unknown, T> | false>;
  // selectDisplay?: "buttons" | "listbox" | "dropdown" | "checkbox" | "checkcircle";
  options: ObservableInput<T[] | false>;
  optionLabels: ColumnBase[];
  /** Leave empty to treat options as string[] */
  optionValue: VK;
  optionIcon?: string;
  optionDisabled?: keyof T & string;
  optionFiltered?: keyof T & string;
  /** not valid for buttons display */
  filter?: "contains" | "startsWith" | "endsWith" | "equals" | "notEquals" | "in" | "lt" | "lte" | "gt" | "gte";
  filterBy?: string;
  placeholder?: string;
  multiple?: boolean;
  showToggleAll?: boolean;
  /** Update this table and field with the selected optionValue on save. Must be handled by saving code. */
  remoteField?: [string, string];
  onRelation?: boolean;
}

export class QuestionSelect<T extends Record<string, any> & Record<VK, string>, VK extends keyof T & string>
  extends QuestionBase<T[VK], "Select">
  implements Complete<QuestionSelectOptions<T, VK>> {

  declare public form: FormControl<T[VK] | null>;
  public controlType: 'Select' = "Select";


  constructor(opts: QuestionSelectOptions<T, VK>) {
    super(opts);
    Object.assign(this, opts);

    this.ok("optionLabels");
    this.ok("optionValue");

    this.options = opts.options;

  }

  onRelation: boolean | undefined;
  forReadonly: { hostTable: string; relation: string; } | undefined;
  display: 'buttons' | 'listbox' | 'dropdown' | 'checkbox' | 'radiocircle' | undefined;
  remoteField: [string, string] | undefined;
  filter: 'endsWith' | 'startsWith' | 'contains' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' | undefined;
  filterBy: string | undefined;
  showToggleAll: boolean | undefined;
  #options: Observable<T[] | false> = EMPTY;
  get options(): Observable<T[] | false> { return this.#options }
  set options(v: ObservableInput<T[] | false>) {
    try { this.#options; } catch (e) { return; }
    this.#options = from(v).pipe(tap((e) => {
      if (Array.isArray(e))
        this.optionsOuter = [...e];
      else if (e === null)
        this.optionsOuter = [];
      else throw new Error("Unexpected value " + e)
    }));

  }

  get selectedOption() { return this.form.value ? this.optionsOuterTree[this.form.value] : undefined; }
  optionsOuterTree: Record<string, T> = {};
  #optionsOuter: T[] = []
  get optionsOuter(): T[] {
    return this.#optionsOuter;
  }
  set optionsOuter(v: T[]) {
    const { optionValue } = this;
    this.#optionsOuter = v;
    this.updateList();
    this.optionsOuterTree = v.reduce((n, e) => (e[optionValue] ? n[e[optionValue]] = e : true, n), {} as any);
  }
  optionsInner: T[] = [];
  optionLabels!: QuestionSelectOptions<T, VK>["optionLabels"];
  optionValue!: VK;
  optionIcon: string | undefined;

  optionDisabled: (keyof T & string) | undefined;
  optionFiltered: (keyof T & string) | undefined;
  placeholder: string | undefined;
  multiple: boolean | undefined;

  updateList() {

    if (!this.optionFiltered) {
      this.optionsInner = this.optionsOuter.slice();
    } else {
      const { optionFiltered, optionValue } = this;
      this.optionsInner = this.optionsOuter
        .filter(e => /* (this.form.value === e[optionValue]) || */ !e[optionFiltered]);
    }

    // console.debug("updateList", this.title, this.optionsInner.length, this.optionsOuter.length, this.form.value);
  }



}


type Fields<T> = { [P in keyof T]: T[P] extends Function ? never : T[P]; }

export interface QuestionAutoCompleteOptions<T, I extends Record<string, any>> extends QuestionOptions<T, "AutoComplete"> {
  mapIn: (val: T) => I | null;
  mapOut: (val: I) => T | null;
  mapInput: (value: string) => Promise<I[]>;
  optionLabel: string & keyof I;
  optionValue?: string & keyof I;
  forceSelection?: boolean;
}


export class QuestionAutoComplete<T, I extends Record<string, any>>
  extends QuestionBase<T, "AutoComplete">
  implements Complete<QuestionAutoCompleteOptions<T, I>> {

  declare public form: FormControl<T | null>;

  public controlType: 'AutoComplete' = "AutoComplete";


  constructor(opts: QuestionAutoCompleteOptions<T, I>) {
    super(opts);
    Object.assign(this, opts);
    this.ok("mapIn");
    this.ok("mapOut");
    this.ok("mapInput");
    this.ok("optionLabel");

    // this.form = this.baseForm(opts.readonly);
    // this.innerForm = new FormControlQuestion(this, { value: this.form.value, disabled: false });

    // this.form.valueChanges.subscribe(e => {
    //   // console.log("outer", this._value);
    //   if (this._value === e) return;
    //   this._value = e && this.mapIn(e);
    //   this.innerForm.setValue(this._value);
    // });

    // this.innerForm.valueChanges.subscribe(e => {
    //   // console.log("inner", this._value);
    //   if (this._value === e) return;
    //   this._value = e && this.mapOut(e);
    //   this.form.setValue(this._value);
    // });


  }
  mapIn!: (val: T) => I;
  mapOut!: (val: I) => T;
  mapInput!: (value: string) => Promise<I[]>;
  optionLabel!: string & keyof I;
  optionValue: string & keyof I | undefined;
  optionFiltered: string & keyof I | undefined;
  active = new EventEmitter();
  // subject = new EventEmitter<string>();
  // results$: Observable<I[]>;
  results: I[] = [];
  // innerForm: FormControl<I | null>;
  private _value: any;
  forceSelection: boolean | undefined;


  async search(query: string) {
    if (!query) return;
    this.active.emit(true);
    this.results = await this.mapInput(query);
    this.active.emit(false);
    return this.results;
  }

}


export interface FileUploadInput {
  title: string;
  modified: number;
  size: number;
  type: string;
}
export interface FileUploadResult extends FileUploadInput {
  file: File;
  success: boolean;
  message: string;
  body: string;
  key: string;
  url: string;
}
export interface FileUploadSelectEvent {
  /** browser event from input field */
  originalEvent: Event,
  /** files selected in the current event */
  files: FileList,
  /** current files selected to be uploaded */
  currentFiles: File[]
}

export interface FileUploadChangeEvent {
  type: "onSelect" | "onClear" | "onRemove",
  currentFiles: File[],
}


export interface FileUploadState {
  files: File[];
  names: string[];
  thumbs: string[];
}


export interface QuestionFileUploadOptions
  extends QuestionOptions<ValueTree<FileUpload>[], "FileUpload"> {
  /**
   * The HTML input[type=file] accept attribute. This is used by the browser. 
   * Comma separated list of: file_extension|audio/*|video/*|image/*|media_type
   */
  accept: string;
  selectMultiple?: boolean;
  registerUploads: (uploads: FileUploadInput[]) => Promise<{ key: string, url: string }[]>;
  completeUploads: (uploads: FileUploadResult[]) => Promise<void>;
  showGallery?: boolean;
  deleteFromGallery?: (row: ValueTree<FileUpload>) => Promise<boolean>;
  onStateChange?: (event: FileUploadState) => void;
  initState?: FileUploadState;
  // onSelect?: (file: File) => void;
  // onRemove?: (file: File) => void;
  // onClear?: (() => void) | undefined;
}

export class QuestionFileUpload
  extends QuestionBase<ValueTree<FileUpload>[], "FileUpload">
  implements Complete<QuestionFileUploadOptions> {
  declare public form: FormControl<ValueTree<FileUpload>[] | null>;

  public controlType: 'FileUpload' = "FileUpload";
  constructor(opts: QuestionFileUploadOptions) {
    super(opts);
    Object.assign(this, opts);
    this.ok("accept");
    this.ok("registerUploads");
    this.ok("completeUploads");
  }
  onStateChange: ((event: FileUploadState) => void) | undefined;
  // onSelect: ((event: FileUploadSelectEvent) => void) | undefined;
  // onRemove: ((event: { file: File }) => void) | undefined;
  // onClear: (() => void) | undefined;
  accept: string;
  selectMultiple: boolean | undefined;
  showGallery: boolean | undefined;
  deleteFromGallery: ((row: ValueTree<FileUpload>) => Promise<boolean>) | undefined;
  registerUploads: (uploads: FileUploadInput[]) => Promise<{ key: string; url: string; }[]>;
  completeUploads: (uploads: FileUploadResult[]) => Promise<void>;
  thumbs: Promise<(string | null)[]> | undefined;
  initState: FileUploadState | undefined;

  async uploadFiles(files: File[], names: string[]) {

    const uploads = files.map((e, i) => ({
      title: names[i] ? names[i] + e.name.slice(e.name.lastIndexOf(".")) : e.name,
      modified: e.lastModified,
      size: e.size,
      type: e.type,
    } satisfies ValueTree<FileUpload>));

    const urls = await this.registerUploads(uploads);

    const res = await urls.reduce((p, e, i) => p.then(async (n) => {

      const res = await fetch(e.url, { method: "PUT", body: files[i] });

      n.push({
        success: res.status === 200,
        message: res.statusText,
        body: await res.text(),
      });
      return n;
    }), Promise.resolve([] as { success: boolean; message: string; body: string; }[])).catch(e => {
      // if this rejects, the request never made it to the server, CORS failed, etc.
      // so we just cancel the entire thing and report everything as failed
      return urls.map(e => ({ success: false, message: "Fetch rejected", body: "" }));
    });

    if (urls.length !== res.length) {
      // um, no idea, but it's a problem if this happens
      console.log("lengths don't match", urls, res);
      alert("An error occurred: Lengths don't match");
      throw new Error("lengths don't match");
    }

    const result = res.map((e, i) => ({ ...urls[i], ...uploads[i], ...e, file: files[i] }));

    for (let i = res.length - 1; i >= 0; i--) if (res[i].success) files.splice(i, 1);

    await this.completeUploads(result);

  }

}
export interface QuestionPhotoGalleryOptions extends QuestionOptions<ValueTree<FileUpload>, "PhotoGallery"> {
  images?: {
    "alt": string,
    "title": string,
    "previewImageSrc": string,
    "thumbnailImageSrc": string,
  }[]

  responsiveOptions?: {
    breakpoint: string;
    numVisible: number;
  }[];

  visible?: boolean;

  // onSelect?: QuestionFileUploadOptions<"files">;

}



export class QuestionPhotoUploadGallery
  extends QuestionBase<any, "PhotoGallery">
  implements Complete<QuestionPhotoGalleryOptions> {
  declare public form: FormControl<{
    "alt": string,
    "title": string,
    "previewImageSrc": string,
    "thumbnailImageSrc": string,
  }[] | null>;

  public controlType: 'PhotoGallery' = "PhotoGallery";

  responsiveOptions: { breakpoint: string; numVisible: number; }[] = [];

  images: QuestionPhotoGalleryOptions["images"] = [];
  imagesChange: EventEmitter<this["images"]> = new EventEmitter();

  visible: boolean = false;
  visibleChange: EventEmitter<boolean> = new EventEmitter();

  index: number = 0;
  indexChange: EventEmitter<number> = new EventEmitter();

  // upload: QuestionFileUploadOptions<"files"> | undefined;
  uploadControl?: QuestionFileUpload;

  constructor(opts: QuestionPhotoGalleryOptions) {
    super(opts);
    Object.assign(this, opts);
    this.form.setValue([]);
    this.ok("images");
    this.required && this.form.valueChanges.subscribe(e => {
      if (!e) { this.form.setValue([]); return; }
    })
  }
  deleteButton() {
    if (!this.form.value) return;
    this.form.setValue(this.form.value.filter((e, i) => this.index !== i));
  }

}




export class QuestionSubGroup<C extends FGCR, T extends { [K: string]: any } = QuestionValues<C>>
  extends QuestionBase<T, "SubGroup"> {
  declare public form: FormControl<T | null>;

  constructor(group: QuestionGroup<any, C, T>, opts: QuestionOptions<T, "SubGroup">) {
    super(opts);
    Object.assign(this, opts);
    this.group = group;
    this.form = this.group.form;
  }

  public controlType: 'SubGroup' = "SubGroup";

  group!: QuestionGroup<any, C, T>;
  get groupRecord() {
    return this.group as unknown as QuestionGroup<any, Record<string, QuestionBase<any, any>>>;
  }

  override get flexmain() { return this.group.height === "flex" ? " flex-main" : "" }
  private _value: any;


  override getValue(mode: Modes, role: Roles): QuestionValues<C> | null | undefined {
    if (this.onlyfor && this.onlyfor.indexOf(mode) === -1
      || this.preventUpdate && mode === "UPDATE"
      || this.preventCreate && mode === "CREATE")
      return undefined;
    else if (this.rlsRestrict && role === "web_user")
      return undefined;
    else
      return this.group.getValue(mode, role);
  }

  override onlymode(mode: Modes | "", role: Roles | "") {
    super.onlymode(mode, role);
    this.group.onlymode(mode, role);
  }


  override onLoadHook: ((tag: DataQueryGraph) => void) = (tag) => {
    this.group.onLoadHook(tag);
  }


}

export interface QuestionTableOptions<T extends Record<string, any>> extends QuestionOptions<T[], "Table"> {
  // tree: EditTreeAccessor<T>;
  rowType: TYPE_NAMES;
  cols: readonly ColumnBase[];
  idcol: ColumnBase;
  size?: "min" | "small" | "normal" | "large";
  // height?: DataListHeight;
  // rowHeight?: `${number}rem`;
  showAdd?: boolean;
  showEdit?: boolean;
  // selectRowForEdit?: boolean;
  // showSort?: boolean;
  // showDelete?: boolean;
  // showResize?: boolean;
  // showSelection?: boolean;
  showFilter?: boolean;
  // showAddFooter?: boolean;
  // allowInlineEdits?: boolean;
  // collapsible?: boolean;
  // editmode: "inline" | "popup" | "event";
  showRowCheckbox?: boolean;

  onCreate?: (this: QuestionTable<T>) => void;
  onSelect?: (this: QuestionTable<T>, row: T) => void;
  // onDelete?: (this: QuestionTable<T>, row: T) => void;

  config?: "header_in_card" | "header_above_card" | "no_header" | "no_card" | "no_header_or_card";
  // editingRowKeys?: Record<string, boolean>;
}

export class QuestionTable<T extends Record<string, any>> extends QuestionBase<T[], "Table"> implements Complete<QuestionTableOptions<T>> {
  declare public form: FormControl<T[] | null>;

  public controlType: 'Table' = "Table";

  TableRowRedux: TableRowRedux;
  setState: TableRowDispatch;
  pendingChange;
  constructor(opts: QuestionTableOptions<T>) {
    super(opts);
    Object.assign(this, opts);

    this.pendingChange = false;
    const redux = makeTableRowRedux(true, this.cols, this.idcol);
    this.TableRowRedux = redux.TableRowRedux;
    this.rows = redux.initialState.rows;
    this.value = redux.initialState.value;
    this.setState = (action) => {
      const { value, rows } = this.TableRowRedux(this, action);
      if (this.rows === rows && this.value === value) return;
      this.rows = rows;
      this.value = value;
      try {
        this.pendingChange = true;
        this.form.setValue(this.value ?? null);
      } finally {
        this.pendingChange = false;
      }
    }

    if (this.clientSideLoad) {
      this.onLoadHook = async tag => {
        if (!this.clientSideLoad) return;
        const value = await tag.addPromise(this.handleClientSideLoadHook(tag));
        const newValue = this.clientSidePath
          ? getValueByPath(value, this.clientSidePath.split("/"))
          : value;
        this.setState({ action: "reset", newValue });
      }
    } else {
      this.subs.add(this.form.valueChanges.subscribe(newValue => {
        if (this.pendingChange) return;
        this.setState({ action: "reset", newValue });
      }));
    }

    this.ok("cols");
    // this.ok("editmode");

  }




  cols: readonly ColumnBase[];
  idcol: ColumnBase;
  // tableForm: FormControl<any[]>;
  groupHook = new EventEmitter<{ group: QuestionGroup<any, any>, row: T[] }>();
  /** this replaces tree.value */
  rows: T[];
  value: T[] | null | undefined;
  rowType: TYPE_NAMES;
  // showAddFooter: boolean | undefined;
  // selectRowForEdit: boolean = false;
  // editmode!: 'inline' | 'popup' | 'event';

  showAdd: boolean = false;
  showEdit: boolean = false;
  showDelete: boolean = false;
  // showSort: boolean = false;
  // showResize: boolean = false;
  // showSelection: boolean = false;
  showFilter: boolean = false;
  showRowCheckbox: boolean = false;
  // showHeader: boolean = true;
  // allowInlineEdits: boolean = false;
  // rowHeight: `${number}rem` | undefined;
  // noCard: boolean | undefined;
  config: "header_in_card" | "header_above_card" | "no_header" | "no_card" | "no_header_or_card" | undefined;
  size: 'min' | 'small' | 'normal' | 'large' | undefined = 'normal';
  emptySize: "small" | "large" | undefined;

  private _loading: boolean = false;
  public get loading(): boolean {
    return this._loading;
  }
  public set loading(value: boolean) {
    if (this._loading === value) return;
    this._loading = value;
    this.loadingChange.emit(value);
  }
  loadingChange: EventEmitter<boolean> = new EventEmitter();

  get sizeclass() {
    switch (this.size) {
      case "min": return " p-datatable-min"
      case "small": return " p-datatable-sm";
      case "normal": return "";
      case "large": return " p-datatable-lg";
      default: return "";
    }
  }
  collapsible: boolean | undefined;
  height: DataListHeight;

  // onDelete: ((this: QuestionTable<T>, row: T) => void) | undefined;
  onSelect: ((this: QuestionTable<T>, row: T) => void) | undefined;
  onCreate: ((this: QuestionTable<T>) => void) | undefined;

}


// export type QuestionGroupValue<T extends readonly QuestionGroup<any, any>[]> = {
//     [K in keyof T & number]: FormGroup<QuestionGroupType<T[K]["controls"]>>
// }
// export type QuestionGroupForm<T extends readonly QuestionGroup<any, any>[]> = {
//     [K in keyof T & number]: FormGroup<QuestionGroupType<T[K]["controls"]>>
// }

export type GroupType = "group" | "gallery" | "galleryupload";
export interface GroupSchema<C, TYPE extends GroupType> {
  groupType: TYPE,
  title: string,
  controls: C,
}

// type QGTI<T extends FGC> = {
//     [K in (keyof T & FGCKey)]: {
//         [L in T[K]["key"]]: T[K]["value"];
//     };
// }[keyof T & FGCKey];

export type QuestionValues<C extends FGCR> = {
  [K in keyof C]: C[K]["form"]["value"];
};

export type QuestionForm<C extends FGCR> = {
  [K in keyof C]: C[K]["form"]
}
export type FGC<KK extends FGCKey> = { [K in KK]: QuestionBase<any, any> }
/** FGCR is not recursive because we use QuestionSubGroup, which is an FGCV */
export type FGCR = { [K: string]: FGCV };
export type FGCV = QuestionBase<any, any>;
export type FGCKey = string;
type RemoveSPPI<T> = {
  [K in keyof T]:
  (T[K] & {}) extends SPPI[] ? string[] :
  (T[K] & {}) extends SPPI ? string :
  T[K];
}

export interface QuestionGroupButton {
  title: string;
  onClick: () => void | Promise<void>;
  onlyClean?: boolean;
  subform?: string;
  icon?: string;
  // iconPos?: 'left' | 'right';
  // color?: string;
  // background?: string;
  severity?: "danger" | "success"; //| "secondary" | "success" | "info" | "warning" | "help" | ;
  // variant?: "plain" | "primary" | "secondary" | "tertiary" | "monochromePlain";
}

export interface QuestionGroupOptions<K extends TYPE_NAMES, C extends FGCR> {
  controls: C,
  collapsible?: boolean;
  forms?: RemoveSPPI<ValueTree<forms<any>>>;
  height?: DataListHeight,
  buttons?: QuestionGroupButton[],
  noHiddenAnimation?: boolean;
  extraGetPaths?: SPPI[];
  __typename: K | TYPE_NAMES,
  __prismaheader?: string[]
}

function resolveDotsVerifyRoot(k: string, e: string, ...rest: string[]): string {
  const f = resolveDots(k, e, ...rest);
  ok(!f.startsWith("../"));
  return f;
}

export class QuestionGroupBase<K extends TYPE_NAMES, C extends FGCR> implements Complete<QuestionGroupOptions<K, C>> {
  /**
   * 
   * @param path 
   * @param partial 
   * @returns [The QuestionBase object, the number of found parts]
   */
  getPath(path: string[], partial = false): [QuestionBase<any, any>, number] {
    let group: QuestionGroupBase<any, any> | undefined = this;
    for (let i = 0; i < path.length; i++) {
      const e = path[i];
      if (!group)
        throw new Error("Cannot navigate full path " + path.join("/"))
      else if (e === "..") {
        if (group.parent) group = group.parent.parent;
        else throw new Error("parent does not exist");
      } else if (e === ".")
        true;
      else if (group.controls[e] instanceof QuestionSubGroup)
        group = group.controls[e].group;
      else if (group.controls[e] instanceof QuestionTable)
        throw new Error("Cannot descend into a table");
      else if (group.controls[e] && (partial || i === path.length - 1))
        return [group.controls[e], i + 1];
      else
        throw new Error(`Cannot descend into ${e}`);
    }
    throw new Error("Should not happen");
  }
  getPathValue(path: string[]) {
    let [item, index] = this.getPath(path, true);
    return getValueByPath(item.form.value, path.slice(index));
  }

  findGetPaths(dir: string, role: Roles): SPPI[] {
    let paths = [] as string[];

    if (this.extraGetPaths) paths.push(...this.extraGetPaths.map(f => resolveDots(dir, f)));

    Object.values(this.controls).forEach(e => {
      if (e.clientSideOnly)
        return;
      if (e.rlsRestrict && role === "web_user")
        return;
      if (e.replicate)
        paths.push(resolveDots(dir, e.replicate));
      if (e.extraGetPaths)
        paths.push(...e.extraGetPaths.map(f => resolveDots(dir, e.key, f)));
      if (e.selectPath)
        paths.push(e.selectPath);
      if (e.calculate || e.replicate) {

      } else if (e.is("Select") && e.arrayList && e.onRelation) {
        paths.push(...["id", ...e.arrayList.map(arrayListKeys)].map(f => resolveDots(dir, e.key, f)))
      } else if (e.is("SubGroup")) {
        paths.push(...e.group.findGetPaths(e.key, role).map(f => resolveDots(dir, f)))
      } else if (e.is("Table")) {
        paths.push(...e.cols.map(f => resolveDots(dir, e.key, f.key)));
      } else {
        paths.push(resolveDots(dir, e.key));
      }
    });
    // console.log(paths);
    return paths as SPPI[];
  }

  findSelectWhere(select: any, role: Roles) {
    // const paths: string[] = [];
    // if (!base.select) base.select = {};
    // if (!base.where) base.where = {};

    if (this.extraGetPaths) this.selectPaths(select, null, this.extraGetPaths);

    Object.values(this.controls).forEach(e => {
      if (e.clientSideOnly) return;
      if (e.rlsRestrict && role === "web_user") return;
      if (e.replicate) {
        this.selectPaths(select, null, [resolveDotsVerifyRoot(e.key, e.replicate)] as SPPI[]);
      }
      if (e.extraGetPaths) {
        this.selectPaths(select, null, e.extraGetPaths.map(f => resolveDotsVerifyRoot(e.key, f)) as SPPI[]);
      }
      if (e.calculate || e.replicate) {

      } else if (e.is("Select") && e.arrayList && e.onRelation) {
        select[e.key] = {
          select: this.selectPaths({}, e.key, ["id", ...e.arrayList.map(arrayListKeys)].map(f => resolveDotsVerifyRoot("", f)) as SPPI[]),
          where: e.arrayWhere,
        } as any;
      } else if (e.is("SubGroup")) {
        select[e.key] = {
          select: e.group.findSelectWhere({}, role),
          where: e.arrayWhere,
        }
      } else if (e.is("Table")) {
        select[e.key] = {
          select: this.selectPaths({}, e.key, e.cols.map(f => resolveDotsVerifyRoot("", f.key)) as SPPI[]),
          where: e.arrayWhere,
        };
      } else {
        // this.selectPaths(select, e.key, [e.key as SPPI]);
        this.selectKey(select, e.key);
      }
    });

    return select;

  }
  selectPaths(base: any, field: string | null, paths: SPPI[]) {
    let type: RecordType;
    if (field) {
      let type2 = root.types[this.__typename].__member(field);
      if (type2.__wrapped()) type2 = type2.__wrap;
      if (!type2.__is("record") && !type2.__is("table")) throw new Error("Not a record type");
      type = type2;
    } else {
      type = root.types[this.__typename];
    }
    return PrismaQuery.getQueryTree(paths, type, false, base);
  }
  selectKey(select: any, key: string) {
    return PrismaQuery.getQueryTree([key as SPPI], root.types[this.__typename], false, select);
  }



  buildErrorTree(acc: any, item: AbstractControl, val?: any) {
    if (item.valid) return;
    if (item instanceof FormGroup) {
      Object.entries(item.controls).forEach(([k, item]) => {
        if (item.valid) return;
        if (item instanceof FormGroup) {
          acc[k] = {};
          this.buildErrorTree(acc[k], item);
        } else if (item instanceof FormControl) {
          acc[k] = val ?? item.errors;
        } else {
          throw new Error("Unhandled AbstratControl: " + item.constructor.name);
        }
      })
    }
  }
  getValue(mode: Modes, role: Roles): QuestionValues<C> {

    return objMap(this.controls, (child) => child.getValue(mode, role));
  }

  constructor(
    opts: QuestionGroupOptions<K, C>
  ) {
    Object.assign(this, opts);
    this.ok("controls");
    this.ok("__typename");
    if (!this.buttons) this.buttons = [];
    this.initControls();
  }

  subs = new Subscription();

  public controls!: C
  __typename: any extends K ? TYPE_NAMES : K;

  __prismaheader: string[] | undefined;
  buttons: QuestionGroupButton[];
  height: DataListHeight;
  forms: RemoveSPPI<Attribute<"forms">> | undefined;
  collapsible: boolean | undefined;
  borderless: boolean | undefined;
  noHiddenAnimation: boolean | undefined;
  extraGetPaths: SPPI[] | undefined;
  #styleClass: string = "";
  get styleClass() {
    return `${this.flexmain ?? ""} ${this.borderless ? "borderless p-0" : ""} ${this.#styleClass ?? ""}`
  }
  set styleClass(v: string) { this.#styleClass = v; }
  hostClass: string = "";
  parent?: QuestionBase<any, any>;
  get flexmain() {
    return (this.height === "flex") ? "flex-main" : "";
  }
  onlymode(mode: Modes | "", role: Roles | "") {
    Object.values<QuestionBase<any, any>>(this.controls).forEach((e) => { e.onlymode(mode, role); });
  }
  #onLoadHookCalled = false;
  onLoadHook(tag: DataQueryGraph) {
    if (this.#onLoadHookCalled) throw new Error("onLoadHook already called");
    this.#onLoadHookCalled = true;
    Object.values<QuestionBase<any, any>>(this.controls).forEach((e) => {
      e.onLoadHook && e.onLoadHook(tag);
    });
  }

  ok<K extends (keyof this) & string>(key: K): asserts this is Record<K, NonNullable<this[K]>> {
    if (this[key] === null || this[key] === undefined) {
      throw new Error("Assertion failed: Options must include " + key);
    }
  }

  initControls() {
    Object.entries(this.controls).forEach(([name, control]) => {
      okNull(control);
      control.key = name;
      control.parent = this;
    });
  }

  /** 
   * Add the control to this group. Calls form.addControl on the group form 
   * with emitEvent: false, then sets control.parent on the control. 
   * This should be the same as adding the control in the constructor options
   */
  addControl(name: string, control: QuestionBase<any, any>) {
    ok(control);
    ok(!this.controls[name]);
    (this.controls as FGCR)[name] = control;
    if (this instanceof QuestionGroup)
      this.form.addControl(name, control.form, { emitEvent: false });
    control.parent = this;
    control.key = name;
  }
}

export class QuestionGroup<K extends TYPE_NAMES, C extends FGCR, T extends { [K: string]: any } = QuestionValues<C>>
  extends QuestionGroupBase<K, C>
  implements Complete<QuestionGroupOptions<K, C>> {

  form: FormGroupQuestion<T>;

  constructor(
    opts: QuestionGroupOptions<K, C>
  ) {
    super(opts);
    Object.assign(this, opts);
    this.form = this.initFormGroup();
  }



  initFormGroup() {
    const group = Object.entries(this.controls).reduce((group, [name, control]) => {
      okNull(control);
      group[name] = control.form;
      return group;
    }, {} as any);
    const form = new FormGroupQuestion<T>(group);
    form.host = this;
    form.valueChanges.subscribe(val =>
      Object.values(this.controls as FGCR).forEach(child =>
        child.onChange && child.onChange(val)))

    return form;

  }



  getValueAndValidity(mode: Modes, role: Roles) {

    // we use the angular form disabled feature to handle the onlymode parameter
    this.onlymode(mode, role);
    this.form.updateValueAndValidity();
    const value = this.getValue(mode, role);
    const formValue = this.form.value;
    if (!this.form.valid) {
      const error = {} as any;
      this.buildErrorTree(error, this.form);
      console.log(error);
      console.log(value);
      console.log(this);
      debugger;
      this.onlymode("", "");

      const recursiveCount = (error: any, controls: FGCR, keypath: string): { total: number, keys: string[] } => {
        const keys = Object.keys(error).filter(e => error[e] && !controls[e].hidden);
        return keys.reduce((n, e) => {
          const field = controls[e];
          const keypath2 = `${keypath}/${e}`;
          n.keys.push(keypath2);
          if (field.is("SubGroup")) {
            return recursiveCount(error[e], field.group.controls, keypath2);
          } else {
            n.total++;
            return n;
          }
        }, { total: 0, keys: [] as string[] });
      }
      const { keys, total } = recursiveCount(error, this.controls, "");
      return {
        error: {
          severity: "error",
          summary: "Form has errors",
          data: error,
          total,
          keys,
        },
        value: null
      };

    }

    this.onlymode("", "");

    if (!value) return { value: null };

    // console.debug(value);


    return {
      value: cloneJSON(value) as Record<string & keyof C, any>,
      formValue: cloneJSON(formValue) as Record<string & keyof C, any>
    };
  }

}

class FormControlQuestion<T> extends FormControl {


  constructor(public host: QuestionBase<T, any>, ...args: ConstructorParameters<typeof FormControl>) {
    super(...args);

  }
}


class FormGroupQuestion<T extends { [K: string]: any }>
  extends FormGroup<{ [K in keyof T]: AbstractControl<T[K]> }>
  implements FormControl<T> {

  host!: QuestionGroup<any, any>;

  declare value: T;
  declare valueChanges: Observable<T>;
  defaultValue: T;

  constructor(
    controls: { [K in keyof T]: AbstractControl<T[K]> },
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    this.defaultValue = this.value;
  }

  registerOnChange(fn: Function): void {
    this.valueChanges.subscribe(e => fn());
  }
  registerOnDisabledChange(fn: (isDisabled: boolean) => void): void {
    this.statusChanges.subscribe(e => fn(e === "DISABLED"));
  }

  override reset(value: T, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    return super.reset(value, options);
  }
  override setValue(value: T, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    return super.setValue(value, options);
  }
  override patchValue(value: T, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    return super.patchValue(value, options);
  }
  override getRawValue(): T {
    return super.getRawValue() as any;
  }

}

const TinyMCE_Default_Email: RawEditorOptions = {

  // Specify which element(s) to make editable - these are all of our editable
  // areas within the email
  selector: '.tinymce',

  // Tip - To make TinyMCE leaner, only include the plugins you actually need
  plugins: 'advcode a11ychecker autocorrect autolink editimage emoticons image inlinecss link linkchecker lists mergetags powerpaste tinymcespellchecker',

  // This option allows you to specify the buttons and the order that they
  // will appear on TinyMCE’s toolbar.
  // https://www.tiny.cloud/docs/tinymce/6/toolbar-configuration-options/#basic-toolbar-options
  toolbar: 'undo redo | styles | bold italic forecolor backcolor | link image emoticons mergetags | align bullist numlist | spellcheckdialog a11ycheck | code removeformat',

  // Toolbar_mode controls how the toolbar behaves when toolbar buttons do not
  // fit on one row. Wrap displays all toolbar buttons wrapped over multiple rows
  // https://www.tiny.cloud/docs/tinymce/6/toolbar-configuration-options/#toolbar_mode
  toolbar_mode: "wrap",

  // Render the inline toolbar into an element at the top of the email editor
  // https://www.tiny.cloud/docs/configure/editor-appearance/#fixed_toolbar_container
  fixed_toolbar_container: '.toolbar',

  // Toggle the menubar off to get a leaner visual experience
  // https://www.tiny.cloud/docs/tinymce/6/menus-configuration-options/
  menubar: false,

  // Enable inline mode
  // https://www.tiny.cloud/docs/tinymce/6/use-tinymce-inline/
  inline: true,

  // In emails we don't use targets for links so we hide the
  // target drop down in the link dialog
  // https://www.tiny.cloud/docs/tinymce/6/link/#link_target_list
  link_target_list: false,

  // A common feature for email marketing tools is to provide a prepopulated
  // list of links to choose. Here we define that list.
  // https://www.tiny.cloud/docs/tinymce/6/link/#link_list
  link_list: [
    { title: "Features", value: 'https://www.tiny.cloud/tinymce/features/' },
    { title: "Docs", value: 'https://www.tiny.cloud/pricing/' },
    { title: "Pricing", value: 'https://www.tiny.cloud/docs/tinymce/6/' }
  ],

  // We don't want users to be able to resize images by using
  // drag and drop because it can break layout templates.
  // https://www.tiny.cloud/docs/tinymce/6/content-behavior-options/#object_resizing
  object_resizing: false,

  // The formats option is where custom formatting options are defined.
  // In this case we define a couple of headings and a button appearance
  // for links. HTML Emails require inlining the CSS. Fortunately the
  // styles property makes that easy.
  // https://www.tiny.cloud/docs/tinymce/6/content-formatting/
  formats: {
    h1: { block: 'h1', styles: { fontSize: '24px', color: '#335dff' } },
    h2: { block: 'h2', styles: { fontSize: '18px' } },
    calltoaction: { selector: 'a', styles: { backgroundColor: '#335dff', padding: '12px 16px', color: '#ffffff', borderRadius: '4px', textDecoration: 'none', display: 'inline-block' } }
  },

  // The style_formats option controls the styleformat toolbar button menu
  // https://www.tiny.cloud/docs/tinymce/6/user-formatting-options/#style_formats
  style_formats: [
    { title: 'Paragraph', format: 'p' },
    { title: 'Heading 1', format: 'h1' },
    { title: 'Heading 2', format: 'h2' },
    { title: 'Button styles' },
    { title: 'Call-to-action', format: 'calltoaction' },
  ],

  // An inline editor is "invisible" when there are no content in the editor
  // Make sure to use the placeholder option to show the user where to write
  // https://www.tiny.cloud/docs/tinymce/6/editor-important-options/#placeholder
  placeholder: "Write here...",

  // Only allow certain image types to be added to emails
  // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#images_file_types
  images_file_types: "jpeg,jpg,png,gif",

  // Because our email builder contains two "half-column" editors, we need to make
  // sure the Enhanced Image Editing toolbar will fit within those editable areas
  // Reduce the number of buttons to ensure the toolbar fits nicely
  // https://www.tiny.cloud/docs/tinymce/6/editimage/#editimage_toolbar
  editimage_toolbar: "editimage imageoptions",

  // Merge Tags lets users add non-editable personalization tokens to your content, so your
  // app can then merge the personalized content into emails before sending
  // https://www.tiny.cloud/docs/tinymce/6/mergetags/
  mergetags_list: [
    {
      title: "Contact",
      menu: [{
        value: 'Contact.FirstName',
        title: 'Contact First Name'
      },
      {
        value: 'Contact.LastName',
        title: 'Contact Last Name'
      },
      {
        value: 'Contact.Email',
        title: 'Contact Email'
      }
      ]
    },
    {
      title: "Sender",
      menu: [{
        value: 'Sender.FirstName',
        title: 'Sender First Name'
      },
      {
        value: 'Sender.LastName',
        title: 'Sender Last name'
      },
      {
        value: 'Sender.Email',
        title: 'Sender Email'
      }
      ]
    },
    {
      title: 'Subscription',
      menu: [{
        value: 'Subscription.UnsubscribeLink',
        title: 'Unsubscribe Link'
      },
      {
        value: 'Subscription.Preferences',
        title: 'Subscription Preferences'
      }
      ]
    }
  ],
}


const TinyMCE_Default_CRM = {
  selector: '#editor',
  plugins: 'advcode autocorrect autoresize editimage emoticons image inlinecss link linkchecker lists mergetags powerpaste template tinymcespellchecker',
  menubar: false,
  statusbar: false,
  min_height: 300,
  max_height: 500,
  autoresize_bottom_margin: 20,
  toolbar: 'undo redo spellchecker | formatgroup | link emoticons image template mergetags | code',
  toolbar_groups: {
    formatgroup: {
      icon: 'format',
      tooltip: 'Formatting',
      items: 'blocks fontfamily fontsize | bold italic underline strikethrough forecolor | align bullist numlist outdent indent blockquote'
    }
  },
  toolbar_location: 'bottom',
  advcode_inline: true,
  font_size_formats: "8px 10px 12px 14px 18px 24px 36px",
  formats: {
    h1: { block: 'h1' },
    h2: { block: 'h2' },
    p: [
      { block: 'p' },
      { selector: 'p' }
    ],
    small: { block: 'small', styles: { fontSize: '12px', color: '#aaaaaa' } }
  },
  block_formats: 'Normal=p; Heading=h1; Sub heading=h2; Small=small',
  forced_root_block: 'p',
  forced_root_block_attrs: { 'style': 'font-size: 14px; font-family: helvetica, arial, sans-serif;' },
  images_file_types: "jpeg,jpg,png,gif",
  spellchecker_active: false,
  powerpaste_word_import: 'clean',
  powerpaste_googledocs_import: 'clean',
  powerpaste_html_import: 'clean',
  link_target_list: false,
  link_list: [
    { title: "Product demo", value: "https://www.tiny.cloud/" },
    { title: "Pricing", value: "https://www.tiny.cloud/pricing/" },
    { title: "Sign up", value: "https://www.tiny.cloud/signup/" },
    {
      title: "Case studies",
      value: "https://www.tiny.cloud/solutions/content-authoring-tool/",
      menu: [
        { title: "Thomson Reuters", value: "https://assets.ctfassets.net/sn6g75ou164i/529dmerPtej8eu8wxRFSIQ/9eeeacaf7c7f45b43db991a7064c354d/tiny-case-study-thomson-reuters.pdf" },
        { title: "Morning Brew", value: "https://assets.ctfassets.net/sn6g75ou164i/5Y5ETFsqsbxrn8KrfxxuSn/eba8bdd4be3b378b167bc9e9016f9206/tiny-case-study-morning-brew_1_.pdf" },
        { title: "Accelo", value: "https://assets.ctfassets.net/sn6g75ou164i/4Zg8kQr3vcRpwWCcjwuwTy/0363c30c76032d69d25b68a6a625126b/tiny-case-study-accelo.pdf" }
      ]
    }
  ],
  mergetags_list: [
    {
      title: "Lead",
      menu: [{
        value: 'Lead.FirstName',
        title: 'Lead First Name'
      },
      {
        value: 'Lead.LastName',
        title: 'Lead Last Name'
      },
      {
        value: 'Lead.Organization',
        title: 'Lead Organization'
      },
      {
        value: 'Lead.Email',
        title: 'Lead Email'
      }
      ]
    },
    {
      title: "Sender",
      menu: [{
        value: 'Sender.FirstName',
        title: 'Sender First Name'
      },
      {
        value: 'Sender.LastName',
        title: 'Sender Last Name'
      },
      {
        value: 'Sender.Organization',
        title: 'Sender Organization'
      },
      {
        value: 'Sender.Email',
        title: 'Sender Email'
      }
      ]
    },
    {
      title: 'Subscription',
      menu: [{
        value: 'Subscription.UnsubscribeLink',
        title: 'Unsubscribe Link'
      },
      {
        value: 'Subscription.Preferences',
        title: 'Subscription Preferences'
      }
      ]
    }
  ],
  templates: [
    {
      title: 'Outbound email',
      description: 'Outbound cold email for prospects',
      content: '<p style="font-size: 14px; font-family: helvetica, arial, sans-serif;">Hi {{Lead.FirstName}},</p><p style="font-size: 14px; font-family: helvetica, arial, sans-serif;">My name is {{Sender.FirstName}} with {{Sender.Organization}}.</p><p style="font-size: 14px; font-family: helvetica, arial, sans-serif;">We help companies just like yours securely store data in the cloud. I wanted to learn how you handle data storage at {{Lead.Organization}} and show you some of the exciting technology we&quot;re working on.</p><p style="font-size: 14px; font-family: helvetica, arial, sans-serif;">Are you available for a quick call tomorrow afternoon?</p><p style="font-size: 14px; font-family: helvetica, arial, sans-serif;">{{Sender.FirstName}}</p>'
    },
    {
      title: 'Follow-up email',
      description: 'Follow-up to be sent immediately after discovery meetings',
      content: '<p style="font-size: 14px; font-family: helvetica, arial, sans-serif;">Hi {{Lead.FirstName}},</p><p style="font-size: 14px; font-family: helvetica, arial, sans-serif;">Thank you for taking the time to explore a potential partnership today! It felt like our product could help you solve some of the issues that you&rsquo;re having within {{Lead.Organization}}, especially in these areas:</p><ul><li style="font-size: 14px; font-family: helvetica, arial, sans-serif;">The offsite data warehouse will allow you to guarantee business continuity</li><li style="font-size: 14px; font-family: helvetica, arial, sans-serif;">Metered usage will ensure you only pay for what you consume</li><li style="font-size: 14px; font-family: helvetica, arial, sans-serif;">Level III security protocols will mean you will meet security requirements for your jurisdiction</li></ul><p style="font-size: 14px; font-family: helvetica, arial, sans-serif;">I understand that now you will discuss and agree internally on the next step. Please let me know if you have any questions or if there is anything I can do to help. If not, I&rsquo;ll talk to you next week.</p><p style="font-size: 14px; font-family: helvetica, arial, sans-serif;">Best,<br>{{Sender.FirstName}}</p>'
    }
  ],
  content_style: `
    body {
      font-family: -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 1.5rem;
    }

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px;
    }
  `
};

const TinyMCE_Default_Document = {
  selector: "#editor",
  plugins: "advcode advlist advtable anchor autocorrect autolink autosave casechange charmap checklist codesample directionality editimage emoticons export footnotes formatpainter help image insertdatetime link linkchecker lists media mediaembed mergetags nonbreaking pagebreak permanentpen powerpaste searchreplace table tableofcontents tinycomments tinymcespellchecker typography visualblocks visualchars wordcount",
  toolbar: "undo redo spellcheckdialog  | blocks fontfamily fontsizeinput | bold italic underline forecolor backcolor | link image addcomment showcomments  | align lineheight checklist bullist numlist | indent outdent | removeformat typography",
  height: '700px',
  toolbar_sticky: true,
  autosave_restore_when_empty: true,
  spellchecker_active: true,
  spellchecker_language: 'en_US',
  spellchecker_languages: 'English (United States)=en_US,English (United Kingdom)=en_GB,Danish=da,French=fr,German=de,Italian=it,Polish=pl,Spanish=es,Swedish=sv',
  typography_langs: ['en-US'],
  typography_default_lang: 'en-US',
  tinycomments_mode: 'embedded',
  tinycomments_author: 'rmartel',
  tinycomments_author_name: 'Rosalina Martel',
  tinycomments_author_avatar: 'https://www.tiny.cloud/images/avatars/avatar-RosalinaMartel.jpg',
  sidebar_show: 'showcomments',
  mergetags_list: [
    {
      value: 'Document.Title',
      title: 'Document Title'
    },
    {
      value: 'Publish.Date',
      title: 'Publish Date'
    },
    {
      value: 'Author.Name',
      title: 'Author Name'
    }
  ],
  content_style: `
    body {
      background: #fff;
    }

    @media (min-width: 840px) {
      html {
        background: #eceef4;
        min-height: 100%;
        padding: 0.5rem;
      }

      body {
        background-color: #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, .15);
        box-sizing: border-box;
        margin: 1rem auto 0;
        max-width: 820px;
        min-height: calc(100vh - 1rem);
        padding: 4rem 6rem 6rem 6rem;
      }
    }
  `,
};

const TinyMCE_Default_CMS = {
  selector: 'textarea',
  plugins: 'a11ychecker advcode advlist advtable anchor autocorrect autosave editimage image link linkchecker lists media mediaembed pageembed powerpaste searchreplace table template tinymcespellchecker typography visualblocks wordcount',
  toolbar: 'undo redo | styles | bold italic underline strikethrough | align | table link image media pageembed | bullist numlist outdent indent | spellcheckdialog a11ycheck typography code',
  height: 540,
  a11ychecker_level: 'aaa',
  typography_langs: ['en-US'],
  typography_default_lang: 'en-US',
  advcode_inline: true,
  style_formats: [
    { title: 'Heading 1', block: 'h1' },
    { title: 'Heading 2', block: 'h2' },
    { title: 'Paragraph', block: 'p' },
    { title: 'Blockquote', block: 'blockquote' },
    { title: 'Image formats' },
    { title: 'Medium', selector: 'img', classes: 'medium' },
  ],
  object_resizing: false,
  valid_classes: {
    'img': 'medium',
    'div': 'related-content'
  },
  image_caption: true,
  noneditable_class: 'related-content',
  templates: [
    {
      title: 'Related content',
      description: 'This template inserts a related content block',
      content: '<div class="related-content"><h3>Related content</h3><p><strong>{$rel_lede}</strong> {$rel_body}</p></div>'
    }
  ],
  template_replace_values: {
    rel_lede: 'Lorem ipsum',
    rel_body: 'dolor sit amet...',
  },
  template_preview_replace_values: {
    rel_lede: 'Lorem ipsum',
    rel_body: 'dolor sit amet...',
  },
  content_style: `
    body {
      font-family: 'Roboto', sans-serif;
      color: #222;
    }
    img {
      height: auto;
      margin: auto;
      padding: 10px;
      display: block;
    }
    img.medium {
      max-width: 25%;
    }
    a {
      color: #116B59;
    }
    .related-content {
      padding: 0 10px;
      margin: 0 0 15px 15px;
      background: #eee;
      width: 200px;
      float: right;
    }
  `
};

const TinyMCE_Default_LMS = {
  selector: "#editor",
  plugins: "a11ychecker advcode autocorrect autolink autoresize autosave charmap checklist code editimage emoticons footnotes fullscreen image link linkchecker lists media mediaembed mergetags powerpaste preview table tableofcontents tinycomments tinymcespellchecker typography wordcount",
  toolbar: "undo redo | blocks | bold italic underline strikethrough forecolor backcolor | align checklist bullist numlist | link image media footnotes mergetags table | subscript superscript charmap blockquote | tokens | spellchecker typography a11ycheck wordcount | addcomment showcomments | fullscreen preview",
  statusbar: false,
  toolbar_sticky: true,
  mediaembed_max_width: 800,
  block_formats: 'Title=h1; Heading=h2; Sub heading=h3; Blockquote=blockquote; Paragraph=p',
  font_css: ['https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,550;1,400&display=swap'], // URLs containing commas and such have to be wrapped in an array to work
  a11y_advanced_options: true,
  a11ychecker_html_version: 'html5',
  a11ychecker_level: 'aa',
  typography_default_lang: 'en-US',
  mergetags_list: [
    {
      title: "Course",
      menu: [{
        value: 'Course.Name',
        title: 'Course Name'
      },
      {
        value: 'Course.Teacher.Name',
        title: 'Teacher Name'
      },
      {
        value: 'Course.Department.Head',
        title: 'Department Head'
      }
      ]
    },
    {
      title: "Assignment",
      menu: [{
        value: 'Assignment.Name',
        title: 'Assignment Name'
      },
      {
        value: 'Assignment.DueDate',
        title: 'Assignment Due Date'
      }
      ]
    },
    {
      title: "Student",
      menu: [{
        value: 'Student.Name',
        title: 'Student Name'
      },
      {
        value: 'Student.ID',
        title: 'Student ID'
      },
      {
        value: 'Student.Email',
        title: 'Student Email'
      }
      ]
    }
  ],
  tinycomments_mode: 'embedded',
  tinycomments_author: 'rmartel',
  tinycomments_author_name: 'Rosalina Martel (Instructor)',
  tinycomments_author_avatar: 'https://www.tiny.cloud/images/avatars/avatar-RosalinaMartel.jpg',
  sidebar_show: 'showcomments',
  content_style: `
    body {
      max-width: 800px;
      margin: auto;
      font-family: 'Asap', serif;
      font-size: 17px;
      color: #222f3e;
    }

    h1, h2, h3, strong {
      font-weight: 550;
    }

    table th,
    table thead td {
      background-color: #ecf0f1;
      font-weight: 550;
      text-align: left;
    }

    table caption {
      display: none;
    }

    table[data-mce-selected="1"] caption {
      display: table-caption;
    }

    .mce-footnotes {
      font-size:12px;
    }
  `
};

const TinyMCE_Default_SAAS = {
  selector: 'textarea',
  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
  tinycomments_mode: 'embedded',
  tinycomments_author: 'Author name',
  mergetags_list: [
    { value: 'First.Name', title: 'First Name' },
    { value: 'Email', title: 'Email' },
  ]
};

const TinyMCE_Email: RawEditorOptions = {
  selector: '.tinymce',
  plugins: 'advcode a11ychecker autocorrect autolink editimage emoticons image inlinecss link linkchecker lists mergetags powerpaste tinymcespellchecker',
  toolbar: 'undo redo | styles | bold italic forecolor backcolor | link image emoticons mergetags | align bullist numlist | spellcheckdialog a11ycheck | code removeformat',
  toolbar_mode: "wrap",
  menubar: false,
  inline: true,
  auto_focus: 'editor-1',
  link_target_list: false,
  object_resizing: false,
  placeholder: "Write here...",
  images_file_types: "jpeg,jpg,png,gif",
  editimage_toolbar: "editimage imageoptions",

};
const TinyMCE_Letter: RawEditorOptions & { selector?: undefined; target?: undefined; } = {
  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',

}

const TinyMCE_Email2: RawEditorOptions & { selector?: undefined; target?: undefined; } = {

  // most of these plugins are premium 
  // plugins: 'advcode a11ychecker autocorrect autolink emoticons image inlinecss link linkchecker lists mergetags powerpaste tinymcespellchecker',
  // these are the free plugins
  plugins: 'autolink emoticons image link lists',
  toolbar: 'mergetags | undo redo | styles | bold italic forecolor backcolor | link image emoticons mergetags | align bullist numlist | spellcheckdialog a11ycheck | code removeformat',

  toolbar_mode: "wrap",

  menubar: false,

  link_target_list: false,

  object_resizing: false,

  formats: {
    h1: { block: 'h1', styles: { fontSize: '24px', color: '#335dff' } },
    h2: { block: 'h2', styles: { fontSize: '18px' } },
  },

  style_formats: [
    { title: 'Paragraph', format: 'p' },
    { title: 'Heading 1', format: 'h1' },
    { title: 'Heading 2', format: 'h2' },
  ],

  images_file_types: "",

  editimage_toolbar: "editimage imageoptions",

  mergetags_list: [
    { value: 'First.Name', title: 'First Name' },
    { value: 'Email', title: 'Email' },
  ]

};
