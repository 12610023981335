import { PropsWithChildren, useImperativeHandle, useMemo, useState } from 'react';
import { EventEmitter } from '@angular/core';
import { filter } from 'rxjs';
import React from "react";
import { useAngular, useAsyncEffect, useEventEmitter, useObservable, useObserver } from 'react-utils';
import { EventType, Router } from '@angular/router';
import { MakePaymentModel } from './page-amount';
import { PaymentInfoModel } from './page-input';
import { PaymentApp, usePaymentStatus } from './usePaymentStatus';
import { DataService, PaymentLedgerKeys } from 'common';
import DineroFactory from 'dinero.js';
export type PaymentMode = "" | "make-payment" | "make-payment-success" | "update-payment" | "update-payment-success";
interface PaymentContext {
  // id: string;
  paymentMode: EventEmitter<PaymentMode>;
  paymentState: ReturnType<typeof usePaymentStatus> | undefined;
}
export const PaymentEventsContext = React.createContext<PaymentContext>(null as never);
export function usePaymentsModal() { return React.useContext(PaymentEventsContext); }

export function usePaymentProcessor({ PaymentLedger, id, balance, }: {
  PaymentLedger: PaymentLedgerKeys;
  id: string;
  balance: Dinero.Dinero;
}) {

  const state = usePaymentStatus(PaymentLedger, id);
  const hostID = id;
  const events = useEventEmitter<PaymentMode>();

  return {
    state, 
    events, 
    paymentModalsMarkup: state && <>
      <MakePaymentModel {...state} {...{ PaymentLedger, hostID, balance }} events={events} />
      <PaymentInfoModel {...state} {...{ PaymentLedger, hostID }} events={events} />
    </>
  };

}
