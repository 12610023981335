import { EventEmitter, InjectFlags, InjectOptions, Injector, NgZone } from '@angular/core';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { NavigationBehaviorOptions, NavigationExtras, Router, UrlTree } from '@angular/router';
import { NavigateFunction, useNavigation } from 'react-router';
import { ConfirmationService, MessageService } from './services';

export type ProviderToken<T> = { new(injector: Injector): T } | typeof NgZone | typeof Router;

export class InjectorAngularTokenError extends Error {
  constructor(public token: any) {
    console.log("Cannot use angular token", token);
    super(`Cannot use angular token ${token.name}`);
  }
}
export const ReactInjectableSymbol: unique symbol = Symbol("ReactInjectable");
export function ReactInjectable<T extends { new(injector: Injector): any;[ReactInjectableSymbol]?: boolean; }>() {
  return function (target: T) {
    target[ReactInjectableSymbol] = true;
    if (target.length !== 1) throw new Error("ReactInjectable classes must have a single argument constructor");
    return target;
  }
}



@ReactInjectable()
export class ReactInjectorRouterHack {
  static globalNavigate: NavigateFunction;
  constructor(private injector: Injector) {

  }
  navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions | undefined): Promise<boolean> {
    console.error("navigating");
    try {
      ReactInjectorRouterHack.globalNavigate(url.toString(), {
        replace: extras?.replaceUrl,
      });
    } catch (e) {
      console.log(e);
      ReactInjectorRouterHack.globalNavigate("/");
    }
    return new Promise(() => { });
  }
  navigate(commands: any[], extras?: NavigationExtras | undefined): Promise<boolean> {
    return this.navigateByUrl(this.injector.get(Router).createUrlTree(commands, extras));
  }
}

export class ReactInjector extends Injector {
  private lookup = new Map<any, any>();
  private provides: Set<any>;
  constructor(
    private parent: ReactInjector | undefined,
    provides: ProviderToken<any>[] = []
  ) {
    super();
    this.provides = new Set(provides);
  }
  get<T>(token: ProviderToken<T>, notFoundValue: undefined, options: InjectOptions & { optional?: false | undefined; }): T;
  get<T>(token: ProviderToken<T>, notFoundValue: null | undefined, options: InjectOptions): T | null;
  get<T>(token: ProviderToken<T>, notFoundValue?: T | undefined, options?: InjectOptions | InjectFlags | undefined): T;
  get<T>(token: ProviderToken<T>, notFoundValue?: T | undefined, flags?: InjectFlags | undefined): T;
  get(token: any, notFoundValue?: any): any;
  get(token: ProviderToken<any>, notFoundValue?: unknown, flags?: InjectOptions | InjectFlags): any {
    if (token !== NgZone && token !== Router && typeof token === "function" && token.length !== 1)
      console.error("ReactInjector only supports classes with a single argument", token);

    if (typeof flags === "number") flags = {
      host: (InjectFlags.Host & flags) === InjectFlags.Host,
      optional: (InjectFlags.Optional & flags) === InjectFlags.Optional,
      self: (InjectFlags.Self & flags) === InjectFlags.Self,
      skipSelf: (InjectFlags.SkipSelf & flags) === InjectFlags.SkipSelf,
    };
    if (flags?.host)
      throw new Error("host flag is not implemented");
    if (flags?.skipSelf) {
      if (this.parent)
        return this.parent.get(token, notFoundValue);
      else
        return this.finishNotFound(token, notFoundValue, flags?.optional);
    }
    if (!this.lookup.has(token)) {
      if (!this.parent || this.provides.has(token)) {
        const inst = (() => {
          if (token === NgZone)
            return new Proxy({}, {
              get: (target, prop) => {
                debugger;
                throw new Error("Why is zone being used?");
              }
            });
          if (token === Router)
            return new Proxy(this, {
              get: (target, prop) => {
                if (prop === "url") return location.pathname + location.search;
                if (prop === "navigateByUrl") return target.get(ReactInjectorRouterHack).navigateByUrl;
                if (prop === "navigate") return target.get(ReactInjectorRouterHack).navigate;
                throw new Error(`Why is router being used for ${String(prop)}?`);
              }
            });

          if ("ɵprov" in token && !(ReactInjectableSymbol in token))
            throw new InjectorAngularTokenError(token);
          // throw new Error("Cannot replace angular factory for " + token.name);

          return new token(this);
        })();
        this.lookup.set(token, inst);
        return inst;
      } else if (!flags?.self) {
        return this.parent.get(token);
      } else {
        return this.finishNotFound(token, notFoundValue, flags?.optional);
      }
    }
    return this.lookup.get(token);
  }
  finishNotFound = (token: ProviderToken<any>, notFoundValue: any | undefined, optional: boolean | undefined) => {
    if (notFoundValue === undefined && optional)
      return null;
    if (notFoundValue === undefined || notFoundValue === Injector.THROW_IF_NOT_FOUND)
      throw new Error("ReactInjector does not have an instance of " + token.name);
    else
      return notFoundValue;
  };
}


interface NgContext {
  get: <T>(e: ProviderToken<T>) => T,
  getAll: <E extends Record<string, ProviderToken<any>>>(e: E) => { [K in keyof E]: E[K] extends ProviderToken<infer T> ? T : never },
  injector: Injector
}

const InjectorCtx = createContext<NgContext | null>(null)

export function NgContextProvider({ injector, children }: PropsWithChildren<{ injector: Injector }>) {
  const value = useMemo((): NgContext | null => ({
    injector,
    get: e => injector.get(e),
    getAll: e => {
      const res = {} as any;
      for (const key in e) {
        res[key] = injector.get(e[key]);
      }
      return res;
    },
  }), [injector]);
  return <InjectorCtx.Provider value={value}>{children}</InjectorCtx.Provider>
}

export function useAngular(): NgContext {

  const injector = useContext(InjectorCtx);

  if (!injector) throw new Error('Missing NgContext');

  return injector;
}
