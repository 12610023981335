
import React from "react";
import { ENUM_NAMES, FieldFilterType, hasMany, ModelClass, okNull, QuestionEnumOption, Roles, SPPI, TYPE_NAMES, UIService, Modes, truthy, RentalStatus, PaymentStatus, ok, PrismaExtra, FieldClass, Prisma, is } from "common";
import { EnumSchemaEntry, schema, TableViewColumn } from "common";
import { EventEmitter } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { FormControl, ValidationErrors } from "@angular/forms";
import { FGCR, QuestionBase, QuestionGroup } from '../utils';
import { ColumnSortEvent } from "primereact/column";
import { parse, parseISO } from "date-fns";
import { ValueTree } from "common";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Badge, IndexTableRowProps } from "@shopify/polaris";
import DineroFactory from "dinero.js";
import { TableViewColumnNormal } from "../tables/table-views";

dayjs.extend(utc) // use plugin
dayjs.extend(customParseFormat) // use plugin

const RowUnknown: unique symbol = Symbol("Row Unknown");

function sortNumberHelper(a: number | boolean | undefined, b: number | boolean | undefined, dir: 1 | -1): number {
  // including dir here puts it at the end of the list in both directions
  if (typeof a === "boolean" && typeof b === "boolean") return a === b ? 0 : a ? dir : -dir;
  // use comparison rather than subtraction to avoid overflow
  if (typeof a === "number" && typeof b === "number") return a > b ? dir : a === b ? 0 : -dir;
  if (a == null || b == null) return (a == null ? b == null ? 0 : 1 : -1);
  debugger; // both values should be numbers or booleans, or one should be null or undefined. otherwise problem.
  return 0;
}

export interface IDataListColumn {
  // /** full path of the column, not keyof T, aka value */
  // key: string;
  /** number indicating sort order (absolute value) and direction (sign) */
  sort: number;
  /** is it actually visible (or used for something else) */
  hidden: boolean;
  /** are edits allowed if row editing */
  readonly: boolean;
  /** whether the field is considered required in various scenarios */
  required: boolean;
  /** the type field on p-columnFilter */
  filterType: "boolean" | "text" | "numeric" | "date" | "enum" | "currency" | "none";
  /** title of the column */
  title: string;
  /** returns the properly formatted text for the value */
  text: (val: string) => string;
  /** returns the sort order between two values */
  sorter: (a1: string, b1: string, dir: 1 | -1) => number;
  /** returns whether the value matches the filter */
  filter: (val: string) => any;
  // /** walk the object using the column key and return the value, optionally formatted for display or filter */
  // walk(obj: any, format?: "text" | "filter"): any;
  is(type: "enum"): this is IDataListEnumColumn;
  is(type: "base"): this is IDataListColumn;

}

export interface IDataListEnumColumn extends IDataListColumn {
  enumOptions: { value: string; label: string; }[];
  enumIndex: string[];
}
type EnumKeys<T> = T extends `${infer X}` ? X : never
export abstract class ColumnBase implements IDataListColumn {

  abstract key: any;
  abstract text: (val: string) => string;

  /** given the val, returns the value to filter on */
  filter: (val: string) => any;
  /** given the row objects, returns the sort order */
  sorter: (row_a: any, row_b: any, dir: 1 | -1) => number;
  abstract sort: number;
  // filterType: "boolean" | "text" | "numeric" | "date" | "enum" | "currency" | "none";
  abstract title: string;
  abstract hidden: boolean;
  abstract readonly: boolean;
  abstract required: boolean;
  abstract queryColumn: boolean;

  displayGroup: string | undefined;
  link?: (row: any) => string | null | undefined;

  public calculate?: (val: any) => any;

  constructor(public filterType: "boolean" | "text" | "numeric" | "date" | "enum" | "currency" | "none", format?: string) {


    if (filterType === "none" || filterType === "enum") {
      // enum filters are ignored here
    } else if (filterType === "date" && format) {
      this.filter = (val) => format ? parse(val, format, Date.now()) : parseISO(val);
    } else if (filterType === "boolean" || filterType === "numeric") {
      this.filter = (val) => val;
    } else {
      this.filter = (val) => this.text(val);
    }

    if (filterType === "none" || filterType === "enum") {
      // enum filters are ignored here
    } else if (filterType === "boolean" || filterType === "numeric" || filterType === "currency") {
      this.sorter = (a1, b1, dir) => sortNumberHelper(this.get(a1), this.get(b1), dir);
    } else if (filterType === "date") {
      this.sorter = (a: unknown, b: unknown, dir: 1 | -1) => {
        const a1 = this.get(a);
        const b1 = this.get(b);
        if (a1 == null || b1 == null) return a1 == null ? b1 == null ? 0 : 1 : -1;
        if (typeof a1 !== "string") debugger;
        if (typeof b1 !== "string") debugger;
        const a2 = format ? parse(a1, format, Date.now()) : parseISO(a1);
        const b2 = format ? parse(b1, format, Date.now()) : parseISO(b1);
        return sortNumberHelper(a2.valueOf(), b2.valueOf(), dir);
        // return b1.valueOf() - b2.valueOf();
      }
    } else {
      this.sorter = (a: unknown, b: unknown, dir) => {

        const a1 = this.get(a);
        const b1 = this.get(b);

        // null is always bigger
        if (a1 == null || b1 == null) return a1 == null ? b1 == null ? 0 : 1 : -1;

        if (typeof a1 !== "string") debugger;
        if (typeof b1 !== "string") debugger;

        return dir * (this.text(a1)).localeCompare(this.text(b1));
      }
    }

  }

  walk(row: any, result?: "text" | "filter") {
    const val = this.get(row);
    switch (result) {
      case "text": return this.text(val);
      case "filter": return this.filter(val);
      default: return val;
    }
  }

  is(t: "enum"): this is DataListEnumColumn;
  is(t: "base"): this is DataListColumn;
  is(t: "base" | "enum"): boolean {
    if (this instanceof DataListEnumColumn) return t === "enum";
    if (this instanceof DataListColumn) return t === "base";
    throw new Error("invalid is parameter " + t)
  }
  abstract get(row: any): any;
  abstract set(row: any, value: any): void;
  aggregate: TableViewColumn["aggregate"];
  markup?: (val: unknown) => React.JSX.Element | null;
  valText(row: any) {
    return this.text(this.get(row));
  }
  valFilter(row: any) {
    return this.filter(this.get(row));
  }
  valMarkup(row: any) {
    return this.markup ? this.markup(this.get(row)) : this.valText(row);
  }

  aggVal(val: any) {
    val = Array.isArray(val) ? val : [val];
    switch (this.aggregate) {
      case "array": return val;
      case "array-unique": return [...new Set(val)];
      case "array-truthy": return val.filter(truthy);
      case "count": return val.length;
      case "count-unique": return [...new Set(val)].length;
      case "count-truthy": return val.filter(truthy).length;
    }
    if (val.length === 0) return undefined;
    if (val.length === 1) return val[0];
    switch (this.aggregate) {
      case "sum": return val.reduce((n: number, e: number) => typeof e !== "number" ? n : (n ?? 0) + (e ?? 0), undefined);
      case "average": return val.reduce((n: number, e: number, i: number) => (n + (e / (i + 1))), 0);
      case "min": return Math.min(...val);
      case "max": return Math.max(...val);
      case "first": return val[0];
      case "last": return val[val.length - 1];

      default: {
        // if aggregate is undefined, it should be a single value, which returns before the switch
        // if it is not undefined, it should be one of the above cases
        const t: undefined = this.aggregate;
        // eslint-disable-next-line no-debugger
        debugger;
      }
    }
  }

}

export abstract class KeyColumnBase extends ColumnBase {
  abstract override key: string;


  /** This walks the object tree using the keys and aggregates the arrays it finds at every level */
  get(row: any) {
    // return this.key.split('/').reduce((n: any, e) => n ? Array.isArray(n[e]) ? n[e] : n[e] : undefined, row);

    return this.aggVal(this.calculate ? this.calculate(row) : agg(row, this.key.split('/')));

    function agg(n: any, keys: string[]): any {
      const e = keys.shift();
      if (!e) return n;
      if (n === undefined || n === null) return undefined;
      if (Array.isArray(n[e])) {
        return n[e].flatMap((f: any) => agg(f, keys.slice()));
      } else {
        return agg(n[e], keys);
      }
    }

  }
  set(row: any, value: any) {
    this.key.split('/').reduce((n: any, e, j, keys) => j === keys.length - 1 ? (n[e] = value) : n[e], row);
  }
}

export class SimpleKeyColumn extends KeyColumnBase {

  public hidden: boolean;
  public readonly: boolean;
  public required: boolean;
  public queryColumn: boolean;
  constructor(
    public key: string,
    public title: string,
    public text: (val: string) => string,
    public sort: number,
  ) {
    super("text", "");
  }

}

export class ArrayColumn extends ColumnBase {
  override key: undefined;

  public hidden: boolean = false;
  public readonly: boolean = false;
  public required: boolean = false;
  public queryColumn: boolean = false;
  constructor(
    public index: number,
    public title: string,
    public text: (val: string) => string,
    public sort: number,
  ) {
    super("text", "");
  }
  /** This only supports a flat array row, but it does put it through aggVal */
  get(row: any) {
    return this.aggVal(this.calculate ? this.calculate(row) : row[this.index]);
  }
  set(row: any, value: any) {
    row[this.index] = value;
  }

}

export class ValueColumnBase extends KeyColumnBase {
  hidden: boolean = false;
  readonly: boolean = true;
  required = false;

  constructor(
    public key: string,
    public title: string,
    /** returns the properly formatted text for the value */
    public text: (val: string) => string,
    filterType: EnumKeys<FieldFilterType>,
    format: string,
    public sort: number,
    public queryColumn: boolean,
  ) {
    super(filterType, format);
  }

}

export class SelectEnumColumn extends KeyColumnBase implements IDataListColumn {
  private enumOrder: Record<string, number>;
  private enumTitle: Record<string, string>;
  constructor(
    enumOptions: QuestionEnumOption[]
  ) {
    super("enum");
    this.enumOrder = {};
    this.enumTitle = {};
    enumOptions.forEach(e => {
      this.enumOrder[e.value] = e.order;
      this.enumTitle[e.value] = e.title ?? e.value;
    });
    this.text = (val) => this.enumTitle[val];
    this.filter = (val) => this.enumTitle[val];
    this.sorter = (a, b, dir) => { return dir * (this.enumOrder[this.get(a)] - this.enumOrder[this.get(b)]); }
    this.filterType = "text";
    this.hidden = false;
    this.readonly = true;
  }
  key: string = "value";
  sort: number = 0;
  hidden: boolean = false;
  readonly: boolean = true;
  title: string = "";
  required = false;
  queryColumn: boolean = false;
  /** returns the properly formatted text for the value */
  text: (val: string) => string;
  // /** returns the sort order between two values */
  // sorter: (a1: string, b1: string) => number;
  // /** returns whether the value matches the filter */
  // filter: (val: string) => any;
}

export class DataListIdFieldColumn extends KeyColumnBase implements IDataListColumn {
  constructor(public key: string) {
    super("none");
    this.filter = e => e;
    this.sorter = (a, b, dir) => 0;
  }

  sort: number = -1;
  hidden: boolean = true;
  readonly: boolean = true;
  title: string = "idfield";
  text: (val: string) => string = e => e;
  required = true;
  queryColumn = false;
}

function toDollars2(e: string) {
  if (e === null || e === undefined || Number.isNaN(+e)) return "";
  if (Array.isArray(e)) if (e.length !== 1) debugger; else e = e[0];
  return "$" + (+(`${e}e-2`)).toFixed(2);
}

export type Idx<O, P> = P extends keyof O ? O[P] : never;
export class DataListColumn extends KeyColumnBase implements IDataListColumn {
  static textScalarDate = (e: string) => e ? dayjs.utc(e, "YYYY-MM-DD").format("MMM DD, YYYY") : "";
  static textScalarDateTime = (e: string) => e ? dayjs.utc(e).local().toDate().toDateString() : "";
  static textCubesDinero = (e: any) => {
    if (Array.isArray(e)) { if (e.length !== 1) debugger; e = e[0]; }
    if (e === null || e === undefined || Number.isNaN(+e)) return "";
    const [hi, lo = "0"] = `${+`${e}e-2`}`.split(".");
    return `$${hi.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${lo.padEnd(2, "0")}`;
    // return DineroFactory({ amount: +e }).toFormat("$0,0.00");
  }
  static getFieldType(key: string, typepath: ModelClass<any>[]) {
    const fieldname = key.split("/")?.map(e => e.trim()).last() || key;
    const fieldtype = typepath.last()?.fields[fieldname];
    ok(fieldtype);
    return fieldtype;
  }

  static getFilterType(fieldtype: FieldClass) {
    const fieldattr = fieldtype?.attributes.field?.first() ?? {};

    let filterType: "boolean" | "text" | "numeric" | "date" | "enum" | "currency" | "none" = fieldattr.filterType ??
      (fieldtype?.name === "Boolean" ? "boolean" :
        fieldtype?.type === "enum" ? "enum" :
          "text");

    if (fieldtype?.isType("scalar") && fieldtype.name === "CubesDinero") filterType = "currency";

    return filterType;

  }
  public hidden: boolean = false;
  public readonly: boolean = false;
  public queryColumn: boolean = false;

  constructor(
    public key: string,
    public sort: number,
    fieldtype: FieldClass,
  ) {
    const filterType = DataListColumn.getFilterType(fieldtype);
    const format = fieldtype?.options['format'];

    super(filterType, format);

    this.required = fieldtype?.isRequired || false;

    if (fieldtype?.isType("enum")) {
      // enum takes care of setting text
    } else if (fieldtype?.isType("scalar") && fieldtype.name === "CubesDinero") {
      this.text = DataListColumn.textCubesDinero;
    } else if (fieldtype?.isType("scalar") && fieldtype.name === "ScalarDateTime") {
      this.text = DataListColumn.textScalarDateTime;
    } else if (fieldtype?.isType("scalar") && fieldtype.name === "ScalarDate") {
      this.text = DataListColumn.textScalarDate;
    } else {
      this.text = (e) => e;
    }



    if (fieldtype.key === "paidOn" && this.filterType === "date") {
      this.markup = (val) => {
        if (val) {
          return (<Badge progress="complete" tone="success">{this.text(val)}</Badge>);
        } else if (val === null) {
          return (<Badge progress="incomplete" tone="critical">No</Badge>);
        } else {
          return null;
        }
      }
    } else if (this.key === "IS_TESTING") {
      this.markup = val => val ? (<Badge progress="incomplete" tone="critical">Test</Badge>) : null;
    } else if (fieldtype?.name === "RentalStatus") {
      this.markup = val => {
        switch (val as PrismaExtra.$Enums.RentalStatus) {
          case "Reserved": return (<Badge progress="incomplete" tone="critical">{this.text(val)}</Badge>);
          case "Scheduled": return (<Badge progress="complete" tone="attention">{this.text(val)}</Badge>);
          case "Rented": return (<Badge progress="complete" tone="success">{this.text(val)}</Badge>);
          case "Moving_Out": return (<Badge progress="incomplete" tone="warning">{this.text(val)}</Badge>);
          case "Completed": return (<Badge progress="incomplete" tone="critical">{this.text(val)}</Badge>);
          case "Retained": return (<Badge progress="complete" tone="success">{this.text(val)}</Badge>);
          case "RentToOwn": return (<Badge progress="complete" tone="info">{this.text(val)}</Badge>);
          // case "SoldToCustomer": return (<Badge progress="complete" tone="success">{this.text(val)}</Badge>);
          default: return <Badge progress="complete">{this.text(val)}</Badge>;
        }
      }
    } else if (fieldtype?.name === "PaymentStatus") {
      this.markup = (val) => {
        ok(is<PrismaExtra.PaymentStatus>(val, true));
        switch (val) {
          case "Cleared":
            return (<Badge progress="complete" tone="success">Cleared</Badge>);
          case "Approved":
            return (<Badge progress="partiallyComplete" tone="warning">Approved</Badge>);
          case "Voided":
          case "Bounced":
            return (<Badge progress="incomplete" tone="attention">{typeof val === "string" ? val : ""}</Badge>);
            case "Declined":
            return (<Badge progress="incomplete" tone="critical">{typeof val === "string" ? val : ""}</Badge>);
          case null:
          case undefined:
            return null;
          default:
            return (<Badge progress="incomplete">{typeof val === "string" ? val : ""}</Badge>);
        }
      }
    } else if (this.filterType === "boolean") {
      if (fieldtype?.key === "Unavailable")
        this.markup = (val) => {
          switch (val) {
            case true: return (<Badge progress="incomplete">Unavailable</Badge>);
            default: return (<Badge progress="complete" tone="success">Available</Badge>);
          }
        }
      else if (fieldtype?.key === "IsRentToOwn")
        this.markup = (val) => {
          switch (val) {
            case true: return (<Badge progress="complete" tone="info">Rent To Own</Badge>);
            default: return null;
          }
        }
      else
        this.markup = (val) => {
          switch (val) {
            case true:
              return (<Badge progress="complete" tone="success">Yes</Badge>);
            case false:
            case null:
              return (<Badge progress="incomplete" tone="critical">No</Badge>);
            default:
              return null;
          }
        }
    }


  }


  title: string;

  text: (val: any) => string;

  required: boolean;

  typename?: TYPE_NAMES;
  typepath?: ModelClass<any>[];

  setSchema(typename: TYPE_NAMES, typepath: ModelClass<any>[]) {
    this.title = this.key.split("/").map((e, i) => {
      return typepath[i].fields[e].attributes.field?.first()?.title ?? "";
    }).join(" ");
    this.typepath = typepath;
    this.typename = typename;
  }

}

export class DataListSchemaColumn extends DataListColumn {
  constructor(
    key: string,
    sort: number,
    typepath: ModelClass<any>[],
  ) {
    const fieldname = key.split("/")?.map(e => e.trim()).last() || key;
    const fieldtype = typepath.last()?.fields[fieldname];
    ok(fieldtype);
    super(key, sort, fieldtype);
    this.title = this.key.split("/").map((e, i) => {
      return typepath[i].fields[e].attributes.field?.first()?.title ?? "";
    }).join(" ");
  }
}

export class DataListGroupColumn extends DataListColumn {
  childgroup: TableViewColumnNormal[];

}

const orderSort = (a: { order: number }, b: { order: number }) => a.order - b.order;

export class DataListEnumColumn extends DataListColumn {

  public enumOptions: { value: string; label: string; }[] = [];
  public enumIndex: string[] = [];
  options;
  constructor(
    key: string,
    sort: number,
    fieldtype: FieldClass,
  ) {
    super(key, sort, fieldtype);
    const fieldname = this.key.split("/")?.map(e => e.trim()).last() || this.key;
    const extra = fieldtype;
    okNull(extra);
    const field = extra?.attributes.field?.first();
    okNull(field);
    if (!extra?.isType("enum")) throw new Error("DataListEnumColumn can only be used with enum types");
    const { options } = schema.enums[extra.name as ENUM_NAMES] as EnumSchemaEntry<any>;
    const enums = Object.values(options).sort(orderSort);

    this.enumOptions = enums.map(e => ({ value: e.value, label: e.label ?? e.value }));
    this.enumIndex = enums.map(e => e.value);
    this.options = options;

    this.text = (e) => this.options[e]?.label ?? "";
    this.sorter = (a1: string, b1: string, dir) => dir * (this.enumIndex.indexOf(this.get(a1)) - this.enumIndex.indexOf(this.get(b1)));

  }
}

export class DataListCustomColumn extends KeyColumnBase implements DataListColumn {
  constructor(
    public key: string,
    public title: string,
    filterType: IDataListColumn["filterType"],
    format: string,
    public override get: (row: any) => any,
    public text: (val: string) => string,
    public queryColumn: boolean,
    // public filter: (val: string) => any,
    // public sorter: (a1: string, b1: string) => number,
  ) {
    super(filterType, format);
    this.sort = 0;
    this.hidden = false;
    this.readonly = true;

  }
  // IDataListColumn
  sort: number;
  hidden: boolean;
  readonly: boolean;
  required = false;
  // abstract ColumnBase
  typepath: never;
  typename: never;

  setSchema(typename: TYPE_NAMES, typepath: ModelClass<any>[]): void {
    throw new Error("Method not implemented.");
  }

}

export class DataListLookupColumn<V> extends DataListColumn {
  constructor(
    key: string,
    sort: number,
    title: string,
    fieldtype: FieldClass,
  ) {
    super(key, sort, fieldtype);
    this.title = title;
  }
  lookup = new Map<string, V>();
  override get = (row: any) => {
    ok(row.id);
    return this.lookup.get(row.id);
  };
}



export class EditTreeAccessor<T extends Record<string, any>> {
  // protected list: string[];
  constructor(
    public list: SPPI[],
    cols: ColumnBase[],
    public ui: UIService,
    public userRole: Roles,
    public readonly rowType: TYPE_NAMES,
    public readonly idfield: "id",
    public readonly setID: boolean,
    public pageSetupGroup: (mode: Modes) => QuestionGroup<any, FGCR>,
    debugged?: boolean
  ) {
    // this.list = list as any[];
    if (!debugged) debugger;
    this.cols = cols;
    this.idcol = new DataListIdFieldColumn(this.idfield);
  }
  get ptable_idfield() { return this.idfield ? this.idfield.split("/").join(".") : undefined; }
  subs = new Subscription();
  readonly idcol: DataListIdFieldColumn;
  idfieldVal(row: T) {
    return this.idcol.get(row);
  }
  get(row: T, col: number) {
    if (this.cols[col].filterType === "boolean")
      return this.cols[col].get(row);
    else
      return this.cols[col].valText(row);
  }
  set(row: T, col: number, value: any) {
    this.cols[col].set(row, value);
    this.update();
    this.#cells.emit({ row, col, value });
  }
  select(row: T, col: number, index: number, selected: boolean): void {
    throw new Error("Method not implemented.");
  }
  update() {
    this.#value = JSON.parse(JSON.stringify(this.#table));
    this._emitter.emit(this.value);
    this.pendingChange = true;
    this._form.setValue(this.value);

    // console.log(this.value, this._form.errors, this._editGroups.size);
  }
  setForm(form: FormControl<T[] | null>): void {
    this._form = form;
    this.subs.add(form.valueChanges.subscribe(e => {
      if (this.pendingChange) {
        this.pendingChange = false;
      } else {
        this.value = e;
      }
    }));

  }
  mapRow(row: T, i: number) {
    if (this.setID) {
      if (!this.idfieldVal(row))
        Object.defineProperty(row, this.idfield, {
          configurable: true,
          enumerable: false,
          value: i.toFixed(0),
          writable: false
        });
    } else {
      okNull(this.idfieldVal(row));
    }
    Object.defineProperties(row, this.cols.reduce((n, e, col) => (
      n["filter:" + e.key] = {
        get: () => this.cols[col].valFilter(row),
        enumerable: false,
        configurable: true,
      }, n), {} as PropertyDescriptorMap));
  }
  #table: T[] = [];
  get table() { return this.#table; }
  #value: T[] = [];
  /** value can be anything depending on how sub classes handle this */
  get value(): T[] { return this.#value; }
  set value(v: T[] | null) {
    if (v == null)
      v = [];
    else if (!Array.isArray(v))
      throw new Error("value must be an array");

    this.#table.forEach((e, i) => this.mapRow(e, i));

    this.#table = v as any;
    this.update();
  }

  #cols!: readonly ColumnBase[];
  #colsIndex!: Record<string, number>;

  public get cols(): readonly ColumnBase[] {
    return this.#cols;
  }
  public set cols(v: readonly ColumnBase[]) {
    this.#cols = v;
    this.#colsIndex = v.reduce((n, e, i) => (n[e.key] = i, n), {} as any);
  }

  pendingChange = false;
  protected _form!: FormControl<T[] | null>;
  #cells = new EventEmitter<{ row: T, col: number, value: any }>();
  _emitter = new EventEmitter<T[]>();
  valueChanges = this._emitter.asObservable();
  cellChanges = this.#cells.asObservable();
  hasMany?: ValueTree<hasMany<any, any>>;

  sort: (event: ColumnSortEvent) => T[] = (event: ColumnSortEvent) => {
    // console.log(event, this);
    if (!event.data) return [];
    const data: T[] = event.data;
    return data.sort((a, b) => {
      const result = 0;
      if (!event.multiSortMeta) return result;
      for (let i = 0; i < event.multiSortMeta.length; i++) {
        const { field, order } = event.multiSortMeta[i];
        const index = this.#colsIndex[field];
        const col = this.cols[index];
        return (!!col.sorter && order) ? col.sorter(a, b, order) : 0;
      }
      return 0;
    });
  }
  /** this should not be awaited, even if it is async */
  groupHook = new EventEmitter<{ group: QuestionGroup<any, FGCR>, row: T }>();
  protected _editGroups = new Map<T, QuestionGroup<any, FGCR>>();
  private getGroup(row: T) {
    const res = this._editGroups.get(row);
    okNull(res);
    return res;
  }
  hasGroup(row: T) {
    return this._editGroups.has(row);
  }
  group(row: T): QuestionGroup<any, FGCR> {
    return this.getGroup(row);
  }
  q(row: T, col: number) {
    const group = this.group(row);
    const path = this.list[col].split("/");
    return group.getPath(path, true)[0];
  }
  onRowEditInit(row: any): void {
    okNull(row);
    const group = this.pageSetupGroup(row === false ? "CREATE" : "UPDATE");
    this.groupHook.emit({ group, row });
    if (row) group.form.patchValue(row);
    this._editGroups.set(row, group);
    this._form.updateValueAndValidity({ emitEvent: false });
  }
  async onRowEditSave(row: any) {

    const group = this.group(row);
    const { value, error, formValue } = group.getValueAndValidity(this.idfieldVal(row) ? "UPDATE" : "CREATE", this.userRole);
    console.log(group, value, error);
    if (!value) return false;

    let index = this.table.indexOf(row);
    if (index > -1) {
      Object.assign(this.table[index], formValue);
    } else {
      index = this.table.length;
      this.mapRow(formValue as T, index);
      this.table.push(formValue as T);
    }
    Object.defineProperty(this.table[index], "_____groupsave_____", {
      configurable: true,
      enumerable: false,
      value,
      writable: false
    });


    group.subs.unsubscribe();
    this._editGroups.delete(row);
    this._form.updateValueAndValidity({ emitEvent: false });
    this.update();

    return true

  }
  onRowEditCancel(row: T): void {
    const group = this.getGroup(row);
    group.subs.unsubscribe();
    this._editGroups.delete(row);
    this._form.updateValueAndValidity({ emitEvent: false });
  }
  deleteRow(row: any) {
    if (this.hasGroup(row))
      this.onRowEditCancel(row);
    const index = this.table.indexOf(row);
    this.#table.splice(index, 1);
    this.update();
  }
  checkValid(): ValidationErrors | null {
    const res = this.table.filter(e => this._editGroups.has(e)).length > 0 ? { dirty: true } : null;
    // console.log(this.table.length, this._editGroups.size, res);
    return res;
  }
}
/** for compatibility reasons, the prefilled items will join on the first column */
export class PrefilledEditTreeAccessor<T extends Record<string, any>> extends EditTreeAccessor<T> {

  public prefilled: any[] = [];

  constructor(
    { child, other }: { child: string, other: string },
    ...args: ConstructorParameters<typeof EditTreeAccessor>
  ) {
    super(...args);
    this.otherID = other;
    this.subs.add(this.groupHook.subscribe(({ group, row }) => {
      group.controls[child].onlyfor = [];
    }));
  }
  idhash = {} as any;
  checkPrefilled(v: any[]) {
    this.idhash = {} as any;
    v.forEach((row: any) => {
      this.idhash[this.cols[0].get(row)] = row;
    });

    this.prefilled.forEach(row => {
      if (!this.idhash[this.cols[0].get(row)]) {
        const cloned = JSON.parse(JSON.stringify(row));
        (row.__tone__ as IndexTableRowProps["tone"]) = "warning";
        v.push(cloned);
      }
    });
  }
  private otherID: string;
  override get value() { return super.value; }
  override set value(v: any[]) {
    okNull(v);

    if (!Array.isArray(v)) throw new Error("value must be an array");

    this.checkPrefilled(v);

    super.value = v;
  }

}
