
import { CubesEnumMember, Member, TableType } from "./cubes-schema-helpers";

import {
  EnumMember, EnumType, IsArray, RecordType, ScalarType, RecordMember,
  Directive, ValueTree, MemberKeys, Wrapping,
  truthy, RegisterState, DirectiveOptions, __name_symbol
} from "./graphql-declarator";
import { index_makeName } from "./cubes-utils";
import "reflect-metadata";


function StringGeneric(generic: string) {
  return function (target: any, propertyKey: string): void {
    Reflect.defineMetadata("StringGeneric", generic, target, propertyKey);
  };
}

// const root = new Root();
// type Equal<A, B, C> = B extends A & B ? C : never;
// type imp = typeof import("./cubes-amazon-old");
// type proto<T> = { prototype: T };
// export type DIRECTIVES_NAMES<T extends DirectiveOptions> = {
//   [K in keyof imp]: imp[K] extends proto<Directive<T>> ? K : never;
// }[keyof imp];
// export type ENUM_NAMES = {
//   [K in keyof imp]: imp[K] extends proto<EnumType> ? K : never;
// }[keyof imp];
// export type ENUM_VALUES<T extends ENUM_NAMES> = {
//   [K in T]: imp[K] extends proto<EnumType>
//   ? MemberKeys<imp[K]["prototype"]>
//   : never;
// }[T];
// export type TYPE_NAMES = {
//   [K in keyof imp]: imp[K] extends proto<RecordType> ? K : never;
// }[keyof imp];
// export type FIELD_NAMES<T extends TYPE_NAMES> = {
//   [K in T]: imp[K] extends proto<RecordType>
//   ? MemberKeys<imp[K]["prototype"]>
//   : never;
// }[T];
// export type TABLE_NAMES = {
//   [K in keyof imp]: K extends "TableType" ? never : imp[K] extends proto<TableType> ? K : never;
// }[keyof imp];
// export type SCALAR_NAMES = {
//   [K in keyof imp]: imp[K] extends proto<ScalarType<any>> ? K : never;
// }[keyof imp];
// export type ITEM_NAME<T> = {
//   [K in keyof imp]: Equal<imp[K]["prototype"], T, K>
// }[keyof imp]
// /** The generic type allows only directives with ALL the options */
// export type Directives<T extends DirectiveOptions = any> = {
//   [D in DIRECTIVES_NAMES<T>]?: (imp[D]["prototype"] & {
//     __values: ValueTree<imp[D]["prototype"]>;
//   })[];
// };
// /** The generic type allows only directives with ALL the options */
// export type Attributes<T extends DirectiveOptions = any> = {
//   [D in DIRECTIVES_NAMES<T>]?: (ValueTree<imp[D]["prototype"]> & {})[];
// };
// export type TableFieldStrings = SCALAR_NAMES;
// export type TableFieldTypeStrings =
//   | "enum"
//   | "model"
//   | "nonModel"
//   | TableFieldStrings;
// export type Values = { [T in keyof imp]: ValueTree<imp[T]["prototype"]> };
// export type Enums = { [T in ENUM_NAMES]: MemberKeys<imp[T]["prototype"]> };
// // export type Fields = { [T in TYPE_NAMES]: { [P in MemberKeys<imp[T]["prototype"]>]: imp[T]["prototype"][P] } }
// export type Types = { [T in TYPE_NAMES]: imp[T]["prototype"] };
// export type Tables = { [T in TABLE_NAMES]: imp[T]["prototype"] };



// export const root = new Member(SchemaDef,{}, {}, {}, {});
// import { Member, Filter, TableType } from "./cubes-schema";

function ok(a: any, message?: string): asserts a { if ((a ?? null) === null) throw new Error(message || "Assertion Failed"); }
const is = <T>(a: any, p: boolean): a is T => p;
type CP<T extends abstract new (...args: any) => any> = ConstructorParameters<T>;
type CPS = ConstructorParameters<typeof ScalarType<string>>;

/** A unique identifier for an object. This scalar is serialized like a String but isn't meant to be human-readable. */
export class ID extends ScalarType<string>{
  [__name_symbol] = "ID" as const;
}
/** A UTF-8 character sequence. */
export class String<T extends string = string> extends ScalarType<T>{
  [__name_symbol] = "String" as const;

}
/** A Boolean value, either `true` or `false`. */
export class Boolean<T extends boolean = boolean> extends ScalarType<T>{
  [__name_symbol] = "Boolean" as const;
}
/** An integer value between -(2^31) and 2^31-1. */
export class Int<T extends number = number> extends ScalarType<T>{
  [__name_symbol] = "Int" as const;
  
}
/** An IEEE 754 floating point value. */
export class Float<T extends number = number> extends ScalarType<T>{
  [__name_symbol] = "Float" as const;
}

/** An extended ISO 8601 date string in the format `YYYY-MM-DD`. */
export class ScalarDate extends ScalarType<string>{
  [__name_symbol] = "ScalarDate" as const;
  constructor(public format: string = "yyyy-MM-dd", ...args: CPS) { super(...args); }
}
/** An extended ISO 8601 time string in the format `hh:mm:ss.sss`. */
export class ScalarTime extends ScalarType<string>{
  [__name_symbol] = "ScalarTime" as const;
  constructor(public format: string = "hh:mm:ss.SSS", ...args: CPS) { super(...args); }
}
/** An extended ISO 8601 date and time string in the format `YYYY-MM-DDThh:mm:ss.sssZ`. */
export class ScalarDateTime extends ScalarType<string>{
  [__name_symbol] = "ScalarDateTime" as const;
  constructor(public format: string = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", ...args: CPS) { super(...args); }
}
/** An integer value representing the number of seconds before or after `1970-01-01-T00:00`Z. */
export class ScalarTimestamp extends ScalarType<number>{
  [__name_symbol] = "ScalarTimestamp" as const;
  constructor(public format: string = "yyyy-MM-dd", ...args: CPS) { super(...args); }
}

/** A phone number. This value is stored as a string. Phone numbers can contain either spaces or hyphens to separate 
 * digit groups. Phone numbers without a country code are assumed to be US/North American numbers adhering to the 
 * North American Numbering Plan (NANP). 
 */
export class ScalarPhone<T extends any = any> extends ScalarType<T>{
  [__name_symbol] = "ScalarPhone" as const;
  /* static register = root.register(() => new this()); */ /*__builtin = true;*/
}

export class ScalarJSON<K extends keyof PrismaJson.AllTypes> extends ScalarType<PrismaJson.AllTypes[K]>{
  static stringify<T>(a: T): string { return JSON.stringify(a); }
  [__name_symbol] = "ScalarJSON" as const;
  constructor(public type: K, ...args: CPS) { super(...args); }
}
/** An email address in the format local-part@domain-part as defined by RFC 822. */
export class ScalarEmail<T extends any = any> extends ScalarType<T>{
  [__name_symbol] = "ScalarEmail" as const;

}
/** A URL as defined by RFC 1738. For example, https://www.amazon.com/dp/B000NZW3KC/ or mailto:example@example.com. 
 * URLs must contain a schema (http, mailto) and can't contain two forward slashes (//) in the path part. 
 */
export class ScalarURL<T extends any = any> extends ScalarType<T>{
  [__name_symbol] = "ScalarURL" as const;
}
/** A valid IPv4 or IPv6 address. IPv4 addresses are expected in quad-dotted notation (123.12.34.56). IPv6 addresses 
 * are expected in non-bracketed, colon-separated format (1a2b:3c4b::1234:4567). You can include an optional 
 * CIDR suffix (123.45.67.89/16) to indicate subnet mask.
 */
export class ScalarIPAddress<T extends any = any> extends ScalarType<T>{
  [__name_symbol] = "ScalarIPAddress" as const;
}

export class CubesDinero extends ScalarType<number>{
  [__name_symbol] = "CubesDinero" as const;
}

export class AuthStrategyAmplify extends EnumType {


  /*__builtin = true;*/
  owner = new EnumMember()
  groups = new EnumMember()
  private = new EnumMember()
  public = new EnumMember()
  custom = new EnumMember()
}
export class AuthProviderAmplify extends EnumType {


  /*__builtin = true;*/
  apiKey = new EnumMember()
  iam = new EnumMember()
  oidc = new EnumMember()
  userPools = new EnumMember()
  function = new EnumMember()
}
export class ModelOperation extends EnumType {


  /*__builtin = true;*/
  create = new EnumMember()
  update = new EnumMember()
  delete = new EnumMember()
  read = new EnumMember() //# Short-hand to allow "get", "list", "sync", "listen", and "search"
  get = new EnumMember() //# Retrieves an individual item
  list = new EnumMember() //# Retrieves a list of items
  sync = new EnumMember() //# Enables ability to sync offline / online changes(including via DataStore)
  listen = new EnumMember() //# Subscribes to real - time changes
  search = new EnumMember() //# Enables ability to search using @searchable directive
}

export class BranchUserLevel extends EnumType {
  user = new CubesEnumMember("User");
  admin = new CubesEnumMember("Admin");
}
export class AuthRuleAmplify extends RecordType {


  /*__builtin = true;*/
  allow = new AuthStrategyAmplify()
  provider = new AuthProviderAmplify()
  ownerField = new String() //# defaults to "owner" when using owner auth
  identityClaim = new String() //# defaults to "sub::username" when using owner auth
  groupClaim = new String() //# defaults to "cognito:groups" when using Group auth
  groups = new IsArray(new String()) //# Required when using Static Group auth
  groupsField = new String() //# defaults to "groups" when using Dynamic Group auth
  operations = new IsArray(new ModelOperation()) // #Required for finer control
}


/** 
 * ```graphql
 * input ModelMutationMap {
 *   create: String
 *   update: String
 *   delete: String
 * }
 * ```
 */
export class ModelMutationMap extends RecordType {

  /*__builtin = true;*/
  create = new String()
  update = new String()
  delete = new String()

}
/** 
 * ```graphql
 * input ModelQueryMap {
 *   get: String
 *   list: String
 * }
 * ```
 */
export class ModelQueryMap extends RecordType {

  /*__builtin = true;*/
  get = new String();
  list = new String();
}
/** 
 * ```graphql
 * input ModelSubscriptionMap {
 *   onCreate: [String]
 *   onUpdate: [String]
 *   onDelete: [String]
 *   level: ModelSubscriptionLevel
 * }
 * ```
 */
export class ModelSubscriptionMap extends RecordType {

  /*__builtin = true;*/
  onCreate = new IsArray(new String());
  onUpdate = new IsArray(new String());
  onDelete = new IsArray(new String());
  level = new ModelSubscriptionLevel()
}
/** 
 * ```graphql
 * enum ModelSubscriptionLevel {
 *   off
 *   public
 *   on
 * }
 * ```
 */
export class ModelSubscriptionLevel extends EnumType {

  /*__builtin = true;*/

  off = new EnumMember()
  public = new EnumMember()
  on = new EnumMember()
}

/** 
 * ```graphql
 * input TimestampConfiguration {
 *   createdAt: String
 *   updatedAt: String
 * }
 * ```
 */
export class TimestampConfiguration extends RecordType {

  /*__builtin = true;*/

  createdAt = new String();
  updatedAt = new String();
}



/**
 * ```graphql
 * directive @model(
 *   queries: ModelQueryMap
 *   mutations: ModelMutationMap
 *   subscriptions: ModelSubscriptionMap
 *   timestamps: TimestampConfiguration
 * ) on OBJECT
 *  
 * ```
 */

class model<H extends TableType> extends Directive<"OBJECT"> {
  static G = `H extends TableType`;

  __host = { OBJECT: true } as const
  /*__builtin = true;*/
  declare __value: ValueTree<model<H>>;
  declare $def: (val: ValueTree<model<H>>) => this;

  @StringGeneric("MemberKeys<H>")
  uniques = new IsArray(new IsArray(new String<MemberKeys<H>>()));
  queries = new Member(ModelQueryMap,)
  mutations = new Member(ModelMutationMap,)
  subscriptions = new Member(ModelSubscriptionMap,)
  timestamps = new Member(TimestampConfiguration,)
  constructor(...args: ConstructorParameters<typeof Directive>) {
    super(...args);

    // this.__hookRegister.push(({ caller, host }) => {
    //   if (caller instanceof RecordType)
    //     if (!(caller instanceof TableType))
    //       throw new Error(`model directive found on ${caller.__name}`)
    // })
  }


}
class mapsTo extends Directive<"OBJECT" | "FIELD_DEFINITION"> {

  /*__builtin = true;*/
  __host = { OBJECT: true, FIELD_DEFINITION: true } as const;
  declare __value: ValueTree<mapsTo>;
  declare $def: (val: ValueTree<mapsTo>) => this;

  name = new String()
}
/**
 * ```graphql
 * directive @auth(rules: [AuthRule!]!) on OBJECT | FIELD_DEFINITION
 * ``` 
 */
class authAmplify extends Directive<"OBJECT" | "FIELD_DEFINITION"> {
  declare __value: ValueTree<authAmplify>;
  declare $def: (val: ValueTree<authAmplify>) => this;


  __host = { OBJECT: true, FIELD_DEFINITION: true } as const
  /*__builtin = true;*/

  rules = new IsArray(new Member(AuthRuleAmplify, true))
}



class authPostgres extends Directive<"OBJECT" | "FIELD_DEFINITION"> {
  declare __value: ValueTree<authPostgres>;
  declare $def: (val: ValueTree<authPostgres>) => this;


  __host = { OBJECT: true, FIELD_DEFINITION: true } as const
  /*__builtin = true;*/

  role = new PostgresUserRoles();
  privelages = new IsArray(new PostgresTablePermissions());
}


export class PostgresUserRoles extends EnumType {
  web_admin = new EnumMember()
  web_user = new EnumMember()
  // web_central_admin = new EnumMember()
  // web_central_user = new EnumMember()
  // web_dealer_admin = new EnumMember()
  // web_dealer_user = new EnumMember()
}

export class PostgresRoles extends PostgresUserRoles {
  app = new EnumMember();
}


export class PostgresTablePermissions extends EnumType {

  // __builtin = false;

  INSERT = new EnumMember();
  SELECT = new EnumMember();
  UPDATE = new EnumMember();
  DELETE = new EnumMember();
  TRUNCATE = new EnumMember();
  REFERENCES = new EnumMember();
  TRIGGER = new EnumMember();

}

/** 
 * ```graphql
 * directive @index(name: String) on FIELD_DEFINITION
 * name: "byNameAndPhoneNumber", sortKeyFields: ["phoneNumber"], queryField: "customerByNameAndPhone"
 * Specify target with $against
 * ```
 */
class index<H extends TableType> extends Directive<"FIELD_DEFINITION"> {
  static G = `H extends TableType`;
  declare __value: ValueTree<index<H>>;
  declare $def: (val: ValueTree<index<H>>) => this;


  static makeNameRegister(state: RegisterState) {
    if (state.host && state.caller)
      return index_makeName(
        state.host.__name,
        (state.caller instanceof RecordType && state.caller.__proxy) ? state.caller.__name : state.key
      );
    else
      throw new Error("state not valid");
  }
  __host = { FIELD_DEFINITION: true } as const
  /*__builtin = true;*/

  name = new String()
  @StringGeneric("MemberKeys<H>")
  sortKeyFields = new IsArray(new String<MemberKeys<H>>());
  // queryField = new String()

  constructor() {
    super();
    // this.$hook(state => {
    //   if (state.host && state.caller)
    //     this.__value.name = this.__value.queryField = index.makeNameRegister(state);
    // })
  }
}


/**
 * ```graphql
 * directive @hasOne(fields: [String!]) on FIELD_DEFINITION
 * ```
 */
class hasOne extends Directive<"FIELD_DEFINITION"> {
  declare __value: ValueTree<hasOne>;
  declare $def: (val: ValueTree<hasOne>) => this;


  constructor(...args: ConstructorParameters<typeof Directive>) {
    super(...args);
    // this.__hookRegister.push((state) => {
    //   if (state.role === "root" || state.host instanceof TableType && state.host.__typeAttributes().model?.length) {
    //   } else {
    //     throw new Error(`${this.__name} must be declared on a TableType member. Found one on ${state.host?.__name} \n${state.host?.__stack}\n`);
    //   }
    // });
  }

  /*__builtin = true;*/
  __host: Record<"FIELD_DEFINITION", true> = { FIELD_DEFINITION: true };
  fields = new IsArray(new String(true))
  relationName = new String()
  updateVerb = new String()
}
/**
 * ```
 * directive @hasMany(indexName: String, fields: [String!], limit: Int = 100) on FIELD_DEFINITION
 * ```
 */
class hasMany<T extends TableType, H extends TableType> extends Directive<"FIELD_DEFINITION"> {
  static G = `T extends TableType, H extends TableType`;
  declare __value: ValueTree<hasMany<T, H>>;
  declare $def: (val: ValueTree<hasMany<T, H>>) => this;



  constructor(...args: ConstructorParameters<typeof Directive>) {
    super(...args);
    // this.__hookRegister.push((state) => {
    //   if (state.role === "root" || state.host instanceof TableType && state.host.__typeAttributes().model?.length) {
    //   } else {
    //     throw new Error(`${this.__name} must be declared on a TableType member. Found one on ${state.host?.__name} \n${state.host?.__stack}\n`);
    //   }
    // });
  }

  __HasManyIndexConfirmed?: boolean
  __autoset!: Extract<MemberKeys<this>, "indexName">;
  /*__builtin = true;*/
  __host: Record<"FIELD_DEFINITION", true> = { FIELD_DEFINITION: true };

  indexName = new String()
  @StringGeneric("MemberKeys<H>")
  fields = new IsArray(new String<MemberKeys<H>>(true))
  limit = new Int(true)
  @StringGeneric("MemberKeys<T>")
  remote = new String<MemberKeys<T>>();
  detailType = new String();
}
/**
 * ```
 * directive @belongsTo(fields: [String!]) on FIELD_DEFINITION
 * ```
 */
class belongsTo<H extends RecordType> extends Directive<"FIELD_DEFINITION"> {
  static G = `H extends RecordType`;
  declare __value: ValueTree<belongsTo<H>>;
  declare $def: (val: ValueTree<belongsTo<H>>) => this;


  constructor(...args: ConstructorParameters<typeof Directive>) {
    super(...args);
    // this.__hookRegister.push((state) => {
    //   if (state.role === "root" || state.host instanceof TableType && state.host.__typeAttributes().model?.length) {
    //   } else {
    //     throw new Error(`${this.__name} must be declared on a TableType member with model directive. Found one on ${state.host?.__name} \n${state.host?.__stack}\n`);
    //   }
    // });
  }


  /*__builtin = true;*/
  __host: Record<"FIELD_DEFINITION", true> = { FIELD_DEFINITION: true };
  @StringGeneric("MemberKeys<H>")
  root = new String<MemberKeys<H>>(true);
  @StringGeneric("MemberKeys<H>")
  fields = new IsArray(new String<MemberKeys<H>>(false));
  unique = new Boolean(false);
  onUpdate = new ReferentialActions();
  onDelete = new ReferentialActions();
}
/**
 * ```
 * directive @manyToMany(relationName: String!, limit: Int = 100) on FIELD_DEFINITION
 * ```
 */
class manyToMany extends Directive<"FIELD_DEFINITION"> {
  declare __value: ValueTree<manyToMany>;
  declare $def: (val: ValueTree<manyToMany>) => this;



  constructor(...args: ConstructorParameters<typeof Directive>) {
    super(...args);
    // this.__hookRegister.push((state) => {
    //   if (state.role === "root" || state.host instanceof TableType && state.host.__typeAttributes().model?.length) {
    //   } else {
    //     throw new Error(`${this.__name} must be declared on a TableType member. Found one on ${state.host?.__name} \n${state.host?.__stack}\n`);
    //   }
    // });
  }

  /*__builtin = true;*/
  __host: Record<"FIELD_DEFINITION", true> = { FIELD_DEFINITION: true };

  relationName = new String(true)
  limit = new Int(true)
}
/**
 * ```
 * directive @key(fields: [String!]!, name: String, queryField: String) on OBJECT
 * ```
 */
class key extends Directive<"OBJECT"> {
  declare __value: ValueTree<key>;
  declare $def: (val: ValueTree<key>) => this;


  /*__builtin = true;*/
  __host: Record<"OBJECT", true> = { OBJECT: true };
  fields = new IsArray(new String(true))
  name = new String()
  queryField = new String()
}
/** 
 * ```
 * directive @aws_iam(a1:String) on OBJECT | FIELD_DEFINITION
 * ```
 */
class aws_iam extends Directive<"OBJECT" | "FIELD_DEFINITION"> {
  declare __value: ValueTree<aws_iam>;
  declare $def: (val: ValueTree<aws_iam>) => this;


  /*__builtin = true;*/
  __host: Record<"OBJECT" | "FIELD_DEFINITION", true> = { OBJECT: true, FIELD_DEFINITION: true };
  a1 = new String()
}
/**
 * ```
 * directive @aws_api_key(a1: String) on OBJECT | FIELD_DEFINITION
 * ```
 */
class aws_api_key extends Directive<"OBJECT" | "FIELD_DEFINITION"> {
  declare __value: ValueTree<aws_api_key>;
  declare $def: (val: ValueTree<aws_api_key>) => this;


  /*__builtin = true;*/
  __host: Record<"OBJECT" | "FIELD_DEFINITION", true> = { OBJECT: true, FIELD_DEFINITION: true };
  a1 = new String()
}
/**
 * ```
 * directive @aws_subscribe(mutations: String) on FIELD_DEFINITION
 * ```
 */
class aws_subscribe extends Directive<"FIELD_DEFINITION"> {
  declare __value: ValueTree<aws_subscribe>;
  declare $def: (val: ValueTree<aws_subscribe>) => this;


  /*__builtin = true;*/
  __host: Record<"FIELD_DEFINITION", true> = { FIELD_DEFINITION: true };
  mutations = new String()
}

export class ConfirmType extends EnumType {


  __builtin = true
  READ = new EnumMember()
  CREATE = new EnumMember()
  UPDATE = new EnumMember()
  DELETE = new EnumMember()
}
export class SectionType extends EnumType {


  __builtin = true
  STATIC = new EnumMember()
  OPENED = new EnumMember()
  CLOSED = new EnumMember()
}


export class FieldFilterType extends EnumType {

  /*__builtin = true;*/

  text = new EnumMember();
  numeric = new EnumMember();
  boolean = new EnumMember();
  date = new EnumMember();
  enum = new EnumMember();
}

class field<T extends RecordMember<any>, H extends RecordType> extends Directive<"FIELD_DEFINITION" | "ENUM_VALUE"> {
  static G = `T extends RecordMember<any>, H extends RecordType`;
  declare __value: ValueTree<field<T, H>>;
  // $def: (def: T extends EnumType ? MemberKeys<T> : ValueTree<T>) => this = (def) => {
  //   if (!this.__value) throw new Error("Value must be set before calling default");
  //   this.__value.default = ScalarJSON.stringify(def);
  //   return this;
  // }


  __host = { FIELD_DEFINITION: true, ENUM_VALUE: true, } as const
  // __builtin = false;

  title = new String()
  filterType = new FieldFilterType()
  helptext = new String()
  lefticon = new IsArray(new String(true))
  righticon = new IsArray(new String(true))
  preventUpdate = new Boolean(false)
  preventCreate = new Boolean(false)
  preventDelete = new Boolean(false)
  /** The UI will only send this field to the server for the specified modes, but does not otherwise change the UI.  */
  onlyfor = new IsArray(new ConfirmType())
  @StringGeneric("MemberKeys<T> | `${MemberKeys<T>}/${string}`")
  arrayList = new IsArray(new String<MemberKeys<T> | `${MemberKeys<T>}/${string}`>());
  @StringGeneric("MemberKeys<T> | `${MemberKeys<T>}/${string}`")
  arraySort = new IsArray(new String<MemberKeys<T> | `${MemberKeys<T>}/${string}`>());
  @StringGeneric("MemberKeys<T> | `${MemberKeys<T>}/${string}` | `../${MemberKeys<H>}` | `../${MemberKeys<H>}/${string}`")
  extraGetPaths = new IsArray(new String<MemberKeys<T> | `${MemberKeys<T>}/${string}` | `../${MemberKeys<H>}` | `../${MemberKeys<H>}/${string}`>());
  hidden = new Boolean()
  default = new ScalarJSON("field_default")
  @StringGeneric("`./${MemberKeys<H>}` | `../${string}` | `./${MemberKeys<H>}/${string}`")
  replicate = new String<`./${MemberKeys<H>}` | `../${string}` | `./${MemberKeys<H>}/${string}`>;
  subform = new String()
  unique = new Boolean()
  clientSideOnly = new Boolean()
  clientSideLoad = new ScalarJSON("PrismaPromise")
  clientSidePath = new String()
  fieldClass = new String()
}
class select<T extends RecordMember<any>, H extends RecordType> extends Directive<"FIELD_DEFINITION" | "ENUM_VALUE"> {
  static G = `T extends RecordMember<any>, H extends RecordType`;
  declare __value: ValueTree<select<T, H>>;
  __host = { FIELD_DEFINITION: true, ENUM_VALUE: true, } as const;
  @StringGeneric(`"buttons" | "listbox" | "dropdown" | "checkbox" | "radiocircle"`)
  display = new String<"buttons" | "listbox" | "dropdown" | "checkbox" | "radiocircle">();
}

class forms extends Directive<"ENUM" | "OBJECT"> {
  declare __value: ValueTree<forms>;
  declare $def: (val: ValueTree<forms>) => this;

  __host = { ENUM: true, OBJECT: true } as const

  heading = new String()
  list = new IsArray(new String())
  sort = new IsArray(new String())
  defaultTab = new String(false)
  extraForms = new ScalarJSON("forms_extraForms")
  constructor(...args: ConstructorParameters<typeof Directive>) {
    super(...args);
  }

}


class uniquePrisma<H extends TableType> extends Directive<"OBJECT"> {
  static G = `H extends TableType`;
  declare __value: ValueTree<uniquePrisma<H>>;
  declare $def: (val: ValueTree<uniquePrisma<H>>) => this;


  __host = { OBJECT: true } as const
  // __builtin = false;

  @StringGeneric("MemberKeys<H>")
  fields = new IsArray(new String<MemberKeys<H>>());
  name = new String()
  map = new String()
  length = new Number()
  sort = new String()
  clustered = new Boolean()
}
export class ReferentialActions extends EnumType {

  Cascade = new EnumMember();
  Restrict = new EnumMember();
  NoAction = new EnumMember();
  SetNull = new EnumMember();
  SetDefaul = new EnumMember();
}
class relationPrisma<T extends TableType, H extends TableType> extends Directive<"FIELD_DEFINITION"> {
  static G = `T extends TableType, H extends TableType`;
  declare __value: ValueTree<relationPrisma<T, H>>;
  declare $def: (val: ValueTree<relationPrisma<T, H>>) => this;


  __host = { FIELD_DEFINITION: true } as const
  // __builtin = false;

  name = new String();
  @StringGeneric("MemberKeys<T>")
  remote = new String<MemberKeys<T>>();
  @StringGeneric("MemberKeys<H>")
  fields = new IsArray(new String<MemberKeys<H>>());
  @StringGeneric("MemberKeys<T>")
  references = new IsArray(new String<MemberKeys<T>>());
  map = new String();
  onUpdate = new ReferentialActions();
  onDelete = new ReferentialActions();

}


export class EditorType extends EnumType {


  __builtin = true
  InputText = new EnumMember()
  TextArea = new EnumMember()
  TinyMCE = new EnumMember()
  Email = new EnumMember()
}
class editor extends Directive<"FIELD_DEFINITION"> {
  declare __value: ValueTree<editor>;
  declare $def: (val: ValueTree<editor>) => this;


  __host = { FIELD_DEFINITION: true } as const
  // __builtin = false;

  type = new EditorType()
  options = new ScalarJSON("editor_options")
}

class indexComposite<H extends TableType> extends Directive<"OBJECT"> {
  static G = `H extends TableType`;
  declare __value: ValueTree<indexComposite<H>>;
  declare $def: (val: ValueTree<indexComposite<H>>) => this;


  __host = { OBJECT: true } as const
  // __builtin = false;

  @StringGeneric("MemberKeys<H>")
  fields = new IsArray(new String<MemberKeys<H>>());
  name = new String()
  map = new String()
  length = new Number()
  sort = new String()
  clustered = new Boolean()
  type = new String()
  ops = new String()
}
type lookupMethodKeys = "AUTOCOMPLETE" | "SELECT";
export class LookupMethod extends EnumType implements Record<lookupMethodKeys, EnumMember> {
  /*__builtin = true;*/


  AUTOCOMPLETE = new EnumMember();
  SELECT = new EnumMember();
}

declare const window: any;
// export interface lookup_values<T extends TableType> extends ValueTree<lookup<T>> {
//     filterWith: MemberKeys<T>[];
//     list: (string)[];
//     method: MemberKeys<LookupMethod>;
// }
class lookup<T extends TableType, H extends RecordType> extends Directive<"FIELD_DEFINITION"> {
  static G = `T extends TableType, H extends RecordType`;
  static makeName(table: string, host: string, key: string) { return `List${table}_${host}_${key}Lookup` as const; }
  declare __value: ValueTree<lookup<T, H>>;


  constructor(...args: ConstructorParameters<typeof Directive>) {
    super(...args);
  }

  __host = { FIELD_DEFINITION: true } as const;
  // __builtin = false;

  @StringGeneric("`List${string}Lookup`")
  queryName_internal = new String<`List${string}Lookup`>();
  @StringGeneric("string")
  queryName = new String<string>();
  @StringGeneric('"ASC" | "DESC"')
  querySort = new String<"ASC" | "DESC">();
  // method = new LookupMethod()

  targetTable = new String();
  hostTable = new String();

  @StringGeneric("MemberKeys<H>")
  belongsTo = new String<MemberKeys<H>>(false);
  @StringGeneric("MemberKeys<T>")
  currentHasOne_RemoteID = new String<MemberKeys<T>>(false);
  @StringGeneric("MemberKeys<H>")
  currentHasOne = new String<MemberKeys<H>>(false);
  @StringGeneric("MemberKeys<T> | `${MemberKeys<T>}/${string}`")
  dropdownList = new IsArray(new String<MemberKeys<T> | `${MemberKeys<T>}/${string}`>());
  @StringGeneric("MemberKeys<T> | `${MemberKeys<T>}/${string}`")
  dropdownSort = new IsArray(new String<MemberKeys<T> | `${MemberKeys<T>}/${string}`>());
  // @StringGeneric("T, H")
  // dropdownFilter = new IsArray(new Member(FilterWith<T, H>,));
  // customQuery = new ScalarJSON("any");
  lookupOnly = new Boolean();
  @StringGeneric("MemberKeys<T>")
  optionValue = new String<MemberKeys<T>>();
}

class filter<T extends TableType, H extends RecordType> extends Directive<"OBJECT"> {
  static G = `T extends TableType, H extends RecordType`;
  __host = { OBJECT: true } as const;
  declare __value: ValueTree<filter<T, H>>;
  @StringGeneric("parentFilterArg<T>")
  USER_BRANCH_ID = new ScalarJSON("filter_USER_BRANCH_ID");
}


// export class FilterWith<T extends TableType, H extends RecordType> extends RecordType {
//   static G = "T extends TableType, H extends RecordType"
//   /*__builtin = true;*/

//   @StringGeneric("MemberKeys<T>")
//   filterThis = new String<MemberKeys<T>>();
//   @StringGeneric("MemberKeys<H> | `../${string}` | `${MemberKeys<H>}/${string}`")
//   filterWith = new String<MemberKeys<H> | `../${string}` | `${MemberKeys<H>}/${string}`>();
//   filterConst = new ScalarJSON("FilterWith_filterConst");
//   onlyfor = new IsArray(new ConfirmType(false));
//   queryName = new String();
// }

class attributes<T extends RecordType, H extends RecordType> extends Directive<"OBJECT" | "FIELD_DEFINITION"> {
  static G = `T extends RecordType, H extends RecordType`;
  __host = { OBJECT: true, FIELD_DEFINITION: true } as const;
  declare __value: ValueTree<attributes<T, H>>;
  @StringGeneric("DirectiveOptions")
  target = new String<DirectiveOptions>();
  attrs = new ScalarJSON("Attributes");
}

class primaryKey extends Directive<"FIELD_DEFINITION"> {

  __host = { FIELD_DEFINITION: true } as const;
  /*__builtin = true;*/
  declare __value: ValueTree<preset>;
  declare $def: (val: ValueTree<preset>) => this;

  sortKeyFields = new IsArray(new String(true))

}

class preset extends Directive<"FIELD_DEFINITION"> {

  __host = { FIELD_DEFINITION: true } as const;
  /*__builtin = false;*/
  declare __value: ValueTree<preset>;
  declare $def: (val: ValueTree<preset>) => this;

  field = new String();
  value = new String();
}

class amplifyFunction extends Directive<"FIELD_DEFINITION"> {
  __host = { FIELD_DEFINITION: true } as const;
  /*__builtin = false;*/
  declare __value: ValueTree<preset>;
}


class currency extends Directive<"FIELD_DEFINITION"> {

  __host = { FIELD_DEFINITION: true } as const;
  /*__builtin = false;*/
  declare __value: ValueTree<currency>;
  declare $def: (val: ValueTree<currency>) => this;

}

class history extends Directive<"FIELD_DEFINITION"> {

  __host = { FIELD_DEFINITION: true } as const;
  /*__builtin = false;*/
  declare __value: ValueTree<history>;
  declare $def: (val: ValueTree<history>) => this;
}

/** 
 * Prefill default values for a junction table. 
 * Requires the arrayList property to be set on the field directive with all 
 * properties required for creation besides childField and otherField.
 * childField and otherField must contain lookup directives as well.
 */
class prejoin<T extends TableType, H extends RecordType> extends Directive<"FIELD_DEFINITION"> {
  static G = `T extends TableType, H extends RecordType`;

  __host = { FIELD_DEFINITION: true } as const;
  /*__builtin = false;*/
  declare __value: ValueTree<history>;
  declare $def: (val: ValueTree<history>) => this;

  @StringGeneric("MemberKeys<T>")
  childField = new String<MemberKeys<T>>();
  @StringGeneric("MemberKeys<T>")
  otherField = new String<MemberKeys<T>>();
  prefill = new Boolean();

}

class ledger extends Directive<"OBJECT"> {

  __host = { OBJECT: true } as const;
  /*__builtin = false;*/
  declare __value: ValueTree<ledger>;
  declare $def: (val: ValueTree<ledger>) => this;

  AccountType = new QuickbooksChartOfAccountsCategory();

}

class fieldComposite extends Directive<"FIELD_DEFINITION" | "OBJECT"> {

  __host = { FIELD_DEFINITION: true, OBJECT: true } as const;
  /*__builtin = false;*/
  declare __value: ValueTree<ledger>;
  declare $def: (val: ValueTree<ledger>) => this;

  name = new String();
  opts = new ScalarJSON("fieldComposite_opts");
  host = new String();
  target = new String();
  directive = new Boolean();
  constructor(...args: ConstructorParameters<typeof Directive>) {
    super(...args)
  }

}

class noticeTags extends Directive<"FIELD_DEFINITION"> {

  declare __value: ValueTree<noticeTags>;

  __host = { FIELD_DEFINITION: true, } as const

  constructor() {
    super();
  }

  tags = new IsArray(new String());
  filterConst = new ScalarJSON("noticeTags_filterConst");
}
export class QuickbooksChartOfAccountsCategory extends EnumType {

  // __builtin = false;

  Bank = new CubesEnumMember("Asset: Bank");
  Other_Current_Asset = new CubesEnumMember("Asset: Other Current Asset");
  Fixed_Asset = new CubesEnumMember("Asset: Fixed Asset");
  Other_Asset = new CubesEnumMember("Asset: Other Asset");
  Accounts_Receivable = new CubesEnumMember("Asset: Accounts Receivable");
  Equity = new CubesEnumMember("Equity");
  Expense = new CubesEnumMember("Expense: Expense");
  Other_Expense = new CubesEnumMember("Expense: Other Expense");
  Cost_of_Goods_Sold = new CubesEnumMember("Expense: Cost of Goods Sold");
  Accounts_Payable = new CubesEnumMember("Liability: Accounts Payable");
  Credit_Card = new CubesEnumMember("Liability: Credit Card");
  Long_Term_Liability = new CubesEnumMember("Liability: Long Term Liability");
  Other_Current_Liability = new CubesEnumMember("Liability: Other Current Liability");
  Income = new CubesEnumMember("Revenue: Income");
  Other_Income = new CubesEnumMember("Revenue: Other Income");
}
// "Asset: Bank"
// "Asset: Other Current Asset"
// "Asset: Fixed Asset"
// "Asset: Other Asset"
// "Asset: Accounts Receivable"
// "Equity"
// "Expense: Expense"
// "Expense: Other Expense"
// "Expense: Cost of Goods Sold"
// "Liability: Accounts Payable"
// "Liability: Credit Card"
// "Liability: Long Term Liability"
// "Liability: Other Current Liability"
// "Revenue: Income"
// "Revenue: Other Income"
export class SignupSource extends EnumType {
  Branch = new CubesEnumMember();
  Account = new CubesEnumMember();
}
export class AccountingType extends EnumType {

  // __builtin = false;

  Asset = new CubesEnumMember();
  Equity = new CubesEnumMember();
  Expense = new CubesEnumMember();
  Liability = new CubesEnumMember();
  Revenue = new CubesEnumMember();
}

export class ListQuery<S extends string, T extends TableType> {

  static printListQuery(item: TableType, list: string[] | undefined, suffix: string) {
    return new ListQuery(item, list, suffix).printListQuery();
  }
  static makeName<N extends string, S extends string>(name: N, suffix: S) {
    return `List${name}${suffix}` as const
  }
  constructor(
    public item: T,
    public list: string[] | undefined,
    public suffix: S
  ) {
    ok(this.item, "item is required");
  }

  get name() {
    const name = ListQuery.makeName(this.item.__name, this.suffix);
    return name;
  }

  printListQuery() {
    if (!this.list || !this.list.length) return "";

    return ListQuery.queryWrapper(this.name, [{ list: this.list, item: this.item }]);
  }
  static queryWrapper(name: `List${string}`, tables: { item: TableType, list: string[], }[]) {
    const joiner = "\n          ";
    return `
        query ${name}(
          ${tables.map((e, i) => `$filter${i}: Model${e.item.__name}FilterInput`).join("\n          ").trim()}
          $limit: Int
          $nextToken: String
        ) {
          ${tables.map((e, i) => this.queryInner(i, ListQuery.getQueryTree(e.list, e.item), e.item.__plural, joiner)).join(joiner).trim()}
        }
        `;
  }
  static queryInner(index: number, tree: any, __plural: string, joiner: string) {
    return [
      `list${__plural}(filter: $filter${index}, limit: $limit, nextToken: $nextToken) {`,
      `  __typename`,
      `  items {`,
      `    ${this.printQueryTree(tree, joiner + "    ")}`,
      `  }`,
      `}`
    ].join(joiner);

  }

  static getQueryTree(list: string[], item: RecordType) {
    if (!list || !list.length) return {};
    return list.reduce((n, e) => ListQuery.walk2(n, e.split("/"), item), {});
  }

  private static printQueryTree = (tree: any, joiner: string): string => {
    if (!tree) console.error(tree);
    return Object.keys(tree).sort((a, b) => +(b[0] === "_") - +(a[0] === "_")).map(e => {
      if (!tree[e]) return false;
      if (tree[e] === true) return e;
      const items = this.printQueryTree(tree[e], joiner + "  ");
      if (!items) return false;
      return `${e} {${joiner + "  "}${items}${joiner}}`
    }).filter(truthy).join(joiner);
  }



  private static walk2 = (acc: any, keys: string[], item: any) => {
    if (item instanceof Wrapping) {
      if (!item.__wrap) debugger;
      item = item.__wrap;
    }
    if (item instanceof RecordType) {
      const isModel = !!item.__typeAttributes().model?.length;
      if (isModel && !keys.length) return false;
      acc["__typename"] = true;
      acc["id"] = isModel;
    }

    if (!keys.length) {
      if (item instanceof RecordType) {
        item.__listMembers().forEach(([k2, item2]) => {
          acc[k2] = this.walk2({}, [], item2)
        });
        return acc;
      } else {
        return true;
      }
    }
    let key = keys[0]
    if (!acc[key]) acc[key] = {};
    if (!item[key]) debugger;
    acc[key] = this.walk2(acc[key], keys.slice(1), item[key]);
    return acc;
  }
}


type Equal<A, B, C> = B extends A & B ? C : never;

type imp = Omit<typeof import("./cubes-amazon-old"), "dirs">;
type proto<T> = { prototype: T };
type DIRECTIVES_NAMES<T extends DirectiveOptions> = {
  [K in keyof imp]: imp[K] extends proto<Directive<T>> ? K : never;
}[keyof imp];
type ENUM_NAMES = {
  [K in keyof imp]: imp[K] extends proto<EnumType> ? K : never;
}[keyof imp];
type ENUM_VALUES<T extends ENUM_NAMES> = {
  [K in T]: imp[K] extends proto<EnumType>
  ? MemberKeys<imp[K]["prototype"]>
  : never;
}[T];
type TYPE_NAMES = {
  [K in keyof imp]: imp[K] extends proto<RecordType> ? K : never;
}[keyof imp];
type FIELD_NAMES<T extends TYPE_NAMES> = {
  [K in T]: imp[K] extends proto<RecordType>
  ? MemberKeys<imp[K]["prototype"]>
  : never;
}[T];

type SCALAR_NAMES = {
  [K in keyof imp]: imp[K] extends proto<ScalarType<any>> ? K : never;
}[keyof imp];
type ITEM_NAME<T> = {
  [K in keyof imp]: Equal<imp[K]["prototype"], T, K>
}[keyof imp]
/** The generic type allows only directives with ALL the options */
type Directives<T extends DirectiveOptions = any> = {
  [D in DIRECTIVES_NAMES<T>]?: (imp[D]["prototype"] & {
    __values: ValueTree<imp[D]["prototype"]>;
  })[];
};
type SchemaEnums = { [K in ENUM_NAMES]: imp[K] extends proto<infer X> ? X : never };
type SchemaTypes = { [K in TYPE_NAMES]: imp[K] extends proto<infer X> ? X : never };
type SchemaScals = { [K in SCALAR_NAMES]: imp[K] extends proto<infer X> ? X : never };
type SchemaDirs = { [K in DIRECTIVES_NAMES<any>]: imp[K] extends proto<infer X> ? X : never };



const t: any = { ["hello"](args: any) { } }["hello"];
console.log(t.name);