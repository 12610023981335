import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { MenuGroupDescriptor } from "@shopify/polaris/types";
import { useMemo } from "react";
import { BranchType, Prisma } from "prisma-client";
import { useAngular, useAsyncEffect, useBind, useEventEmitter, useObservable, useRefresh, useSubscribe } from 'react-utils';
import { DataService, ok, PrismaQuery, proxy, TABLE_NAMES } from "common";
import { EventType, Router } from "@angular/router";
import { EventEmitter, NgZone } from "@angular/core";
import { filter, tap } from "rxjs";

export const BranchSelectorRefresh = new EventEmitter<{}>();
export const BranchSelectorRefreshContext = React.createContext(BranchSelectorRefresh);
export function useBranchSelectorRefresh() { return React.useContext(BranchSelectorRefreshContext); }

export const CurrentBranchContext = React.createContext<string>("");
export const CurrentBranchTitleContext = React.createContext<string>("");

export const SetCurrentBranchContext = React.createContext<React.Dispatch<React.SetStateAction<string>>>(() => { });
export function useSetCurrentBranch() { return React.useContext(SetCurrentBranchContext); }

function useInit<T>(factory: () => T) {
  const inited = useRef(false);
  const value = useRef<any>();
  if (!inited.current) {
    inited.current = true;
    value.current = factory();
  }
  return value.current;
}
export interface CurBranch {
  id: string;
  BranchType: BranchType;
  DisplayName: string;
  division: { billing: { Address: any; } | null; Name: string; } | null;
}
export interface BranchSelectorState {
  curBranch: string;
  curBranchTitle: string;
  curBranchChange: EventEmitter<string>;
  setCurBranch: Dispatch<SetStateAction<string>>;
  curBranches: CurBranch[];
  curBranchesMap: Map<string, CurBranch>;
}

export const BranchSelectorContext = React.createContext<BranchSelectorState>(undefined as any);

function branchSelectorActionGroup({ curBranches, curBranchTitle, setCurBranch }: BranchSelectorState): MenuGroupDescriptor {
  return ({
    title: curBranchTitle || 'Select Branch',
    onClick: (openActions) => { openActions(); },
    actions: !curBranches ? [] : [
      { content: "None", onAction: () => { setCurBranch("") } },
      ...curBranches.map(e => ({ content: e.DisplayName, onAction: () => { setCurBranch(e.id); } }))
    ]
  });
}

export function ProvideBranchSelector({ children }: { children: React.ReactNode }) {
  const { get } = useAngular();
  const data = get(DataService);
  const router = get(Router);
  const status = useObservable(data.ready, null, () => data.status);

  const refreshToken = useObservable(useBranchSelectorRefresh());

  const [curBranches, setCurBranches] = useState<BranchSelectorState["curBranches"]>([]);

  useAsyncEffect(async () => {
    await data.ready;
    const res = await data.singleDataQuery(branchesQuery());
    setCurBranches(res);
  }, undefined, undefined, [refreshToken]);

  const [curBranch, _setCurBranch] = useState<string>(useInit(() => {
    const [, table, , id] = router.url.split("?")[0].split("/");
    return table === "Branch" && id || "";
  }));

  const setCurBranch = useCallback((curBranchFn: SetStateAction<string>) => {
    _setCurBranch((prev) => {
      const curBranch = typeof curBranchFn === "function" ? curBranchFn(prev) : curBranchFn;
      const [path, query] = router.url.split("?");
      const [, table, mode, view] = path.split("/");
      if (table !== "Branch" || !mode || !curBranch || view === curBranch) return curBranch;
      router.navigateByUrl(`/Branch/${mode}/${curBranch}${query ? "?" + query : ""}`, { replaceUrl: true });
      return curBranch;
    });
  }, [_setCurBranch, router]);


  const curBranchChange = useEventEmitter<string>();

  const curBranchesMap = useMemo(() => new Map(curBranches?.map(e => [e.id, e]) ?? []), [curBranches]);

  const curBranchObject = useMemo(() => curBranchesMap.get(curBranch), [curBranchesMap, curBranch]);

  const curBranchTitle = curBranchObject?.DisplayName ?? "";

  useEffect(() => { curBranchChange.emit(curBranch); }, [curBranchChange, curBranch]);

  useLayoutEffect(() => {
    if (!status) return;
    if (status.branchType === "CENTRAL") return;
    if (status.branchID) setCurBranch(status.branchID);
  }, [status, setCurBranch]);

  const value = useMemo(() => ({
    curBranch, setCurBranch, curBranchTitle, curBranchChange, curBranches, curBranchesMap
  }), [
    curBranch, setCurBranch, curBranchTitle, curBranchChange, curBranches, curBranchesMap
  ]);

  return (
    <BranchSelectorContext.Provider value={value}>
      {children}
    </BranchSelectorContext.Provider>
  );
}
/**
 * Get the current BranchSelector context and call useObservable(curBranchChange).
 * 
 * @returns The BranchSelector context value 
 */
export function useBranchSelector() {
  const context = useContext(BranchSelectorContext);
  useObservable(context.curBranchChange);
  const { curBranches, curBranchTitle, setCurBranch } = context;
  const action = useMemo((): MenuGroupDescriptor => {
    return ({
      title: curBranchTitle || 'Select Branch',
      onClick: (openActions) => { openActions(); },
      actions: !curBranches ? [] : [
        { content: "None", onAction: () => { setCurBranch("") } },
        ...curBranches.map(e => ({ content: e.DisplayName, onAction: () => { setCurBranch(e.id); } }))
      ]
    });
  }, [curBranches, curBranchTitle, setCurBranch]);
  return { ...context, branchSelectorActionGroup: action };
}

export const NoBranchSelected: unique symbol = Symbol("No Branch Selected");


// function useBranchSelector2() {
//   const { get } = useAngular();
//   const data = get(DataService);
//   const router = get(Router);
//   const zone = get(NgZone);

//   if (!data.status) throw new Error("data.status is not set");

//   const [, table, mode, view] = router.url.split("/");

//   const [curBranch, setBranch] = useState<string>(table === "Branch" ? view : "");

//   if (data.status.branchType !== "CENTRAL") return {
//     curBranch: data.status.branchID || "",
//     curBranchTitle: data.status.branchName || "",
//     branchSelectorActionGroup: undefined
//   }

//   const curBranchTitle = curBranches?.find(e => e.id === curBranch)?.DisplayName ?? "";



//   const curBranchRef = useBranchRef();

//   useLayoutEffect(() => {
//     curBranchRef.curBranch = curBranch ?? "";
//   }, [curBranch]);

//   return { curBranch, curBranchTitle, branchSelectorActionGroup };
// }


export const BranchRefContext = React.createContext<{ curBranch: string }>({ curBranch: "" });

export function useBranchRef() { return React.useContext(BranchRefContext); }

export const branchesQuery = () => proxy.branch.findMany({
  select: PrismaQuery.selectPathsProxy("Branch", x => [
    x.id.__,
    x.DisplayName.__,
    x.BranchType.__,
    x.division.Name.__,
    x.division.billing.Address.__,
  ] as const),
  where: { BranchType: "DEALER" }
});


