import { useCallback, useState } from "react";
import { DataPage, DataPageSchemaFunc } from "./question-dialog";
import { Button, ButtonProps, Popover, Tooltip } from "@shopify/polaris";
import { Modes, Prisma, Rental, SelectTypeTree, TABLE_NAMES, ToSelectObject } from "common";
import { FGCR, QuestionGroup, TypedQuestionGroup } from "./question-base";
import { DefaultArgs, GetResult } from "../../../../../../../server/datamath/prisma/client/runtime/library";

export interface ButtonAwaitPageProps extends ButtonProps {
  onClick?: () => Promise<void>;
  loading?: boolean;
}
export function ButtonAwaitPage<
  T extends TABLE_NAMES = TABLE_NAMES,
  M extends Modes = Modes,
  C extends QuestionGroup<T, FGCR> = DataPageSchemaFunc<"Branch" | "Division" | "Owner", "UPDATE">
>(page: DataPage<T, M, C>, { onClick, loading, ...rest }: ButtonAwaitPageProps) {
  const [curLoading, setLoading] = useState(false);
  const onClick2 = useCallback(async () => {
    if (page.group?.form.dirty) return page.shakeDirty?.();
    setLoading(true);
    try { await onClick?.(); }
    finally { setLoading(false); }
  }, [onClick, page]);
  const button = <Button onClick={onClick2} loading={loading || curLoading} {...rest} disabled={page.isDirty} />;
  return page.isDirty ? <Tooltip content="Save changes before continuing">{button}</Tooltip> : button;
}

export function Render({ children: useChildren }: { children: () => JSX.Element }) {
  return useChildren();
}
export function renderIf(condition: any, useRender: () => JSX.Element) {
  // have to use a function to avoid rendering the component if the condition is false
  return (condition) ? <Render>{useRender}</Render> : null;
}


type ExtractKeys<TUPLE extends readonly any[]> = {
  [K in keyof TUPLE as K extends `${number}` ? K : never]: TUPLE[K] extends { key: infer U } ? U : TUPLE[K];
}

type ColumnsResult<T extends TABLE_NAMES, COLS extends readonly any[]> =
  GetResult<Prisma.TypeMap<DefaultArgs>["model"][T]["payload"], {
    select: ToSelectObject<COLS>
  }, 'findMany'>;
TypedQuestionGroup
type t1 = ColumnsResult<"Rental", ReturnType<typeof customerRentalViewColumns>>;
const customerRentalViewColumns = (x: SelectTypeTree<"Rental">) => [
  x.StartDate.__,
  x.EndDate.__,
  x.RentalStatus.__,
  { key: x.unit.Name.__ },
  { key: x.unit.unitType.Name.__, title: "Unit Type" },
  { key: x.promotion.Title.__, title: "Promotion" },
  { key: x.IsRentToOwn.__, title: "Rent To Own" },
  { key: x.unit.unitType.RentalPrice.__, title: "Price" },
  { key: x.unit.currentBranchMarkup.Markup.__, title: "Markup" },
  { key: x.unit.id.__, hidden: true },
  { key: x.promotion.id.__, hidden: true },
] as const;
