import { Card, MenuGroupDescriptor, Page } from "@shopify/polaris";
import { DataService, UIService } from "common";
import { useMemo } from "react";
import { useAngular, useLoadingMarkup } from "react-utils";
import { TableListInner } from "../tables/TableListInner";
import { rentalTableViews } from "../tables/table-views";
import { FormsQuestionService, useBranchSelector } from "../utils";


export function PageBranchRentals() {

  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);

  const actionGroups: MenuGroupDescriptor[] = [];

  const { curBranch, branchSelectorActionGroup } = useBranchSelector();

  if (data.status.branchType === "CENTRAL" && branchSelectorActionGroup)
    actionGroups.push(branchSelectorActionGroup);

  const title = "Rentals";

  const loadingMarkup = useLoadingMarkup("rentals");

  const onSelectRow = (id: string | undefined) => { if (id) fq.onClickEvent({ action: "edit", table: "Rental", id }) };

  const views = useMemo(() => rentalTableViews({ unit: { currentBranchID: curBranch || undefined } }), [curBranch]);

  return <Page
    fullWidth
    title={title}
    actionGroups={actionGroups}
  >
    <Card padding="0">
      <TableListInner {...{ table: "Rental", views, loadingMarkup, onSelectRow }} />
    </Card>
  </Page>;

}

// const rentalColumns: SPPF<Rental> = x => schema.tables.Rental.attributes.forms?.first()?.list || [];

// x => [
//   x.customer.billing.Name.__,
//   x.customer.Email.__,
//   x.unit.Name.__,
//   x.RentalStatus.__,
//   x.StartDate.__,
//   x.EndDate.__,
// ];
