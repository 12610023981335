import { BlockStack, IndexFilters, TabProps, IndexFiltersMode, Banner, ButtonGroup, MenuGroupDescriptor, Page, Badge } from "@shopify/polaris";
import { DataService, ok } from "common";
import React, { useDeferredValue, useMemo, useState } from "react";
import { ButtonAwait, CardTitle, useAngular, useAsyncEffect, useLoadingMarkup } from "react-utils";
import { SelectTable, TableViewColumnCustom, useTableCols } from "../tables/TableListInner";
import { DataListColumn, DataListIdFieldColumn, FormsQuestionService, useBranchSelector } from "../utils";
import { AppliedFilterProps, FilterProps, useColFilters } from "../tables/useFilters";
import { TableRowAction } from "../tables/TableRowRedux";

// const tabLabels = ["All", "Ready", "Failed"] as const;
// const tabEnum = Object.fromEntries(new Map(tabLabels.map((e, i) => [e, i]))) as Record<typeof tabLabels[number], number>;


export function useCustomTable<T>(
  /** This gets memoed */
  colsOptionsMemo: () => readonly TableViewColumnCustom<T, any>[],
  /** Col memo deps */
  colsDeps: any[],
  /** Mount function which returns the rows */
  mount: () => Promise<T[]>,
  /** Watch deps which cause the mount function to be called again to fetch fresh data */
  mountDeps: any[],
  /** options object */
  {
    idKey,
    curTab = 0,
    setTab,
    showFilters = false,
    onSelectRow,
    tabLabels,
    emptyMarkup,
    loadingMarkup,
    resourceName,
  }: {
    /** keyof row */
    idKey: keyof T,
    curTab?: number;
    setTab?: React.Dispatch<React.SetStateAction<number>>;
    onSelectRow?: (row: string) => Promise<void>;
    showFilters?: boolean;
    tabLabels?: readonly string[];
    loadingMarkup?: () => JSX.Element;
    emptyMarkup?: () => JSX.Element;
    resourceName?: { singular: string, plural: string };
  }) {
  ok(idKey);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { cols, idcol, rows, setValue } = useTableCols(colsOptionsMemo, colsDeps, idKey);

  const { loading } = useAsyncEffect(async () => {
    setValue({ action: "reset", newValue: await mount() });
  }, undefined, undefined, mountDeps);


  const { curAppliedFilters, curMode, setMode, filters, onClearAll } = useColFilters(cols);

  const [curQuery, setQuery] = useState("");
  const query = useDeferredValue(curQuery);
  const emptyState = loading ? loadingMarkup?.() : emptyMarkup?.();


  const tabs = useMemo((): TabProps[] => {
    if (!tabLabels) return [];
    return tabLabels.map(e => ({
      id: e,
      content: e,
      accessibilityLabel: e,
      panelID: e,
    }))
  }, [tabLabels]);

  return (
    <BlockStack gap="0">
      {showFilters && <IndexFilters
        cancelAction={{ onAction: onClearAll }}
        filters={filters}
        appliedFilters={curAppliedFilters}
        onClearAll={onClearAll}
        mode={curMode}
        setMode={setMode}
        onQueryClear={() => { }}
        queryValue={curQuery}
        onQueryChange={setQuery}
        hideQueryField={false}
        hideFilters={false}
        tabs={tabs}
        selected={curTab}
        onSelect={setTab ?? (() => { })}
        canCreateNewView={false}
        disableStickyMode
        disableKeyboardShortcuts />}
      {<SelectTable
        loading={loading}
        query={query}
        cols={cols}
        rows={rows}
        idcol={idcol}
        emptyState={emptyState}
        onClickRow={onSelectRow}
        // firstSort="-Date"
        curAppliedFilters={curAppliedFilters}
        resourceName={resourceName}
      />}
    </BlockStack>
  );

}

