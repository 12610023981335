import { is, Modes, ok, TABLE_NAMES, UIService } from "common";
import { DataPage } from "./question-dialog";
import { QuestionGroup } from "./question-base";
import { BlockStack, Box, Button, Divider, Icon, InlineStack, ModalProps, Text } from "@shopify/polaris/index";
import { PropsWithChildren, useCallback, useLayoutEffect, useMemo, useState } from "react";
import { ButtonAwait, emitGlobalRefresh, EventEmitter, ModalHostEvents, useAngular, useObservable, useObserver, useRefresh } from "react-utils";
import { InfoIcon } from "@shopify/polaris-icons";

/** Makes use of fresh to reset changes */
export async function showPageEditModal<G extends QuestionGroup<any, any>>({ table, id, group, useTitle, useWhenLoaded, onSaveValue }: {
  table: TABLE_NAMES;
  id: string;
  group: ((mode: Modes) => G);
  useTitle: (page: DataPage<any, any, G>) => string;
  useWhenLoaded: (page: DataPage<any, any, G> & { group: {} }, onClose: () => Promise<void>) => JSX.Element;
  onSaveValue?: (page: DataPage<any, any, G>, value: G["form"]["value"]) => Promise<void>;
}) {


  const render = (): ModalProps => {
    const [open, setOpen] = useState(true);
    const { get, injector } = useAngular();
    const ui = get(UIService);

    const page = useMemo(() => {
      const page = new DataPage(
        injector, table, "UPDATE", id,
        () => group("UPDATE"),
        async () => { render.subs.unsubscribe(); }
      );
      if (onSaveValue) page.onSaveValue = (value) => onSaveValue(page, value);
      return page;
    }, [injector]);

    useLayoutEffect(() => {
      page.pageSetup(true);
      return () => { page.subs.unsubscribe(); };
    }, [page]);

    useObservable(page.loadingChange);

    const onClose = useCallback(async () => {
      if (page.isDirty) return;
      render.subs.unsubscribe();
      setOpen(false);
    }, [page]);

    const onDiscard = useCallback(async () => {
      ok(page.group);
      ok(page.hasFresh());
      page.group.form.reset(page.fresh);
    }, [page]);

    const onSave = useCallback(async () => {
      await page.onClickSave();
      emitGlobalRefresh();
    }, [page]);

    useObservable(page.group?.form.valueChanges);
    useObservable(useRefresh());
    useObserver(useRefresh(), () => { page.pageSetup(true) });

    const title = useTitle(page);
    return {
      onClose,
      open,
      title,
      loading: false,
      children: (<>
        <Box padding="400">
          <BlockStack gap="400">
            <page.WhenLoaded>{() => {
              ok(is<typeof page & { group: {} }>(page, !!page.group));
              return useWhenLoaded(page, onClose);
            }}</page.WhenLoaded>
          </BlockStack>
        </Box>
        <Divider />
        {
          page.isDirty ? <Box padding="400">
            <Box width="100%" padding="200" background="bg-surface-caution" borderRadius="200" >
              <InlineStack gap="400" align="space-between">
                <InlineStack gap="400" align='start' blockAlign='center'>
                  <Icon source={InfoIcon} tone='caution' />
                  <Text as="p">Unsaved Changes</Text>
                </InlineStack>
                <InlineStack gap="400" align='end'>
                  <ButtonAwait onClick={onDiscard} >Discard</ButtonAwait>
                  <ButtonAwait variant='primary' onClick={onSave}>Save</ButtonAwait>
                </InlineStack>
              </InlineStack>
            </Box>
          </Box> : <Box padding="400">
            <InlineStack gap="400" align='end'>
              <Button variant='primary' onClick={onClose}>Close</Button>
            </InlineStack>
          </Box >
        }
      </>),
    };
  };
  // this is just for typing, it will get replaced
  render.subs = new EventEmitter();
  ModalHostEvents.emit(render);
}
