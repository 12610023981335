import {
  Badge,
  BlockStack,
  Box,
  Card,
  ChoiceList,
  IndexFilters,
  IndexFiltersProps,
  InlineStack,
  Page,
  RangeSlider,
  TabProps,
  Text,
  TextField,
  useIndexResourceState,
  useSetIndexFiltersMode,
  IndexFiltersMode,
  Layout,
  Tooltip,
  Loading,
  Spinner,

} from "@shopify/polaris";
import { UIService, truthy, schema, is, TABLE_NAMES, SPPI, DataQueryGraph, DataService, PrismaExtra, Generator, Modes, TableViewColumn, ObjectPathTree, FieldClass, String } from "common";
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useAngular, useAsyncEffect, useObservable, useWatchMemo } from "react-utils";
import { QuestionAutoComplete } from "../components/QuestionAutoComplete";
import { DatePickerComboBox } from "../components/DatePicker";
import { Location } from "@aws-sdk/client-location";
import { Auth } from "aws-amplify";
import { SelectDropdown } from "../components/Select";
import { QuestionInputNumberComp } from "../components/QuestionInputNumber";
import { TableArrays, TableView, TableViewClass } from "../tables/table-views";
import { SelectTable, TableListSimple, useTableListInner, useTableListTree } from "../tables/TableListInner";
import { format } from "date-fns";
import { QuestionTableComp } from "../components/QuestionTableComp";
import { QuestionGroup, QuestionInputNumber, QuestionSubGroup, QuestionTable, useDataPage } from "../utils";
import { QuestionItem } from "../components/QuestionItem";
import { QuestionForm, renderQuestionItem } from "../components/QuestionForm";
import { useNavigate } from "react-router";
import { IndexTablePaginationProps } from "@shopify/polaris/components/IndexTable";
import Stripe from "stripe";

function RenderInner({ table, view, rows: rows2 }: { table: TABLE_NAMES | undefined, view: TableView, rows: any[] }) {
  const navigate = useNavigate()
  const { cols, rows, idcol, value, setValue,  customs } = useTableListTree(table, view);
  if (rows2 !== rows) setValue({ action: "reset", newValue: rows2 });
  const onSelectRow = useCallback((row: any) => {
    navigate(`/${row.metadata.PaymentLedger}/edit/${row.metadata.HostID}`);
  }, [navigate]);
  return <SelectTable {...{
    cols, rows, idcol, value, setValue, customs, onClickRow: onSelectRow
  }} />;
}
let fieldindex = 0;
class StringClass extends FieldClass { constructor(key: string) { super(key, fieldindex++, new String(), false, false); } }



const STRIPE_LIMIT = 10;
function useStripeData(data: DataService) {

  const [options, setOptions] = useState<Stripe.AccountListParams>({ limit: STRIPE_LIMIT });
  // const [rows, setRows] = useState<Stripe.Account[]>([]);
  const [offset, setOffset] = useState(0);

  const view = useMemo(() => TableViewClass.makeClientView(undefined, {
    AND: [],
    key: "stripeview",
    title: "Stripe Accounts List",
    list: (x: ObjectPathTree<Stripe.Account & { metadata: { PaymentLedger: string, Name: string } }, []>) => [
      new TableViewColumn({
        key: x.requirements.eventually_due.__,
        title: "Fields Due",
        aggregate: "array",
        markup: (v: string[] | null | undefined) => {
          return (
            <BlockStack gap="0">
              {v?.map(e => <span>{e}</span>)}
            </BlockStack>
          );
        },
        custom: new StringClass("FieldsDue"),
      }),
      new TableViewColumn({
        key: x.metadata.Name.__,
        title: "Name",
        custom: new StringClass("Name"),
      }),
      new TableViewColumn({
        key: x.metadata.PaymentLedger.__,
        title: "Payment Ledger",
        custom: new StringClass("PaymentLedger"),
      }),
    ],
  }), []);

  const { loading, result: rows } = useAsyncEffect(async () => {
    const res = await data.server.serverStripeAccountList(options);
    while (res.has_more) {
      const newrows = await data.server.serverStripeAccountList({ ...options, starting_after: res.data[res.data.length - 1].id });
      res.data.push(...newrows.data);
      res.has_more = newrows.has_more;
    }
    return res.data.filter(e =>
      e.requirements?.eventually_due?.length
      && !e.requirements.eventually_due.some(e => e.startsWith("tos_acceptance"))
    );
  }, undefined, undefined, [options]);

  return { loading, rows, view };
}


export function StripeArlenPage() {
  const { get } = useAngular();
  const data = get(DataService);
  const { loading, rows, view } = useStripeData(data);

  return <Page>
    <BlockStack gap="400">
      <Card>
        {!loading && rows && <RenderInner table={undefined} view={view} rows={rows} />}
        {loading && <Spinner />}
      </Card>
    </BlockStack>
  </Page >

}
